import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Typography
} from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  disableCustomKeyboardMapping,
  enableCustomKeyboardMapping,
  KeyboardMapperContext,
  setKeyboardMode,
  submitModalCommand
} from 'src/placebet/products/providers/CustomKeyboardProvider/state'

import {
  SearchTwoTone as SearchIcon,
  PaidTwoTone as PayIcon,
  BlockTwoTone as VoidIcon,
  EventTwoTone as EventIcon,
  CreditCardTwoTone as DrawIcon,
  PointOfSaleTwoTone as PayDrawIcon,
  CalendarViewMonthTwoTone as CalendarIcon
} from '@mui/icons-material'

export function CustomKeyboardModalInput({
  children
}: {
  children: React.ReactNode
}) {
  const {
    state: { bypass, mode, modalCommand, toolbarAction },
    dispatch
  } = useContext(KeyboardMapperContext)

  const { t } = useTranslation()

  const open = useMemo(() => {
    return mode === 'ModalInput'
  }, [mode])

  useEffect(() => {
    if (!toolbarAction) {
      if (mode === 'ModalInput') {
        dispatch(disableCustomKeyboardMapping())
      } else if (bypass) {
        dispatch(enableCustomKeyboardMapping())
      }
    }
  }, [mode, bypass])

  const Icon = useMemo(() => {
    switch (modalCommand) {
      case 'Pay':
        return <PayIcon />
      case 'Void':
        return <VoidIcon />
      case 'Draw':
        return <DrawIcon />
      case 'PayDraw':
        return <PayDrawIcon />
      case 'CARRERA':
        return <EventIcon />
      case 'EVENTO':
        return <CalendarIcon />
      default:
        return <SearchIcon />
    }
  }, [modalCommand])

  const [value, setValue] = useState<string>('')
  const submitTriggers = []

  const handleClose = () => {
    setValue('')
    dispatch(setKeyboardMode({ mode: 'Standard' }))
  }

  const handleSubmitCommand = (event: any) => {
    if (event.keyCode === 13) {
      if (
        !event.target.value ||
        (event.target.value as string).trim().length === 0
      ) {
        event.preventDefault()
        return
      }

      dispatch(submitModalCommand({ modalCommand, value }))
      setValue('')
      event.preventDefault()
    } else if (submitTriggers && submitTriggers.indexOf(event.key) !== -1) {
      const currentValue = event.target.value as string
      const updatedValue = `${currentValue}${event.key}`
      dispatch(submitModalCommand({ modalCommand, value: updatedValue }))
      event.preventDefault()
    }
  }

  const handleCommandChange = (event: any) => {
    const value = event.target.value as string
    const cleanValue = value.replace(/'/g, '-')
    setValue(cleanValue)
  }

  return (
    <>
      {children}
      <Dialog
        sx={{
          'MuiPaper-root': {
            borderRadius: 0
          }
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <Typography variant="h3">{t(modalCommand?.toUpperCase())}</Typography>{' '}
        </DialogTitle>
        <DialogContent sx={{ py: 1, px: 1.5 }}>
          <Paper
            component="form"
            sx={{
              p: '2px 4px',
              display: 'flex',
              alignItems: 'center',
              width: 400,
              boxShadow: 'none'
            }}
            elevation={0}
            square
            variant="outlined"
          >
            <InputBase
              onKeyDown={handleSubmitCommand}
              onKeyPress={handleSubmitCommand}
              onChange={handleCommandChange}
              autoFocus
              value={value}
              type="text"
              sx={{ ml: 1, flex: 1, fontSize: '20px' }}
              inputProps={{ 'aria-label': 'Modal Input' }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton
              onClick={() => {
                setValue('')
                dispatch(submitModalCommand({ modalCommand, value }))
              }}
              sx={{ p: '10px' }}
              aria-label="search"
            >
              {Icon}
            </IconButton>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  )
}
