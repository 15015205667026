import { createReducer } from 'typesafe-actions'
import { ActivityHistoryDetail, ActivityType, Filters } from '../models'

import { Actions, setActivitiesHistoryDetail, setCount, setFilters, setLoading } from './actions'

export interface ActivityHistoryDetailState {
  activities: ActivityHistoryDetail[],
  filters: Filters,
  loading: boolean,
  count: number,
}

export const initialState: ActivityHistoryDetailState = {
  loading: false,
  count: 0,
  activities: [],
  filters: {
    date: new Date(),
    activityType: ActivityType.SUBMITTICKET,
    page: 0,
    pageSize: 10,
    skip: 0,
  }
}

export const reducer = createReducer<ActivityHistoryDetailState, Actions>({ ...initialState }).handleAction(setActivitiesHistoryDetail, (state, action) => {
  return {
    ...state,
    activities: action.payload,
  }
})
.handleAction(setFilters, (state, action) => {
  return {
    ...state,
    filters: {...action.payload},
  }
})
.handleAction(setLoading, (state, action) => {
  return {
    ...state,
    loading: action.payload,
  }
})
.handleAction(setCount, (state, action) => {
  return {
    ...state,
    count: action.payload,
  }
})
