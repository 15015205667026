import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es-do'
import { Profile } from 'oidc-client'
import { Dictionary } from '../models'
import { ProductEnum } from './appConfiguration'

export function navigateTo(path: string) {
  if (typeof window !== 'undefined') {
    window.location.href = path
  }
}

export function formatAsCurrency(value?: number, decimals: number = 2): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
  }).format(value || 0)
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const dateFormatter = new Intl.DateTimeFormat('es-DO', {
  month: 'short',
  year: 'numeric',
  day: 'numeric'
})

export const dateTimeFormatter = new Intl.DateTimeFormat('es-DO', {
  month: 'short',
  year: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
})

export const timeFormatter = new Intl.DateTimeFormat('default', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true
})

export const formatDateLong = (date: Date | undefined) => {
  if (!date) return ''

  moment.locale('es-do')
  var offset = moment().utcOffset()
  return moment
    .utc(date.toString())
    .utcOffset(offset)
    .format('dddd, MMMM Do YYYY, h:mm:ss a')
}

export const formatDate = (date: Date | undefined) => {
  if (!date) return ''

  moment.locale('es-do')
  var offset = moment().utcOffset()
  return moment
    .utc(date.toString())
    .utcOffset(offset)
    .format('dd/mm/yyyy h:mm:ss a')
}

export const getCurrentUtcDate = () => {
  var offset = moment().utcOffset()
  return moment.utc(new Date().toString()).utcOffset(offset).toDate()
}

export const formatDateTime = (date: Date | undefined) => {
  if (!date) return ''
  const dateValue = Date.parse(date as any)
  return dateTimeFormatter.format(dateValue)
}

export const formatShortTime = (date: Date | undefined) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('hh:mm A')
}

export const formatStringAsShortTime = (date: string, gmtOffset?: number) => {
  var offset = gmtOffset ? gmtOffset : moment().utcOffset()
  return moment.utc(date).utcOffset(offset).format('hh:mm A')
}

export const formatTime = (date: Date | undefined) => {
  if (!date) return ''
  var offset = moment().utcOffset()
  return moment.utc(new Date(date)).utcOffset(offset).format('hh:mm:ss A')
}

export const getLocalDateTime = (): Date => {
  var date = new Date()
  var offset = moment().utcOffset()
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes() + offset * -1,
    date.getUTCSeconds()
  )

  const localDate = new Date(now_utc)
  return localDate
}

export const formatShortDate = (date: Date | undefined) => {
  if (!date) return ''

  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format('DD/MM/YYYY')
}

export const convertArrayToDictionary = (collection: any[], prop: string) => {
  var items: { [id: string]: any } = {}
  collection.forEach((item) => {
    items[item[prop]] = item
  })
  return items
}

export const getTimespanElapsedSince = (date: Date | undefined) => {
  if (!date) {
    return 0
  }
  const now = moment.utc(new Date())
  const from = moment.utc(date)
  const result = moment.duration(now.diff(from))
  return result.asSeconds()
}

export const getTimespan = (
  fromDate: Date | undefined,
  toDate: Date | undefined
) => {
  if (!fromDate || !toDate) {
    return 0
  }
  const now = moment.utc(toDate)
  const from = moment.utc(fromDate)
  const result = moment.duration(now.diff(from))
  const timeSpan = result.asSeconds()
  return timeSpan < 0 ? 0 : timeSpan
}

export const getTimeRemainingUntil = (date: Date | undefined) => {
  const now = moment.utc(new Date())
  const scheduled = moment.utc(date)
  const result = moment.duration(scheduled.diff(now))
  return result.asSeconds()
}

export const getSecondsAsTimeString = (seconds: number) => {
  const dateString = new Date(seconds * 1000).toISOString()
  const time = dateString.split('T')[1]
  return time.slice(0, 8)
}

export const getTimeRemainingUntilAsString = (date: Date | undefined) => {
  const remaining = Math.abs(getTimeRemainingUntil(date))
  return getSecondsAsTimeString(remaining)
}

//https://www.compart.com/en/unicode/search?q=vulgar+fraction#characters
interface FractionsMap {
  [key: string]: string
}

const map: FractionsMap = {
  OneHalf: '\u00BD',
  OneQuarter: '\u00BC',
  ThreeQuarters: '\u00BE'
}

export const ToFractionDisplay = (value: string): string | undefined => {
  var normalized = value.replace('-', '').replace('+', '')
  var regex = /^[0]\.(\d+)|\.(\d+)/g
  var result = regex.exec(normalized)

  if (!result) {
    return undefined
  }

  const matchValue = result[0]
  if (matchValue === '0.5') {
    return value.replace('0.5', map['OneHalf'])
  } else if (matchValue.endsWith('.5')) {
    return value.replace('.5', map['OneHalf'])
  } else if (matchValue === '0.25') {
    return value.replace('0.25', map['OneQuarter'])
  } else if (matchValue.endsWith('.25')) {
    return value.replace('.25', map['OneQuarter'])
  } else if (matchValue === '0.75') {
    return value.replace('0.75', map['ThreeQuarters'])
  } else if (matchValue.endsWith('.75')) {
    return value.replace('.75', map['ThreeQuarters'])
  } else if (matchValue === '0.0') {
    return value.replace('0.0', '')
  } else if (matchValue.endsWith('.0')) {
    return value.replace('.0', '')
  } else {
    return undefined
  }
}

export const getValueAsFraction = (value: string) => {
  var tokens = value.split(/\s+/)
  var formattedValue = ToFractionDisplay(tokens[0])
  return formattedValue ? `${formattedValue} ${tokens[1] ?? ''}` : value
}

export function getProfileInitials(profile: Profile) {
  if (!profile) return ''
  return `${profile.given_name?.substring(
    0,
    1
  )}${profile.family_name?.substring(0, 1)}`.toUpperCase()
}

export const convertToBuckets = <T>(all: T[], size: number) => {
  let myArray = []
  for (var i = 0; i < all.length; i += size) {
    myArray.push(all.slice(i, i + size))
  }
  return myArray
}

export const convertToNumberBuckets = (all: number[], size: number) => {
  let myArray = []
  for (var i = 0; i < all.length; i += size) {
    myArray.push(all.slice(i, i + size))
  }
  return myArray
}

export function titleCase(str) {
  const result = str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })

  return result
}

export const customFormatDate = (
  date: Date | undefined | null | string,
  format?: string
) => {
  if (!date) return ''

  const defaultFormat = format ? format : 'DD/MM/YYYY h:mm:ss a'

  moment.locale('es-do')
  var offset = moment().utcOffset()
  return moment.utc(date.toString()).utcOffset(offset).format(defaultFormat)
}

export const customFormatLocalDate = (
  date: Date | undefined | null | string,
  format?: string
) => {
  if (!date) return ''

  const defaultFormat = format ? format : 'DD/MM/YYYY h:mm:ss a'

  moment.locale('es-do')
  return moment(date.toString()).format(defaultFormat)
}

export const currentUtcDate = () => {
  var date = moment.utc(new Date()).toDate()
  moment.locale('es-do')
  return moment(date.toString()).format('DD/MM/YYYY h:mm:ss a')
}

export const formatAsPercentage = (
  value: number = 0,
  decimals: number = 2
): string => {
  const result = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals
  }).format(value)
  return `${result}%`
}

export const isMobile = (): boolean => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true
  } else {
    return false
  }
}

export function toUTCISOString(date: Date) {
  var timezoneOffset = date.getMinutes() + date.getTimezoneOffset()
  var timestamp = date.getTime() + timezoneOffset * 1000
  var correctDate = new Date(timestamp)
  return correctDate.toISOString()
}

export function toDateOnlyUtcISOString(date: Date) {
  var timezoneOffset = date.getMinutes() + date.getTimezoneOffset()
  var timestamp = date.getTime() + timezoneOffset * 1000
  var correctDate = new Date(timestamp)
  correctDate.setUTCHours(0, 0, 0, 0)
  return correctDate.toISOString()
}

export function yesterday() {
  var now = new Date()
  now.setHours(0, 0, 0, 0)
  now.setDate(now.getDate() - 1)
  return now.toISOString()
}

export function today() {
  var now = new Date()
  now.setHours(0, 0, 0, 0)
  return now.toISOString()
}

export function tomorrow() {
  var now = new Date()
  now.setHours(0, 0, 0, 0)
  // add a day
  now.setDate(now.getDate() + 1)
  return now.toISOString()
}

export function now() {
  return toUTCISOString(new Date())
}

export const parseJwt = (token: string): Dictionary | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const normalizeRouteParam = (value: string) => {
  return (value || '').replace(/\s+/g, '-').toLowerCase()
}

export const normalizeProductRouteParam = (value: ProductEnum) => {
  return (value || '').replace(/[\s|_]+/g, '-').toLowerCase()
}

export const routeParamAsProduct = (value: string) => {
  return (value || 'NONE')
    .replace(/[\s|-]+/g, '_')
    .toLocaleUpperCase() as ProductEnum
}

export const toPascalCase = (str: string) =>
  _.startCase(_.camelCase(str)).replace(/\s/g, '')

export function decode(data: string) {
  return Buffer.from(data || '', 'base64').toString('utf-8')
}

export function secondsToMinutesString(seconds) {
  var minutes = Math.floor(seconds / 60)
  var remainingSeconds = seconds % 60

  var minutesString = (minutes < 10 ? '0' : '') + minutes
  var secondsString = (remainingSeconds < 10 ? '0' : '') + remainingSeconds

  return minutesString + ':' + secondsString
}

export const isPayTicketFormatValid = (ticket: string): boolean => {
  // Format 1: number (infinity value) - alphanumeric (max 8 characters)
  const appFormat = /^\d+-[A-Za-z0-9]{8}$/

  // Formato 2: 3 blocks alphanumerics of five characters separate by -
  const providerFormat = /^[A-Za-z0-9]{5}-[A-Za-z0-9]{5}-[A-Za-z0-9]{5}$/

  if (appFormat.test(ticket)) {
    return true
  } else if (providerFormat.test(ticket)) {
    return true
  } else {
    return false
  }
}
