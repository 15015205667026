import { ReactNode, useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, Navigate } from 'react-router'
import { ProductEnum, useAppSelector } from 'src/placebet/core'
import { Loader } from '../../../components/Loader'
import { useVerifyRegistrationQuery } from '../api/appContextApi'
import { setProduct } from '../appContextSlice'

const AppContextProvider = ({
  product,
  children
}: {
  product: ProductEnum
  children: ReactNode
}) => {
  const { product: selectedProduct, registered } = useAppSelector(
    (state) => state.appContext
  )
  const { isFetching, error } = useVerifyRegistrationQuery({})

  const dispatch = useDispatch()

  dispatch(setProduct(product))

  const navigate = useNavigate()

  const component = useMemo(() => {
    if (!registered) return null

    switch (registered) {
      case 'NOT_REQUIRED':
      case 'REGISTERED': {
        return children
      }

      case 'UNREGISTERED': {
        return <Navigate to="/ws/registration" />
      }

      case 'UNAUTHORIZED': {
        return <Navigate to="/status/401" />
      }

      case 'UNKNOWN': {
        return null
      }

      default: {
        return <Navigate to="/status/500" />
      }
    }
  }, [registered])

  useEffect(() => {
    if (error) {
      navigate('/status/500')
    }
  }, [error])

  const loading = useMemo(() => {
    return selectedProduct === 'NONE' || isFetching
  }, [isFetching, selectedProduct])

  return <Loader loading={loading}>{component}</Loader>
}

export default AppContextProvider
