import { PoolId } from './../../products/horseraces/models/core';

export const isWagerMultiRace = (poolId: PoolId): boolean => {
    switch (poolId) {
        case 'WIN': 
        case 'PLC': 
        case 'SHW': 
        case 'OMN': 
        case 'QU': 
        case 'BQ': 
        case 'QD': 
        case 'EXA': 
        case 'TRI': 
        case 'TT': 
        case 'SPR': 
        case 'BP': 
        case 'PEN':
            return false
        case 'DE': 
        case 'DD':
        case 'PK3':
        case 'PK4':
        case 'PK5':
        case 'PK6':
        case 'PK7':
        case 'PK8':
        case 'PK9':
        case 'PK10':
            return true
        default:
            return false
    }   
}