import { FinishAt, positionsMap, Pool, PoolId } from '../models'

export const combinations = (runners: string[], positions: FinishAt[]) => {
  const valid: string[] = []
  runners.forEach((runner) => {
    positions.forEach((position) => {
      valid.push(`${runner}-${position}`)
    })
  })

  return valid
}

export const findPermutations = (source: string[]): string[] => {
  if (!source) {
    return []
  }

  if (!!source.length && source.length < 2) {
    return source
  }

  let permutationsArray = []

  for (let i = 0; i < source.length; i++) {
    let item = source[i]

    if (source.indexOf(item) !== i) continue

    let remainder = source
      .slice(0, i)
      .concat(source.slice(i + 1, source.length))

    for (let permutation of findPermutations(remainder)) {
      permutationsArray.push(`${item}-${permutation}`)
    }
  }
  return permutationsArray
}

export const getPoolLegs = (pool?: Pool | PoolId): FinishAt[] => {
  if (!pool) {
    return []
  }

  if ((pool as PoolId) === 'WIN/PLC/SHW') return []

  let results: FinishAt[] = []
  for (var i = 0; i < (pool as Pool).legs; i++) {
    results.push(positionsMap[i])
  }

  return results
}

export function cartesian(...args) {
  var r = [],
    max = args.length - 1
  function helper(arr, i) {
    for (var j = 0, l = args[i].length; j < l; j++) {
      var a = arr.slice(0) // clone arr
      a.push(args[i][j])
      if (i === max) r.push(a)
      else helper(a, i + 1)
    }
  }
  helper([], 0)
  return r
}

export function distinct<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index
}

export function formatWeight(weight: string) {
  return Math.round(Number.parseFloat((weight || '0').replace(',', '.')))
}
