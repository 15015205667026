import {
  List,
  ListItemText,
  styled,
  Typography,
  Box,
  lighten,
  Divider,
  ListItem,
  ListItemIcon,
  Stack,
  IconButton
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import React from 'react'
import WagerItem from './WagerItem'

import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined'

import { formatAsCurrency, useAppSelector } from 'src/placebet/core'
import TicketListItem from '../../../../../components/TicketsSidebarCore/TicketListItem'
import { HorseRacesWagerCollection } from 'src/placebet/products/horseraces/models'
import {
  useRemoveHorseRacesWagerCollectionMutation,
  useRemoveHorseRacesWagerMutation
} from 'src/placebet/products/horseraces/redux/horseRacesProductApi'

const WagerCollectionContainer = styled(Box)(
  ({ theme }) => `
          background-color: ${lighten(theme.colors.alpha.black[10], 0.5)};
          margin: ${theme.spacing(0)} 0;
          border-radius: {theme.general.borderRadius};
          padding: ${theme.spacing(0)};
          padding-left: ${theme.spacing(1)};
    `
)

function TicketWagerCollection({
  wager: wagerCollection
}: {
  wager: HorseRacesWagerCollection
}) {
  const { t } = useTranslation()
  const { id, providerTicketId, event, wagers, total, surcharge } =
    wagerCollection

  const {
    league: { name: leagueName }
  } = event

  const { ticket, busy } = useAppSelector((state) => state.horseRaces)

  const [deleteWagerCollection] = useRemoveHorseRacesWagerCollectionMutation()
  const [deleteWager] = useRemoveHorseRacesWagerMutation()

  return (
    <WagerCollectionContainer>
      <Stack
        spacing={1}
        sx={{ mt: 1, mb: 1 }}
        display="flex"
        alignItems="flex-start"
        direction={'column'}
      >
        <Stack
          sx={{ width: '100%' }}
          display="flex"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" noWrap>{`${t('CARRERA')} ${
            wagerCollection.race
          }`}</Typography>
          <Typography variant="h4" noWrap fontSize={17}>
            {leagueName}
          </Typography>

          <IconButton
            disabled={busy}
            onClick={() =>
              deleteWagerCollection({
                ticketId: ticket?.id,
                wagerCollectionId: id
              })
            }
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Stack>
        {providerTicketId && providerTicketId !== 'null' && (
          <Typography
            variant="subtitle1"
            display="inline"
            align="right"
            textTransform={'uppercase'}
          >
            {`${providerTicketId}`}
          </Typography>
        )}
      </Stack>
      <Divider />
      <Stack
        spacing={0}
        display="flex"
        alignContent={'flex-start'}
        alignItems="flex-start"
        direction={'column'}
      >
        <List sx={{ width: '100%' }} disablePadding>
          {wagers &&
            wagers.map((wager) => {
              const canExpand = wager.wagerCombinations.length > 1
              return (
                <React.Fragment key={`wager-${wager.id}`}>
                  <ListItem
                    sx={{ p: 0 }}
                    key={wager.id}
                    role={undefined}
                    dense
                    disabled={wager.takeDown || busy}
                  >
                    <ListItemIcon
                      sx={{ minWidth: '42px' }}
                      onClick={() =>
                        deleteWager({ ticketId: ticket?.id, wagerId: wager.id })
                      }
                    >
                      <IconButton>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ListItemIcon>

                    <ListItemText
                      id={wager.id}
                      primary={
                        <WagerItem wager={wager} canExpand={canExpand} />
                      }
                    />
                  </ListItem>
                  <Divider />
                </React.Fragment>
              )
            })}
        </List>
        {surcharge > 0 && (
          <Box sx={{ width: '100%', px: 1, py: 1 }}>
            <TicketListItem
              variant="subtitle1"
              primary={t('SURCHARGE') as string}
              secondary={formatAsCurrency(surcharge, 0)}
            />
          </Box>
        )}
        <Box sx={{ width: '100%', px: 1, py: 1 }}>
          <TicketListItem
            variant="subtitle1"
            primary={t('TOTAL') as string}
            secondary={formatAsCurrency(total, 0)}
          />
        </Box>
      </Stack>
    </WagerCollectionContainer>
  )
}

export default TicketWagerCollection
