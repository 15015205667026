import { SearchRequest } from "src/placebet/core"

export interface ActivityHistoryDetail {
    enterpriseId: number
    locationId: number
    stationId: number
    date: Date
    time: Date
    activityType: string
    productId: number
    amount: number
    currencyId: number
  }
  
  export enum ActivityType {
    SUBMITTICKET = 'SUBMITTICKET',
    PAYTICKET = 'PAYTICKET',
    VOIDTICKET = 'VOIDTICKET',
    DRAW = 'DRAW',
    PAYDRAW = 'PAYDRAW',
  }

  export interface Filters extends SearchRequest{
    date: Date
    activityType: ActivityType
    skip: number,
  }
