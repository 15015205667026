import { ApolloProvider } from '@apollo/client'
import {
  getApolloClient,
  store,
  useBrowserFingerprint
} from 'src/placebet/core'
import Protected from 'src/placebet/core/auth/Protected'

import { Loader } from '../Loader'
import CollapsedSidebarLayout from 'src/placebet/layouts/CollapsedSidebarLayout'
import AppContextProvider from 'src/placebet/core/appContext/AppContextProvider'
import { Provider } from 'react-redux'

const CustomerLayout = () => {
  const { ready, error } = useBrowserFingerprint()

  return (
    <Protected>
      <Loader loading={!ready}>
        <Provider store={store}>
          <ApolloProvider client={getApolloClient()}>
            <AppContextProvider product="ALL">
              <CollapsedSidebarLayout />
            </AppContextProvider>
          </ApolloProvider>
        </Provider>
      </Loader>
    </Protected>
  )
}

export default CustomerLayout
