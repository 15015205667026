import { gql } from '@apollo/client'
import { LeagueEvent, ProductEnum } from 'src/placebet/core'
import {
  AnyTicket,
  DictionaryOf,
  Workstation,
  WorkstationTransaction
} from 'src/placebet/core/models'
import { transformTicket } from 'src/placebet/core/services/transformTicket'
import {
  HorseRacesTicket,
  EventDetails,
  EventResults,
  TICKET_FIELDS,
  TICKET_FIELDS_WITH_PAYOFF,
  LEAGUE_EVENTS_QUERY,
  LEAGUE_POOL_EVENTS_QUERY,
  TICKETS_QUERY,
  TICKET_QUERY,
  TICKETS_TYPE_BET_SALES_QUERY
} from '../models'

import productApi from '../../../core/api/productApi/productApi'
import {
  WORKSTATION_TICKET_TRANSACTION_RECEIPT,
  WORKSTATION_TRANSACTION_RECEIPT
} from 'src/placebet/core/api/productApi/fragments'

export interface LegueEventsData {
  horseRacesLeagueEvents: LeagueEvent<EventDetails, EventResults>[]
  tickets: {
    items: AnyTicket[]
  }
}

export interface LeguePoolEventsData {
  horseRacesPoolEvents: LeagueEvent<EventDetails, EventResults>[]
  tickets: {
    items: AnyTicket[]
  }
}

export interface TicketsData {
  tickets: {
    items: HorseRacesTicket[]
  }
}

export interface TicketTypebetSalesData {
  workstationTicketTransactionsTypebetSales: DictionaryOf<AnyTicket>[]
}

export interface TicketResponseData {
  [key: string]: HorseRacesTicket
}

export interface LegueEventsResponse {
  events: LeagueEvent<EventDetails, EventResults>[]
  tickets: DictionaryOf<AnyTicket>
}

const horseRacesApi = productApi.injectEndpoints({
  endpoints: (builder) => ({
    getHorseRacesLeagueEvents: builder.query<
      LegueEventsResponse,
      { name: string }
    >({
      query: ({ name }) => ({
        document: LEAGUE_EVENTS_QUERY,
        variables: {
          name
        }
      }),
      transformResponse: (response: LegueEventsData) => {
        let tickets: DictionaryOf<AnyTicket> = {}

        const list = response.tickets.items || []

        list.forEach((t) => {
          const ticket = transformTicket(t)
          tickets[ticket.grouping] = ticket
        })

        return {
          events: response.horseRacesLeagueEvents || [],
          tickets
        }
      }
    }),
    getHorseRacesPoolLeagueEvents: builder.query<
      LegueEventsResponse,
      { name: string; race: number; pool: string }
    >({
      query: ({ name, race, pool }) => ({
        document: LEAGUE_POOL_EVENTS_QUERY,
        variables: {
          name,
          race,
          pool
        }
      }),
      transformResponse: (response: LeguePoolEventsData) => {
        let tickets: DictionaryOf<AnyTicket> = {}

        const list = response.tickets.items || []

        list.forEach((t) => {
          const ticket = transformTicket(t)
          tickets[ticket.grouping] = ticket
        })

        return {
          events: response.horseRacesPoolEvents || [],
          tickets
        }
      }
    }),
    getHorseRacesTicket: builder.query<AnyTicket, { number: string }>({
      query: ({ number }) => ({
        document: TICKET_QUERY,
        variables: {
          number
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        return transformTicket(response.ticket)
      }
    }),
    getHorseRacesTickets: builder.query<
      DictionaryOf<AnyTicket>,
      { whereTicket?: any }
    >({
      query: ({ whereTicket }) => ({
        document: TICKETS_QUERY,
        variables: {
          whereTicket
        }
      }),
      transformResponse: (response: TicketsData) => {
        let tickets: DictionaryOf<AnyTicket> = {}

        const list = response.tickets.items || []

        list.forEach((t) => {
          const ticket = transformTicket(t)
          tickets[ticket.grouping] = ticket
        })

        return tickets
      }
    }),
    getHorseRacesTicketTypebetSales: builder.query<
      DictionaryOf<AnyTicket>[],
      { product: ProductEnum; from: string; to: string }
    >({
      query: ({ product, from, to }) => ({
        document: TICKETS_TYPE_BET_SALES_QUERY,
        variables: {
          product,
          from,
          to
        }
      }),
      transformResponse: (
        response: TicketTypebetSalesData
      ): DictionaryOf<AnyTicket>[] => {
        return response.workstationTicketTransactionsTypebetSales || []
      }
    }),
    submitHorseRacesTicketWager: builder.mutation<AnyTicket, { request: any }>({
      query: ({ request }) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation SubmitWager($input: HorseRacesWagerRequestInput!) {
            submitHorseRacesWager(input: $input) {
              ...TicketFields
            }
          }
        `,
        variables: {
          input: {
            ...request
          }
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(response[`submitHorseRacesWager`])
        return updated
      }
    }),
    removeHorseRacesTicket: builder.mutation<
      { success: boolean; ticketId: string },
      { ticketId: string }
    >({
      query: ({ ticketId }) => ({
        document: gql`
          mutation RemoveTicket($ticketId: String!) {
            removeHorseRacesTicket(ticketId: $ticketId) {
              entityId
              success
            }
          }
        `,
        variables: {
          ticketId
        }
      }),
      transformResponse: (response: {
        removeHorseRacesTicket: { success: boolean; entityId: string }
      }) => {
        return {
          success: response.removeHorseRacesTicket.success,
          ticketId: response.removeHorseRacesTicket.entityId
        }
      }
    }),
    removeHorseRacesWager: builder.mutation<
      AnyTicket,
      { ticketId: string; wagerId: string }
    >({
      query: ({ ticketId, wagerId }) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation RemoveTicketWager($ticketId: String!, $wagerId: String!) {
            removeHorseRacesTicketWager(
              ticketId: $ticketId
              wagerId: $wagerId
            ) {
              ...TicketFields
            }
          }
        `,
        variables: {
          ticketId,
          wagerId
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(response[`removeHorseRacesTicketWager`])
        return updated
      }
    }),
    removeHorseRacesWagerCollection: builder.mutation<
      AnyTicket,
      { ticketId: string; wagerCollectionId: string }
    >({
      query: ({ ticketId, wagerCollectionId }) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation RemoveTicketWagerCollection(
            $ticketId: String!
            $wagerCollectionId: String!
          ) {
            removeHorseRacesTicketWagerCollection(
              ticketId: $ticketId
              wagerCollectionId: $wagerCollectionId
            ) {
              ...TicketFields
            }
          }
        `,
        variables: {
          ticketId,
          wagerCollectionId
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(
          response[`removeHorseRacesTicketWagerCollection`]
        )
        return updated
      }
    }),
    submitHorseRacesTicket: builder.mutation<AnyTicket, { ticketId: string }>({
      query: ({ ticketId }) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation SubmitTicket($ticketId: String!) {
            submitHorseRacesTicket(ticketId: $ticketId) {
              ...TicketFields
            }
          }
        `,
        variables: {
          ticketId
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(response[`submitHorseRacesTicket`])
        return updated
      }
    }),
    cancelHorseRacesTicket: builder.mutation<
      WorkstationTransaction,
      { ticketNumber: string; allowProviderTicket: boolean }
    >({
      query: ({ ticketNumber, allowProviderTicket }) => ({
        document: gql`
          ${WORKSTATION_TICKET_TRANSACTION_RECEIPT}
          mutation CancelTicket(
            $ticketNumber: String!
            $allowProviderTicket: Boolean
          ) {
            cancelHorseRacesTicket(
              ticketNumber: $ticketNumber
              allowProviderTicket: $allowProviderTicket
            ) {
              ...WorkstationTransactionFragment
            }
          }
        `,
        variables: {
          ticketNumber,
          allowProviderTicket
        }
      }),
      transformResponse: (response: {
        cancelHorseRacesTicket: WorkstationTransaction
      }) => {
        return response.cancelHorseRacesTicket
      }
    }),
    payHorseRacesTicket: builder.mutation<
      WorkstationTransaction,
      { ticketNumber: string; allowProviderTicket: boolean }
    >({
      query: ({ ticketNumber, allowProviderTicket }) => ({
        document: gql`
          ${WORKSTATION_TICKET_TRANSACTION_RECEIPT}
          mutation PayTicket(
            $ticketNumber: String!
            $allowProviderTicket: Boolean
          ) {
            payHorseRacesTicket(
              ticketNumber: $ticketNumber
              allowProviderTicket: $allowProviderTicket
            ) {
              ...WorkstationTransactionFragment
            }
          }
        `,
        variables: {
          ticketNumber,
          allowProviderTicket
        }
      }),
      transformResponse: (response: {
        payHorseRacesTicket: WorkstationTransaction
      }) => {
        return response.payHorseRacesTicket
      }
    })
  })
})

export const {
  useLazyGetHorseRacesLeagueEventsQuery,
  useGetHorseRacesLeagueEventsQuery,
  useLazyGetHorseRacesPoolLeagueEventsQuery,
  useGetHorseRacesPoolLeagueEventsQuery,
  useLazyGetHorseRacesTicketQuery,
  useLazyGetHorseRacesTicketsQuery,
  useLazyGetHorseRacesTicketTypebetSalesQuery,
  useSubmitHorseRacesTicketWagerMutation,
  useRemoveHorseRacesTicketMutation,
  useRemoveHorseRacesWagerMutation,
  useRemoveHorseRacesWagerCollectionMutation,
  useSubmitHorseRacesTicketMutation,
  useCancelHorseRacesTicketMutation,
  usePayHorseRacesTicketMutation
} = horseRacesApi

export default horseRacesApi
