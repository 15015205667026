import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductEnum } from 'src/placebet/core'
import { parseErrorResponse } from '../api/productApi/services'
import { Workstation } from '../models'
import appContextApi from './api/appContextApi'
import { RegisteredStatusEnum } from './api/models'

export interface AppState {
  product: ProductEnum
  error?: Error
  workstation?: Workstation
  registered?: RegisteredStatusEnum
  sidebarOpen?: boolean
  rightSidebarOpen?: boolean
}

const initialState: AppState = {
  product: 'NONE'
}

const appContextSlice = createSlice({
  name: 'appContext',
  initialState,
  reducers: {
    setProduct: (state, { payload }: PayloadAction<ProductEnum>) => {
      state.product = payload
    },
    toggleSidebar: (state) => {
      state.sidebarOpen = !state.sidebarOpen
    },
    closeSidebar: (state) => {
      state.sidebarOpen = false
    },
    toggleRightSidebar: (state) => {
      state.rightSidebarOpen = !state.rightSidebarOpen
    },
    closeRightSidebar: (state) => {
      state.rightSidebarOpen = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        appContextApi.endpoints.verifyRegistration.matchFulfilled,
        (state, { payload }) => {
          state.workstation = payload.workstation
          state.registered = payload.registered
        }
      )
      .addMatcher(
        appContextApi.endpoints.verifyRegistration.matchRejected,
        (state, { payload }) => {
          state.registered = 'UNKNOWN'
          state.error = parseErrorResponse(payload)
        }
      )
  }
})

export const {
  setProduct,
  toggleSidebar,
  closeSidebar,
  toggleRightSidebar,
  closeRightSidebar
} = appContextSlice.actions
export default appContextSlice
