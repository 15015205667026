import { Box, Card, Container, styled } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import Logo from 'src/components/LogoSign'
import Hero from './Hero'
import Footer from 'src/components/Footer'

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    width: 100%;
    display: flex;
    align-items: center;
    height: ${theme.spacing(10)};
    margin-bottom: ${theme.spacing(10)};
`
)

const OverviewWrapper = styled(Box)(
  ({ theme }) => `
    overflow: auto;
    background: ${theme.palette.common.white};
    flex: 1;
    overflow-x: hidden;
`
)

function Landing() {
  const { t }: { t: any } = useTranslation()

  return (
    <OverviewWrapper>
      <Helmet>
        <title>Placebet</title>
      </Helmet>
      <HeaderWrapper>
        <Container maxWidth="lg">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex={1}
          >
            <Box>
              <Logo />
            </Box>
            <Box>{/* <LanguageSwitcher /> */}</Box>
          </Box>
        </Container>
      </HeaderWrapper>
      <Hero />
      {/* <Highlights /> */}
      <Footer />
    </OverviewWrapper>
  )
}

export default Landing
