import Typography, {
  TypographyPropsVariantOverrides
} from '@mui/material/Typography'
import { Box, Grid, Stack } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'

export default function TicketListItem({
  primary,
  secondary,
  legend,
  variant,
  typographyColor,
  fontSize,
  primaryWidth
}: {
  fontSize?: number
  primary?: string | number | JSX.Element
  legend?: string | number | JSX.Element
  secondary?: string | number | JSX.Element
  variant: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >
  typographyColor?: string
  primaryWidth?: string
}) {
  return (
    <Stack
      sx={{ width: '100%', p: 0 }}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      direction={'row'}
    >
      <Box sx={{ maxWidth: primaryWidth || '100%' }}>
        <Typography
          variant={variant}
          align="left"
          color={typographyColor}
          fontSize={fontSize}
        >
          <strong style={{ wordWrap: 'break-word' }}>{primary}</strong>
        </Typography>
      </Box>
      <Stack direction="row" spacing={2}>
        {legend && (
          <Typography
            fontSize={fontSize}
            sx={{ mx: 0.2 }}
            color={typographyColor}
            variant={variant}
          >
            {legend}
          </Typography>
        )}
        {secondary && (
          <Typography
            fontSize={fontSize}
            variant={variant}
            color={typographyColor}
          >
            {secondary}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
