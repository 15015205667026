import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import {
  WalletTransaction,
  WorkstationTransaction,
  useAppSelector
} from 'src/placebet/core'
import {
  TicketTransactionEvent,
  WorkstationSalesByCategory,
  WorkstationTransactionItem
} from 'src/placebet/core/api/productApi/models'
import { setPrintContent } from 'src/placebet/core/api/productApi/productSlice'
import { AnyTicket, LeagueEvent } from 'src/placebet/core/models'
import {
  EventDetails,
  EventResults
} from 'src/placebet/products/cockfights/models'
import { CashTransactionReceipt } from 'src/placebet/products/components/recepits/CashTransactions'
import DepositReceipt from 'src/placebet/products/components/recepits/DepositReceipt'
import SalesSummaryReceipt from 'src/placebet/products/components/recepits/SalesSummaryReceipt'
import {
  CockfightsTicketReceipt,
  HorseRacesTicketReceipt
} from 'src/placebet/products/components/recepits/TicketReceipts/TicketReceipts'
import TicketTransactionReceipt from 'src/placebet/products/components/recepits/TicketTransactionReceipt'
import WithdrawalReceipt from 'src/placebet/products/components/recepits/WithdrawalReceipt'
import OpenReceipt from 'src/placebet/products/reports/LeagueEventOpenResultsReceipts/OpenReceipt'
import ResultsReceipt from 'src/placebet/products/reports/LeagueEventOpenResultsReceipts/ResultsReceipt'
import TicketTransactionsReceipt from 'src/placebet/products/reports/SalesResume/TicketTransactions/TicketTransactionsReceipt'

const pageStyle = `
  @page {
    size: 80mm 297mm; 
    margin: 0mm;  
  }
`

const PrintWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  mx: 0,
  my: 1,
  p: 1,
  '& .MuiTypography-root': {
    lineHeight: '15px'
  }
}))

const Printer = () => {
  const { printContent } = useAppSelector((state) => state.product)

  const componentRef = useRef<any>(null)

  const printContentComponent = useMemo(() => {
    if (!printContent) {
      return null
    }
    const { kind, payload } = printContent
    switch (kind) {
      case 'SALES_SUMMARY': {
        return (
          <SalesSummaryReceipt
            product={printContent.product}
            {...(payload as WorkstationSalesByCategory)}
            date={printContent?.date}
          />
        )
      }

      case 'SALE': {
        return printContent.product === 'HORSE_RACES' ? (
          <HorseRacesTicketReceipt tickets={payload as AnyTicket[]} />
        ) : (
          <CockfightsTicketReceipt tickets={payload as AnyTicket[]} />
        )
      }

      case 'CANCELATION':
      case 'PAYMENT': {
        return (
          <TicketTransactionReceipt
            transaction={payload as WorkstationTransaction}
          />
        )
      }

      case 'DRAW':
      case 'PAYDRAW': {
        return (
          <CashTransactionReceipt {...(payload as WorkstationTransaction)} />
        )
      }

      case 'DEPOSIT': {
        return <DepositReceipt {...(payload as WalletTransaction)} />
      }

      case 'WITHDRAWAL': {
        return <WithdrawalReceipt {...(payload as WalletTransaction)} />
      }
      case 'COCKFIGHTS_LEAGUE_EVENT_OPEN': {
        return (
          <OpenReceipt
            events={payload as LeagueEvent<EventDetails, EventResults>[]}
          />
        )
      }
      case 'COCKFIGHTS_LEAGUE_EVENT_RESULTS': {
        return (
          <ResultsReceipt
            events={payload as LeagueEvent<EventDetails, EventResults>[]}
          />
        )
      }
      case 'TICKET_ACTIVITY': {
        return (
          <TicketTransactionsReceipt
            items={
              payload as WorkstationTransactionItem<TicketTransactionEvent>[]
            }
          />
        )
      }

      default:
        return null
    }
  }, [printContent])

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      window.focus()
    }
  })

  useEffect(() => {
    if (printContent) {
      handlePrint()
      setPrintContent(null)
    }
  }, [printContent])

  return (
    <Box sx={{ height: 0, overflow: 'hidden' }}>
      <PrintWrapper ref={componentRef}>{printContentComponent}</PrintWrapper>
    </Box>
  )
}

export default Printer
