import { useContext, useMemo } from 'react'
import {
  IconButton,
  alpha,
  Tooltip,
  styled,
  useTheme,
  Badge,
  BadgeProps,
  Stack,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  beginToolbarAction,
  KeyboardMapperContext,
  triggerModalCommand,
  ToolbarAction
} from 'src/placebet/products/providers/CustomKeyboardProvider/state'
import { matchPath } from 'react-router-dom'
import { useLocation } from 'react-router'
import { DictionaryOf } from 'src/placebet/core'

function ActionButton({
  title,
  action,
  icon,
  disabledAt,
  variant = 'ModalCommand'
}: {
  title?: string
  action: string | ToolbarAction
  icon: React.ReactNode
  disabledAt?: string
  variant?: 'ModalCommand' | 'Command'
}) {
  const theme = useTheme()
  const { t: translation } = useTranslation()
  const {
    dispatch,
    state: {
      customKeyboard: { keys }
    }
  } = useContext(KeyboardMapperContext)
  const location = useLocation()

  const disabled = useMemo(() => {
    if (!disabledAt) return false

    const match = matchPath(
      {
        path: '/app/:product/:path',
        end: true
      },
      location.pathname
    )
    const result = match?.params as {
      product?: string
      path?: string
    }

    return result?.path === disabledAt
  }, [location])

  const ImageWrapper = styled(icon as any)({ width: '30px' })
  const color = theme.colors.info.main

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -4,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px'
    }
  }))

  const fnKey = useMemo(() => {
    let reversedMap: DictionaryOf<string> = {}
    Object.keys(keys).forEach((k) => {
      const key = keys[k] as string
      reversedMap = { ...reversedMap, [key]: k }
    })

    return reversedMap[action]
  }, [keys])

  return (
    <>
      <Tooltip arrow title={translation(action.toUpperCase())}>
        <Stack spacing={0.5}>
          <IconButton
            disabled={disabled}
            color="primary"
            onClick={() =>
              variant === 'ModalCommand'
                ? dispatch(triggerModalCommand(action))
                : dispatch(beginToolbarAction(action as ToolbarAction))
            }
            sx={{
              width: theme.spacing(4),
              height: theme.spacing(4),
              borderRadius: theme.general.borderRadiusLg,
              mx: 1,
              background: alpha(color, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: color,

              '&:hover': {
                background: alpha(color, 0.2)
              }
            }}
          >
            <StyledBadge badgeContent={fnKey} color="secondary">
              <ImageWrapper />
            </StyledBadge>
          </IconButton>
          <Typography
            display={'flex'}
            sx={{ justifyContent: 'center', fontSize: '10px' }}
          >
            {title || action}
          </Typography>
        </Stack>
      </Tooltip>
    </>
  )
}

export default ActionButton
