import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Stack, styled } from '@mui/material'
import { customFormatLocalDate, useAppSelector } from 'src/placebet/core'
import { useTranslation } from 'react-i18next'
import { ReceiptTypography } from 'src/placebet/products/components/recepits/components/styled'
import { DashedDivider } from 'src/placebet/products/components/recepits/TicketReceipt'

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: 15,
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  borderWidth: 'thin',
  borderColor: theme.palette.primary.contrastText,
  textTransform: 'inherit',
  lineHeight: '1.2em'
}))

const InfoHeader = () => {
  const { t }: { t: any } = useTranslation()
  const now = new Date()
  const { event: event } = useAppSelector((state) => state.cockfights)

  return (
    <Stack
      direction="column"
      alignItems="center"
      alignContent="center"
      spacing={1}
      sx={{ paddingBottom: 1 }}
    >
      <ReceiptTypography
        variant="caption"
        noWrap
        fontSize={14}
        sx={{ textTransform: 'none', paddingTop: 0.5, paddingBottom: 0 }}
      >
        {`${customFormatLocalDate(now)}`}
      </ReceiptTypography>
      <ReceiptTypography
        variant="caption"
        noWrap
        fontSize={14}
        sx={{ textTransform: 'none' }}
      >
        {event?.league.name}
      </ReceiptTypography>
    </Stack>
  )
}

export default InfoHeader
