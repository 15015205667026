import { gql } from '@apollo/client'

export const CORE_TICKET_FIELDS = gql`
  fragment CoreTicketFields on Ticket {
    id
    status
    product
    number
    grouping
    currency
    date
    expiration
    payoff
    userId
    user {
      givenName
      familyName
      email
    }
    workstationId
    workstation {
      name
      agency {
        name
        enterprise {
          name
          slogan
          logo
        }
        products {
          name
          code
        }
      }
    }
    totalAmount
  }
`
export const WAGER_PAYOFF_FRAGMENT = gql`
  fragment WagerPayoffFragment on WagerPayoff {
    ticketId
    claimed
    payoff {
      time
      net
      gross
      refund
      credit
      surcharge
      pending
      cancel
      sourceId
      __typename
    }
    currency
    message
    __typename
  }
`
