import { Loader } from 'src/placebet/components'
import { useAppSelector } from 'src/placebet/core'
import useSubscribeToTodayEventsUpdates from 'src/placebet/core/api/productApi/useSubscribeToTodayEventsUpdates'
import CalendarEvents from './CalendarEvents'

function SidebarMenu() {
  const { isFetching: loading, hubStatus } = useSubscribeToTodayEventsUpdates()

  const { todayEvents: results } = useAppSelector((state) => state.product)

  return (
    <Loader loading={loading}>
      <CalendarEvents calendarEvents={results} hubStatus={hubStatus} />
    </Loader>
  )
}

export default SidebarMenu
