import React, { useContext, useEffect } from 'react'
import {
  forbiddenCustomerModules,
  titleCase,
  useUserClaims
} from 'src/placebet/core'
import {
  beginToolbarAction,
  KeyboardMapperContext,
  keyPressed,
  navigateToAction,
  setKeyboardMode,
  ToolbarAction
} from 'src/placebet/products/providers/CustomKeyboardProvider/state'
import { KeyDefinition } from '../models'

function CustomKeyboardListener({ children }: { children: React.ReactNode }) {
  const {
    dispatch,
    state: {
      customKeyboard: {
        keys: keyMappings,
        controlKeys: modalTriggers,
        navigation,
        actionKeys
      },
      mode,
      bypass
    }
  } = useContext(KeyboardMapperContext)
  const user = useUserClaims()
  // User reference to watch the change of the user object after create the event listener
  const userRef = React.useRef(user)

  useEffect(() => {
    if (user) {
      // refresh the reference
      userRef.current = user
    }
  }, [user])

  // Add event listeners
  useEffect(() => {
    if (bypass) {
      // bypass for modal display
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    } else {
      window.addEventListener('keydown', downHandler)
      window.addEventListener('keyup', upHandler)
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener('keydown', downHandler)
        window.removeEventListener('keyup', upHandler)
      }
    }
  }, [bypass])

  // If pressed key is our target key then set to true
  function downHandler(event) {
    const { key, ctrlKey, shiftKey, altKey, keyCode, metaKey } = event

    if (
      key &&
      key !== 'Shift' &&
      key !== 'Control' &&
      key !== 'Alt' &&
      !metaKey
    ) {
      var mapKey = shiftKey
        ? `Shift+${titleCase(key as string)}`
        : ctrlKey
        ? `Ctrl+${titleCase(key as string)}`
        : altKey
        ? `Alt+${titleCase(key as string)}`
        : key

      var mappedKey = keyMappings[mapKey] || keyMappings[key]

      if (
        userRef.current.isCustomer &&
        forbiddenCustomerModules.includes(mappedKey as string)
      ) {
        event.preventDefault()
        return
      }

      let payload: any = {}
      if (!!mappedKey) {
        if (!!(mappedKey as KeyDefinition)?.value) {
          const { value } = mappedKey as KeyDefinition
          payload = { key: value, mapped: true }
        } else {
          payload = { key: mappedKey as string, mapped: true }
        }
      } else {
        payload = { key, mapped: false }
      }

      const detail = payload as {
        key: string
        mapped: boolean
      }

      // Modal Triggers
      const val = modalTriggers[payload.key]
      if (!!val) {
        const modalCommand = (val as KeyDefinition).value || (val as string)
        if (mode === 'ModalInput' || mode === 'Input') {
          dispatch(setKeyboardMode({ mode: 'Standard' }))
        } else {
          dispatch(
            setKeyboardMode({ mode: 'ModalInput', command: modalCommand })
          )
        }
      } else if (actionKeys[payload.key]) {
        dispatch(beginToolbarAction(payload.key as ToolbarAction))
      } else if (navigation[payload.key]) {
        dispatch(navigateToAction(payload.key as ToolbarAction))
      } else {
        //
        window.dispatchEvent(
          new CustomEvent('mappedKeyPressed', {
            bubbles: true,
            detail
          })
        )
        dispatch(keyPressed(payload as { key: string; mapped: boolean }))
      }
    }
    // Avoid scrolling
    if (key === 'PageDown' || key === 'End') {
      event.preventDefault()
    }

    event.preventDefault()
  }

  // If released key is our target key then set to false
  function upHandler(event) {
    const { key, ctrlKey, shiftKey, altKey, keyCode, metaKey } = event

    window.dispatchEvent(
      new CustomEvent('mappedKeyUp', {
        bubbles: true,
        detail: {}
      })
    )

    event.preventDefault()
  }

  return <>{children}</>
}

export default CustomKeyboardListener
