import { styled, Box, Stack } from '@mui/material'
import { WagerLeg } from 'src/placebet/products/horseraces/models'

const Item = styled('div')(() => ({
  flexGrow: 1
}))

export const DisplayLegs = ({
  legs,
  isMulti
}: {
  legs: WagerLeg[]
  isMulti: boolean
}) => {
  if (isMulti) {
    const displayLegs = legs.map((leg, idxLeg) => (
      <Box
        key={idxLeg}
        sx={{ width: 200, marginBottom: idxLeg + 1 === legs.length ? 0.5 : 0 }}
      >
        <Stack direction={'row'} flexWrap="wrap" spacing={0}>
          {leg.runners.map((runner, idxRunner) => (
            <Stack
              key={idxRunner}
              direction={'row'}
              flexWrap="wrap"
              spacing={0}
            >
              <Item key={idxRunner}>
                {idxRunner + 1 < leg.runners.length
                  ? `${runner.runnerId},`
                  : runner.runnerId}
              </Item>
            </Stack>
          ))}
        </Stack>
      </Box>
    ))

    return <Box>{displayLegs}</Box>
  } else {
    const displayLegs = legs.map((leg, idxLeg) => (
      <>
        {leg.runners.map((runner, idxRunner) => (
          <Stack key={idxRunner} direction={'row'} flexWrap="wrap" spacing={0}>
            <Item key={idxRunner}>
              {idxRunner + 1 < leg.runners.length
                ? `${runner.runnerId},`
                : runner.runnerId}
            </Item>
          </Stack>
        ))}
        <Stack direction={'row'} flexWrap="wrap" spacing={0}>
          <Item>{idxLeg + 1 < legs.length ? '/' : ''}</Item>
        </Stack>
      </>
    ))

    return (
      <Box sx={{ width: 200, marginBottom: 0.5 }}>
        <Stack
          spacing={0}
          direction={'row'}
          justifyContent="flex-start"
          flexWrap="wrap"
        >
          {displayLegs}
        </Stack>
      </Box>
    )
  }
}
