import {
  Typography,
  TableRow,
  TableCell,
  TableContainer,
  useTheme,
  Stack,
  darken,
  alpha,
  TableBody
} from '@mui/material'

import { useTranslation } from 'react-i18next'

import { Entry } from 'src/placebet/products/horseraces/models'
import _ from 'lodash'
import { RunnerPosition } from '../../../components'
import { CustomTypography } from 'src/placebet/components/Typography'
import {
  TableHeadWrapper,
  TableWrapper
} from 'src/placebet/products/components/styled'
import { formatWeight } from 'src/placebet/products/horseraces/services'

function Content({ entries }: { entries: Entry[] }) {
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()

  return (
    <TableContainer>
      <TableWrapper size="small">
        <TableHeadWrapper>
          <TableRow>
            <TableCell align="center" sx={{ maxWidth: '24px' }}>
              {t('POSICION')}
            </TableCell>
            <TableCell sx={{ textAlign: 'start' }}>
              <Typography variant="h5">{`${t('RUNNER')}/${t(
                'JOCKEY'
              )}`}</Typography>
            </TableCell>
            <TableCell align="right">{t('CARGA')}</TableCell>
            <TableCell align="right">{t('MEDICAMENTO')}</TableCell>
            <TableCell align="right">{t('ODDS')}</TableCell>
          </TableRow>
        </TableHeadWrapper>
        <TableBody
          sx={{
            '& tr:nth-of-type(2n+1)': {
              backgroundColor: darken(
                alpha(theme.colors.primary.dark, 0.2),
                0.5
              )
            }
          }}
        >
          {entries.map((entry) => {
            const {
              runnerId,
              entryId,
              scratch,
              name,
              jockey,
              medication,
              weight,
              odds
            } = entry

            return (
              <TableRow
                key={entry.id}
                sx={{
                  opacity: scratch ? 0.4 : 1
                }}
              >
                <TableCell
                  align="center"
                  sx={{ display: 'flex', justifyContent: 'center' }}
                >
                  <RunnerPosition
                    variant="small"
                    runnerId={runnerId as number}
                    entryId={entryId}
                    scratched={scratch}
                  />
                </TableCell>
                <TableCell align="center">
                  <Stack direction={'row'} spacing={1}>
                    <CustomTypography variant="subtitle2" scratched={scratch}>
                      <b>{name}</b>
                    </CustomTypography>
                    <CustomTypography variant="body1">/</CustomTypography>
                    <CustomTypography variant="subtitle2" scratched={scratch}>
                      {jockey}
                    </CustomTypography>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  {weight && (
                    <CustomTypography variant="body1" scratched={scratch}>
                      <b>{formatWeight(weight)}</b>
                      <span>&nbsp;{t('LBS')}</span>
                    </CustomTypography>
                  )}
                </TableCell>
                <TableCell align="right">
                  <CustomTypography variant="body1" scratched={scratch}>
                    {medication}
                  </CustomTypography>
                </TableCell>
                <TableCell align="right">
                  <CustomTypography variant="body1" scratched={scratch}>
                    {odds}
                  </CustomTypography>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </TableWrapper>
    </TableContainer>
  )
}

export default Content
