import { Grid } from '@mui/material'
import { LeagueEvent, secondsToMinutesString } from 'src/placebet/core'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import React from 'react'
import {
  EventDetails,
  EventResults
} from 'src/placebet/products/cockfights/models'
import InfoHeader from './components/InfoHeader'
import { HeaderReceipt } from './components/HeaderReceipt'
import { ReceiptTypography } from '../../components/recepits/components/styled'
import { DashedDivider } from 'src/placebet/products/components/recepits/TicketReceipt'
import { TruncateText } from 'src/placebet/components'

const ResultsReceipt = ({
  events
}: {
  events: LeagueEvent<EventDetails, EventResults>[]
}) => {
  const { t }: { t: any } = useTranslation()

  const resultsEvents = events.filter((e) => e.eventStatus !== 'Open')

  return (
    <>
      <InfoHeader />
      <Grid container spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        <HeaderReceipt />
        <DashedDivider />

        {resultsEvents.map((item, i) => {
          const { details, results, eventName, eventStatus } = item
          const {
            details: { teams }
          } = item

          if (!results) {
            return null
          }

          return (
            <React.Fragment key={i}>
              <Grid
                container
                spacing={1}
                sx={{ paddingLeft: 1, paddingRight: 1 }}
              >
                <Grid item xs={1}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {details.blockNumber}
                  </ReceiptTypography>
                </Grid>

                <Grid item xs={1}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {eventName}
                  </ReceiptTypography>
                </Grid>

                <Grid item xs={6}>
                  <TruncateText style={{ width: '120px' }}>
                    <ReceiptTypography
                      variant="caption"
                      noWrap
                      sx={{ textTransform: 'none' }}
                    >
                      {teams.blue.name}
                    </ReceiptTypography>
                  </TruncateText>
                </Grid>

                <Grid item xs={2}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {results.teams.blue.winner ? '1' : '0'}
                  </ReceiptTypography>
                </Grid>

                <Grid item xs={2}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {secondsToMinutesString(results.duration || 0)}
                  </ReceiptTypography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                sx={{ paddingLeft: 1, paddingRight: 1 }}
              >
                <Grid item xs={1} />
                <Grid item xs={1} />
                <Grid item xs={6}>
                  <TruncateText style={{ width: '120px' }}>
                    <ReceiptTypography
                      variant="caption"
                      noWrap
                      sx={{ textTransform: 'none' }}
                    >
                      {teams.white.name}
                    </ReceiptTypography>
                  </TruncateText>
                </Grid>

                <Grid item xs={2}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {results.teams.white.winner ? '1' : '0'}
                  </ReceiptTypography>
                </Grid>

                <Grid item xs={2}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none', marginRight: 7 }}
                  >
                    {t(eventStatus.toUpperCase())}
                  </ReceiptTypography>
                </Grid>
              </Grid>

              <DashedDivider />
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
}

export default ResultsReceipt
