import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableFooter from '@mui/material/TableFooter'
import TableRow from '@mui/material/TableRow'
import { styled, Typography } from '@mui/material'
import { customFormatLocalDate, LeagueEvent } from 'src/placebet/core'
import { useTranslation } from 'react-i18next'
import { EventDetails, EventResults } from '../../horseraces/models'
import { Runners } from './components/Runners'
import _ from 'lodash'
import { ReceiptTypography } from '../../components/recepits/components/styled'

const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  paddingTop: '1px',
  paddingBottom: '0px',
  borderStyle: 'double',
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  borderColor: theme.palette.primary.contrastText
}))

const FooterTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  paddingBottom: '0px',
  lineHeight: '1.2em'
}))

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  paddingBottom: 0,
  paddingTop: 1,
  lineHeight: '1.2em',
  borderStyle: 'inset',
  borderTop: 0,
  borderLeft: 0,
  borderRight: 0,
  borderColor: theme.palette.primary.contrastText,
  borderWidth: 'thin'
}))

const LeagueEventReceipt = ({
  leagueName,
  events,
  print = false
}: {
  leagueName: string
  events: LeagueEvent<EventDetails, EventResults>[]
  print?: boolean
}) => {
  const { t }: { t: any } = useTranslation()
  const now = new Date()

  const clonedEvents = _.cloneDeep(events)
  const sortedEventsByRaceId = clonedEvents.sort((a, b) => {
    return a.details.raceId - b.details.raceId
  })

  return (
    <Table>
      {leagueName.length > 0 && (
        <TableHead>
          <TableRow>
            <HeaderTableCell colSpan={6} align="center">
              <ReceiptTypography
                style={{ color: print ? '#000000' : 'inherit' }}
              >
                {leagueName}
              </ReceiptTypography>
            </HeaderTableCell>
          </TableRow>
        </TableHead>
      )}

      <TableBody>
        {sortedEventsByRaceId.map((leagueEvent, l: number) => {
          return (
            leagueEvent.eventStatus === 'Open' && (
              <TableRow key={l}>
                <CustomTableCell align="center" width={'10%'}>
                  {leagueEvent.details.raceId}
                </CustomTableCell>
                <CustomTableCell width={'90%'} align="left">
                  <ReceiptTypography
                    style={{
                      display: 'flex',
                      paddingBottom: '2px',
                      color: print ? '#000000' : 'inherit'
                    }}
                  >
                    {abbreviatePoolList(
                      t(leagueEvent.details.poolList)
                    ).replace(/,/g, ' ')}
                  </ReceiptTypography>
                  <ReceiptTypography
                    style={{
                      display: 'flex',
                      color: print ? '#000000' : 'inherit'
                    }}
                  >
                    {leagueEvent.details.live}{' '}
                    {leagueEvent.details.runners.length > 0 && (
                      <Runners runners={leagueEvent.details.runners} />
                    )}
                  </ReceiptTypography>
                </CustomTableCell>
              </TableRow>
            )
          )
        })}
      </TableBody>
      <TableFooter>
        <TableRow>
          <FooterTableCell colSpan={12} style={{ marginTop: 5 }}>
            <ReceiptTypography style={{ color: print ? '#000000' : 'inherit' }}>
              {customFormatLocalDate(now)}
            </ReceiptTypography>
          </FooterTableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

const abbreviatePoolList = (poolList) => {
  const splitPoolList = poolList.split(',')

  const comb1 = [
    'WIN',
    'PLC',
    'SHW',
    'WP',
    'WPS',
    'WS',
    'PS',
    'EXA',
    'TRI',
    'SPR'
  ]
  const comb2 = ['WIN', 'PLC', 'SHW', 'WP', 'WPS', 'EXA', 'TRI']
  const comb3 = ['WIN', 'PLC', 'WP', 'EXA', 'TRI']

  if (comb1.every((v) => splitPoolList.includes(v))) {
    const result = splitPoolList.filter(function (el) {
      return !comb1.includes(el)
    })
    const finalArray = ['WPS,EXA,TRI,SPR', ...result]
    return finalArray.join(' ')
  }

  if (comb2.every((v) => splitPoolList.includes(v))) {
    const result = splitPoolList.filter(function (el) {
      return !comb2.includes(el)
    })
    const finalArray = ['WPS', 'EXA', 'TRI', ...result]
    return finalArray.join(' ')
  }

  if (comb3.every((v) => splitPoolList.includes(v))) {
    const result = splitPoolList.filter(function (el) {
      return !comb3.includes(el)
    })
    const finalArray = ['WP, EXA, TRI', ...result]
    return finalArray.join(' ')
  }

  return poolList
}
export default LeagueEventReceipt
