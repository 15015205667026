import React from 'react'
import { Box, CircularProgress, styled } from '@mui/material'
import { Counter } from '../Counter'

const LoaderContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'flex-center',
  width: '100%',
  height: '100%',
  my: 5,
  color: theme.palette.secondary.dark
}))

export const LoadingAnimation = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        my: 5
      }}
    >
      <CircularProgress />
    </Box>
  )
}

interface ComponentProps {
  children: React.ReactNode
  component?: React.ReactNode
  loading: boolean
}

export const Loader = ({ children, component, loading }: ComponentProps) => {
  return loading ? (
    <LoaderContent>
      {!!component ? { component } : <LoadingAnimation />}
    </LoaderContent>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  )
}
