import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Avatar,
  Box,
  Button,
  Divider,
  MenuList,
  alpha,
  IconButton,
  MenuItem,
  ListItemText,
  Popover,
  Typography,
  styled,
  useTheme,
  SvgIconTypeMap,
  ListItemIcon
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import useAuth from 'src/placebet/core/auth/useAuth'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { useAppSelector } from 'src/placebet/core'

const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.38)};
    height: ${theme.spacing(1.4)};
    display: inline-block;
    border: ${theme.colors.alpha.white[100]} solid 2px;
`
)

const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  padding: 0;
  height: ${theme.spacing(4)};
  margin-left: ${theme.spacing(1)};
  border-radius: ${theme.general.borderRadiusLg};
  
  &:hover {
    background: ${theme.colors.primary.main};
  }
`
)

const UserAvatar = styled(Avatar)(
  ({ theme }) => `
        height: 90%;
        width: 90%;
        border-radius: ${theme.general.borderRadiusLg};
`
)

const MenuListWrapperPrimary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(2)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
)

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        padding: ${theme.spacing(2)};
`
)

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
)

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
)

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
)

export interface UserMenuOption {
  id: string
  label: string
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
}

function HeaderUserbox({ options = [] }: { options?: UserMenuOption[] }) {
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()
  const [selected, setSelected] = useState<string>('settings')

  const { workstation } = useAppSelector((state) => state.appContext)

  const navigate = useNavigate()
  const { user, logout } = useAuth()

  const ref = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose()
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }

  if (!user) {
    return <React.Fragment />
  }

  return (
    <>
      <UserBoxButton color="primary" ref={ref} onClick={handleOpen}>
        <UserAvatar alt={user.name} src={user.avatar} />
      </UserBoxButton>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox
          sx={{
            minWidth: 210
          }}
          display="flex"
        >
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle ?? t('USUARIO')}
            </UserBoxDescription>
            {workstation && (
              <>
                <UserBoxLabel variant="body1">
                  {workstation.agency.name}
                </UserBoxLabel>
                <UserBoxLabel variant="body1">{workstation.name}</UserBoxLabel>
              </>
            )}
          </UserBoxText>
        </MenuUserBox>
        <Divider
          sx={{
            mb: 0
          }}
        />
        <MenuListWrapperPrimary disablePadding>
          {options.map((option) => {
            const Icon = option.icon ?? null
            return (
              <MenuItem key={option.id}>
                {Icon && (
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                )}
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h5'
                  }}
                  primary={t(option.label)}
                />
                <Box display="flex" alignItems="center">
                  {!!selected && selected === option.id && (
                    <DotLegend
                      style={{
                        background: `${theme.colors.warning.main}`
                      }}
                    />
                  )}
                  <ChevronRightTwoToneIcon
                    sx={{
                      ml: 1,
                      color: `${theme.colors.alpha.black[30]}`,
                      opacity: 0.8
                    }}
                  />
                </Box>
              </MenuItem>
            )
          })}
        </MenuListWrapperPrimary>
        <Divider />
        <Box m={1}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon
              sx={{
                mr: 1
              }}
            />
            {t('LOGOUT')}
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default HeaderUserbox
