import React from 'react'
import { AuthContextInterface, AuthService } from './authService'

export const AuthContext = React.createContext<AuthContextInterface>(
  new AuthService()
)

export const AuthConsumer = AuthContext.Consumer

export interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const authService = new AuthService({}, true)

  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  )
}
