import { useContext, useEffect } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Zoom from '@mui/material/Zoom'
import AddIcon from '@mui/icons-material/AddShoppingCart'
import {
  Button as MuiButton,
  ButtonProps,
  Tooltip,
  Typography
} from '@mui/material'
import { ProductWagerRequestInput, Leg } from '../../../../../models'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'src/placebet/core'
import { useSubmitHorseRacesTicketWagerMutation } from 'src/placebet/products/horseraces/redux/horseRacesProductApi'

const Button = styled(MuiButton)<ButtonProps>(({ theme }) => ({
  backgroundColor: `${theme.colors.info.dark}`,
  color: `${theme.palette.getContrastText(theme.colors.info.dark)}`,
  '&:hover': {
    backgroundColor: `${theme.colors.info.main}`,
    color: `${theme.palette.getContrastText(theme.colors.info.main)}`
  }
}))

export default function SubmitWagerButton() {
  const theme = useTheme()
  const { t: translation } = useTranslation()

  const {
    horseRaces: {
      ticket,
      pool,
      busy,
      event: {
        eventId,
        details: { raceId: mainRace }
      },
      error,
      wagerPreview: { bet },
      boardWagerRequest: { canSubmitWager, legs, allowedWagers, modifier }
    }
  } = useAppSelector((state) => state)

  const [submitWager] = useSubmitHorseRacesTicketWagerMutation()

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  }

  const handleSubmitWager = async () => {
    const requestLegs: Leg[] = []

    Object.keys(legs || {}).forEach((key) => {
      requestLegs.push({ ...legs[key] })
    })

    const request: ProductWagerRequestInput = {
      ticketId: ticket?.id,
      eventId: eventId || '',
      bet,
      typeBet: pool?.poolId || 'WIN',
      race: mainRace || 0,
      legs: requestLegs,
      modifier: modifier
    }

    await submitWager({ request })
  }

  const label = translation('ADD_TO_TICKET')

  if (!allowedWagers || allowedWagers.length === 0) {
    return null
  }

  return (
    <Zoom
      in={canSubmitWager}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${canSubmitWager ? transitionDuration.exit : 0}ms`
      }}
      unmountOnExit
    >
      <Tooltip title={label}>
        <Button
          variant="outlined"
          size="small"
          aria-label={label}
          onClick={handleSubmitWager}
          disabled={!canSubmitWager || busy}
          startIcon={<AddIcon fontSize="small" />}
        >
          <Typography variant="body2">{allowedWagers?.length}</Typography>
        </Button>
      </Tooltip>
    </Zoom>
  )
}
