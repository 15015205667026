import { Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

export const Counter = () => {
  //   const [counter, setCounter] = useState<number>(0)

  //   setCounter(counter + 1)

  const renderCounter = useRef(0)
  renderCounter.current = renderCounter.current + 1

  return (
    <Typography variant="caption" color={'red'}>
      Renders: {renderCounter.current}
    </Typography>
  )
}
