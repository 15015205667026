import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  TooltipProps,
  alpha,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone'
import { useNavigate } from 'react-router-dom'
import ThemeSettingsIconButton from './ThemeSettingsIconButton'
import useAuth from 'src/placebet/core/auth/useAuth'
import { useAppSelector } from 'src/placebet/core'
import { useEffect, useMemo, useState } from 'react'
import { useSnackbar } from 'notistack'

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}))

function SidebarFooter() {
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()
  const { logout } = useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [hasBeenDisconnected, setHasBeenDisconnected] = useState(false)
  const {
    realtime: { hubStatus }
  } = useAppSelector((state) => state.product)

  const handleLogout = async (): Promise<void> => {
    try {
      await logout()
      navigate('/')
    } catch (err) {
      console.error(err)
    }
  }

  const status = useMemo(() => {
    switch (hubStatus) {
      case 'Connected':
        return 'success'
      case 'Disconnected':
        return 'error'
      case 'Connecting':
        return 'warning'
      default:
        return 'error'
    }
  }, [hubStatus])

  useEffect(() => {
    if (status === 'warning') {
      setHasBeenDisconnected(true)
      enqueueSnackbar(t('ERROR_DE_CONEXION'), {
        variant: 'error',
        autoHideDuration: 5000
      })
    }

    if (status === 'success' && hasBeenDisconnected) {
      setHasBeenDisconnected(false)
      enqueueSnackbar(t('CONEXION_REESTABLECIDA'), { variant: 'success' })
    }
  }, [status])

  return (
    <Box
      sx={{
        height: 60
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LightTooltip
        sx={{ zIndex: 100 }}
        placement="top"
        arrow
        title={t('THEME')}
      >
        <Badge
          color={status}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{
            '.MuiBadge-badge': {
              animation: 'pulse 1s infinite',
              top: '5%',
              transition: `${theme.transitions.create(['all'])}`
            }
          }}
          variant="dot"
          overlap="circular"
        >
          <ThemeSettingsIconButton />
        </Badge>
      </LightTooltip>
      <LightTooltip placement="top" arrow title={t('LOGOUT')}>
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[10]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          onClick={handleLogout}
        >
          <PowerSettingsNewTwoToneIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
    </Box>
  )
}

export default SidebarFooter
