import { FC, ReactNode, useMemo } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Button,
  Tooltip,
  Badge,
  Collapse,
  ListItem,
  TooltipProps,
  tooltipClasses,
  styled,
  Typography,
  Stack,
  useTheme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import { normalizeRouteParam, useAppSelector } from 'src/placebet/core'

interface SidebarMenuItemProps {
  children?: ReactNode
  link?: string
  icon?: any
  badge?: string
  badgeTooltip?: string
  name: string | ReactNode
  uid: string | number
  secondaryText?: any
  handleNavigation: (link: string) => void
}

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}))

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  badgeTooltip,
  name,
  secondaryText,
  uid: key,
  handleNavigation,
  ...rest
}) => {
  const { t }: { t: any } = useTranslation()

  const {
    horseRaces,
    cockfights,
    appContext: { product }
  } = useAppSelector((state) => state)

  const { league, eventName, ticket } = useMemo(() => {
    switch (product) {
      case 'HORSE_RACES': {
        const league = normalizeRouteParam(
          horseRaces?.event?.league.name || 'NONE'
        )
        const race = `${league}?race=${
          horseRaces.raceParam || horseRaces?.event?.eventName || 'NONE'
        }`
        return {
          league,
          eventName: race,
          ticket: horseRaces?.ticket
        }
      }
      case 'COCKFIGHTS': {
        const league = normalizeRouteParam(
          cockfights?.event?.league.name || 'NONE'
        )
        const match = `${league}?match=${
          cockfights.matchParm || cockfights?.event?.eventName || 'NONE'
        }`
        return {
          league,
          eventName: match,
          ticket: cockfights?.ticket
        }
      }

      default:
        return {
          league: 'NONE',
          event: 'NONE'
        }
    }
  }, [product, horseRaces?.event, cockfights?.event])

  const theme = useTheme()

  if (children) {
    const expanded = link === league || false
    return (
      <ListItem component="div" className="Mui-children" key={key} {...rest}>
        <Button
          sx={{ backgroundColor: 'red', borderRadius: 0 }}
          className={clsx({ 'Mui-active': expanded })}
          startIcon={Icon && <Icon />}
          endIcon={
            expanded ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={() => handleNavigation(link)}
        >
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              <div>
                {badge === '' ? (
                  <Badge color="primary" variant="dot" />
                ) : (
                  <Badge badgeContent={badge} />
                )}
              </div>
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}
          {name}
        </Button>
        <Collapse in={expanded}>{children}</Collapse>
      </ListItem>
    )
  }

  return (
    <ListItem component="div" key={key} {...rest}>
      <Button
        disableRipple
        // activeClassName="Mui-active"
        sx={{ borderRadius: 0 }}
        className={clsx({ 'Mui-active': eventName === link })}
        onClick={() => handleNavigation(link)}
        startIcon={Icon && <Icon />}
      >
        <Stack
          sx={{ p: 0, width: '100%' }}
          display={'flex'}
          alignContent={'space-between'}
          spacing={0}
          direction={'row'}
        >
          <Typography
            noWrap
            sx={{ p: theme.spacing(1), textOverflow: 'ellipsis' }}
          >
            {name}
          </Typography>
          {badgeTooltip ? (
            <TooltipWrapper title={badgeTooltip} arrow placement="right">
              <div>
                {badge === '' ? (
                  <Badge color="primary" variant="dot" />
                ) : (
                  <Badge badgeContent={badge} />
                )}
              </div>
            </TooltipWrapper>
          ) : badge === '' ? (
            <Badge color="primary" variant="dot" />
          ) : (
            <Badge badgeContent={badge} />
          )}

          {secondaryText && (
            <Typography
              noWrap
              color={theme.colors.secondary.light}
              sx={{ p: theme.spacing(1), textOverflow: 'ellipsis' }}
            >
              {secondaryText}
            </Typography>
          )}
        </Stack>
      </Button>
    </ListItem>
  )
}

SidebarMenuItem.propTypes = {
  uid: PropTypes.string.isRequired,
  children: PropTypes.node,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  badgeTooltip: PropTypes.string,
  name: PropTypes.string.isRequired
}

export default SidebarMenuItem
