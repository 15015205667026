import { Dialog, DialogTitle, DialogContent, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'

type PreviewReportProps = {
  open: boolean
  handlePrint?: () => void
  handleClose: () => void
  children: React.ReactNode
}

const PreviewReport = ({
  open,
  handlePrint,
  handleClose,
  children
}: PreviewReportProps) => {
  const { t }: { t: any } = useTranslation()

  const onPrint = () => {
    handleClose()
    if (handlePrint) {
      handlePrint()
    }
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          p: 3
        }}
      >
        <Button
          variant="contained"
          startIcon={<LocalPrintshopIcon />}
          color="primary"
          onClick={onPrint}
          size={'small'}
        >
          {t('IMPRIMIR')}
        </Button>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          p: 3
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default PreviewReport
