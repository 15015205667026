import Wallet from '../pages/Wallet'
import walletRoutes from '../pages/Wallet/routes'

const customerRoutes = [
  {
    path: 'wallet',
    element: <Wallet />,
    children: walletRoutes
  }
]

export default customerRoutes
