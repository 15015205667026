import { WorkstationTransaction, formatAsCurrency } from 'src/placebet/core'
import { t } from 'i18next'
import { ReceiptTypography } from '../components/styled'
import Header from '../components/Header'
import { DashedDivider } from '../TicketReceipt'
import TicketListItem from '../../TicketsSidebarCore/TicketListItem'
import { Stack } from '@mui/material'

export const CashTransactionReceipt = ({
  number,
  date,
  product,
  transaction,
  currency,
  amount,
  workstation
}: WorkstationTransaction) => {
  return (
    <Stack
      display="flex"
      sx={{
        width: '100%',
        p: 0
      }}
      direction={'column'}
      spacing={0}
    >
      <Header
        id={number}
        product={product}
        date={date}
        workstation={workstation}
      />

      <DashedDivider />
      <Stack
        display={'flex'}
        direction={'column'}
        spacing={0.5}
        sx={{ width: '100%', p: 0.2 }}
      >
        <TicketListItem
          fontSize={13}
          typographyColor={'black'}
          variant="h5"
          primary={
            <Stack spacing={1} direction={'row'}>
              <ReceiptTypography>
                {t(`${transaction === 'CREDIT' ? 'PAYDRAW' : 'DRAW'}`)}
              </ReceiptTypography>
              <ReceiptTypography>{`(${currency || 'DOP'})`}</ReceiptTypography>
            </Stack>
          }
          secondary={formatAsCurrency(amount || 0, 2)}
        />
        {/* <TicketListItem
          fontSize={13}
          typographyColor={'black'}
          variant="h5"
          primary={
            <Stack spacing={1} direction={'row'}>
              <ReceiptTypography>{t('PENDING_DRAW')}</ReceiptTypography>
              <ReceiptTypography>{`(${currency || 'DOP'})`}</ReceiptTypography>
            </Stack>
          }
          secondary={formatAsCurrency(pendingDraw || 0, 2)}
        /> */}
      </Stack>
    </Stack>
  )
}
