import { useEffect } from 'react'
import {
  BroadcastContent,
  toPascalCase,
  useAppDispatch,
  useAppSelector,
  useSignalR
} from 'src/placebet/core'
import {
  updateRealtimeConnectionStatus,
  updateTodayEvents
} from './productSlice'
import { LeagueEventsGroup } from './models'
import { useGetTodayCalendarQuery } from './productApi'

const useSubscribeToTodayEventsUpdates = () => {
  const { product } = useAppSelector((state) => state.appContext)
  const dispatch = useAppDispatch()

  // trigger initial load
  const { isFetching } = useGetTodayCalendarQuery({ product })

  const {
    broadcastResults,
    error: hubError,
    status: hubStatus
  } = useSignalR<BroadcastContent<LeagueEventsGroup[]>>(
    `today-events-${toPascalCase(product)}`
  )

  useEffect(() => {
    if (broadcastResults) {
      dispatch(
        updateTodayEvents({
          data: broadcastResults?.content,
          lastUpdate: broadcastResults?.timeStamp
        })
      )
    }
  }, [broadcastResults])

  useEffect(() => {
    dispatch(updateRealtimeConnectionStatus({ hubStatus, hubError }))
  }, [hubError, hubStatus])

  return {
    isFetching,
    hubError,
    hubStatus
  }
}

export default useSubscribeToTodayEventsUpdates
