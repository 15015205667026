import { QueryResults, WalletTransaction } from 'src/placebet/core'
import {
  AccountStatusEnum,
  ProfileAccounts,
  WalletAccount,
  WalletProduct,
  WalletRequestLinkAccount
} from '../../models'
import productApi from '../productApi'
import { BeginWithdrawalRequest, DepositRequest } from './models'
import {
  ACCOUNT_DEPOSIT,
  ACCOUNT_TRANSACTIONS_QUERY,
  BEGIN_WITHDRAWAL,
  CANCEL_WITDRAWAL,
  CHANGE_ACCOUNT_STATUS,
  COMPLETE_WITDRAWAL,
  LINK_WALLET_ACCOUNT,
  PROFILE_ACCOUNTS_QUERY,
  PROVIDERS_QUERY,
  USER_ACCOUNTS_QUERY
} from './queries'

export const walletApi = productApi.injectEndpoints({
  endpoints: (builder) => ({
    walletLinkAccount: builder.mutation<
      { accountId: string; assigned: boolean },
      WalletRequestLinkAccount
    >({
      query: (requestInput) => ({
        document: LINK_WALLET_ACCOUNT,
        variables: {
          requestInput
        }
      }),
      transformResponse: (response: {
        createWalletAccount: { accountId: string; assigned: boolean }
      }) => response.createWalletAccount
    }),
    walletAccountDeposit: builder.mutation<WalletTransaction, DepositRequest>({
      query: (requestInput) => ({
        document: ACCOUNT_DEPOSIT,
        variables: {
          requestInput
        }
      }),
      transformResponse: (response: { accountDeposit: WalletTransaction }) =>
        response.accountDeposit
    }),
    walletInitiateWithdrawal: builder.mutation<
      WalletTransaction,
      BeginWithdrawalRequest
    >({
      query: (requestInput) => ({
        document: BEGIN_WITHDRAWAL,
        variables: {
          requestInput
        }
      }),
      transformResponse: (response: {
        beginAccountWithdrawal: WalletTransaction
      }) => response.beginAccountWithdrawal
    }),
    walletCompleteWithdrawal: builder.mutation<
      WalletTransaction,
      { number: string; referenceId: string }
    >({
      query: ({ number, referenceId }) => ({
        document: COMPLETE_WITDRAWAL,
        variables: {
          requestInput: {
            number,
            referenceId
          }
        }
      }),
      transformResponse: (response: {
        completeAccountWithdrawal: WalletTransaction
      }) => response.completeAccountWithdrawal
    }),
    walletCancelWithdrawal: builder.mutation<
      WalletTransaction,
      { number: string; referenceId?: string }
    >({
      query: ({ number, referenceId }) => ({
        document: CANCEL_WITDRAWAL,
        variables: {
          requestInput: {
            number,
            referenceId
          }
        }
      }),
      transformResponse: (response: {
        cancelAccountWithdrawal: WalletTransaction
      }) => response.cancelAccountWithdrawal
    }),
    walletChangeAccountStatus: builder.mutation<
      { accountId: string; status?: AccountStatusEnum },
      { accountId: string; status?: AccountStatusEnum }
    >({
      query: ({ accountId, status }) => ({
        document: CHANGE_ACCOUNT_STATUS,
        variables: {
          requestInput: {
            accountId,
            status
          }
        }
      }),
      transformResponse: (response: {
        changeAccountStatus: { accountId: string; status?: AccountStatusEnum }
      }) => response.changeAccountStatus
    }),
    walletGetCurrentUserAccounts: builder.query<
      QueryResults<WalletAccount>,
      { where?: any }
    >({
      query: ({ where }) => ({
        document: USER_ACCOUNTS_QUERY,
        variables: {
          where
        }
      }),
      transformResponse: (response: {
        userAccounts: QueryResults<WalletAccount>
      }) => response.userAccounts
    }),
    walletGetUserAccounts: builder.query<
      QueryResults<ProfileAccounts>,
      { where: any }
    >({
      query: ({ where }) => ({
        document: PROFILE_ACCOUNTS_QUERY,
        variables: {
          where
        }
      }),
      transformResponse: (response: {
        profiles: QueryResults<ProfileAccounts>
      }) => response.profiles
    }),
    walletGetProviders: builder.query<
      QueryResults<WalletProduct>,
      { where: any }
    >({
      query: ({ where }) => ({
        document: PROVIDERS_QUERY,
        variables: {
          where
        }
      }),
      transformResponse: (response: {
        providers: QueryResults<WalletProduct>
      }) => response.providers
    }),
    walletTransactions: builder.query<
      QueryResults<WalletTransaction>,
      { where: any; order?: any; skip?: number; take?: number }
    >({
      query: ({ where, order, skip, take }) => ({
        document: ACCOUNT_TRANSACTIONS_QUERY,
        variables: {
          where,
          order,
          skip,
          take
        }
      }),
      transformResponse: (response: {
        transactions: QueryResults<WalletTransaction>
      }) => response.transactions
    })
  })
})

export const {
  useWalletAccountDepositMutation,
  useWalletCompleteWithdrawalMutation,
  useLazyWalletGetUserAccountsQuery,
  useWalletGetCurrentUserAccountsQuery,
  useLazyWalletGetCurrentUserAccountsQuery,
  useLazyWalletTransactionsQuery,
  useWalletInitiateWithdrawalMutation,
  useWalletCancelWithdrawalMutation,
  useWalletChangeAccountStatusMutation,
  useLazyWalletGetProvidersQuery,
  useWalletGetProvidersQuery,
  useWalletLinkAccountMutation
} = walletApi
export default walletApi
