import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { status } from 'nprogress'
import {
  ConnectionStatus,
  WalletAccount,
  WalletTransaction
} from 'src/placebet/core'
import { parseErrorResponse } from 'src/placebet/core/api/productApi/services'
import walletApi from 'src/placebet/core/api/walletApi'

type WalletAppState = {
  accounts: WalletAccount[]
  selected?: WalletAccount
  transactions?: WalletTransaction[]
  previewTransaction?: WalletTransaction
  busy?: boolean
  error?: Error
  printContent?: JSX.Element
  showPreview?: JSX.Element
  fetchTimestamp?: number
  realtime: {
    lastUpdated?: Date
    hubError?: Error
    hubStatus?: ConnectionStatus
  }
}

const initialState: WalletAppState = {
  accounts: [],
  realtime: {},
  fetchTimestamp: new Date().getTime()
}

const walletPageSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setPrintContent: (state, { payload }: PayloadAction<JSX.Element>) => {
      state.printContent = payload
    },
    clearPrintContent: (state) => {
      state.printContent = null
    },
    viewTransactionVoucher: (
      state,
      { payload }: PayloadAction<WalletTransaction>
    ) => {
      state.previewTransaction = payload
    },
    dismissPreview: (state) => {
      state.previewTransaction = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        walletApi.endpoints.walletGetCurrentUserAccounts.matchPending,
        (state) => {
          state.busy = true
        }
      )
      .addMatcher(
        walletApi.endpoints.walletGetCurrentUserAccounts.matchFulfilled,
        (state, { payload }) => {
          state.accounts = payload.items
          state.selected = payload.items[0]
          state.busy = false
        }
      )
      .addMatcher(
        walletApi.endpoints.walletGetCurrentUserAccounts.matchRejected,
        (state, { payload }) => {
          state.busy = false
          state.error = parseErrorResponse(payload)
        }
      )
      .addMatcher(
        walletApi.endpoints.walletTransactions.matchPending,
        (state) => {
          state.busy = true
        }
      )
      .addMatcher(
        walletApi.endpoints.walletTransactions.matchFulfilled,
        (state, { payload }) => {
          state.transactions = payload.items || []
          state.busy = false
        }
      )
      .addMatcher(
        walletApi.endpoints.walletTransactions.matchRejected,
        (state, { payload }) => {
          state.busy = false
          state.error = parseErrorResponse(payload)
        }
      )
      .addMatcher(
        walletApi.endpoints.walletInitiateWithdrawal.matchPending,
        (state) => {
          state.busy = true
        }
      )
      .addMatcher(
        walletApi.endpoints.walletInitiateWithdrawal.matchFulfilled,
        (state, { payload }) => {
          state.transactions = [payload, ...state.transactions]
          state.previewTransaction = payload
          state.busy = false
        }
      )
      .addMatcher(
        walletApi.endpoints.walletInitiateWithdrawal.matchRejected,
        (state, { payload }) => {
          state.busy = false
          state.error = parseErrorResponse(payload)
        }
      )
      .addMatcher(
        walletApi.endpoints.walletCancelWithdrawal.matchPending,
        (state) => {
          state.busy = true
        }
      )
      .addMatcher(
        walletApi.endpoints.walletCancelWithdrawal.matchFulfilled,
        (state, { payload }) => {
          const index = state.transactions.findIndex(
            (t) => t.number === payload.number
          )
          const updated = [...state.transactions]
          updated.splice(index, 1)
          state.transactions = [payload, ...updated]
          state.busy = false
        }
      )
      .addMatcher(
        walletApi.endpoints.walletCancelWithdrawal.matchRejected,
        (state, { payload }) => {
          state.busy = false
          state.error = parseErrorResponse(payload)
        }
      )
      .addMatcher(
        walletApi.endpoints.walletLinkAccount.matchPending,
        (state) => {
          state.busy = true
        }
      )
      .addMatcher(
        walletApi.endpoints.walletLinkAccount.matchFulfilled,
        (state, { payload }) => {
          if (payload.assigned) {
            state.fetchTimestamp = new Date().getTime()
          }          
          state.busy = false
        }
      )
      .addMatcher(
        walletApi.endpoints.walletLinkAccount.matchRejected,
        (state, { payload }) => {
          state.busy = false
          state.error = parseErrorResponse(payload)
        }
      )
  }
})

export const { dismissPreview, viewTransactionVoucher } =
  walletPageSlice.actions

export default walletPageSlice
