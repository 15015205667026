import QuickWagerIcon from '@mui/icons-material/Redeem'
import {
  Button as MuiButton,
  ButtonProps,
  styled,
  Tooltip,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useSubmitHorseRacesTicketWagerMutation } from 'src/placebet/products/horseraces/redux/horseRacesProductApi'
import { useAppSelector } from 'src/placebet/core'

const Button = styled(MuiButton)<ButtonProps>(({ theme }) => ({
  backgroundColor: `${theme.colors.primary.dark}`,
  color: `${theme.palette.getContrastText(theme.colors.primary.dark)}`,
  '&:hover': {
    backgroundColor: `${theme.colors.primary.main}`,
    color: `${theme.palette.getContrastText(theme.colors.primary.main)}`
  }
}))

export default function QuickWagerButton() {
  const { t: translation } = useTranslation()

  const {
    horseRaces: {
      ticket,
      pool,
      busy,
      event: {
        eventId,
        details: { raceId }
      },
      wagerPreview: { bet },
      boardWagerRequest: { modifier }
    }
  } = useAppSelector((state) => state)

  const [submitWager] = useSubmitHorseRacesTicketWagerMutation()

  const handleSubmitWager = async () => {
    const request = {
      ticketId: ticket?.id,
      typeBet: pool?.poolId || 'WIN',
      bet,
      race: raceId,
      quick: true,
      eventId,
      modifier,
      legs: []
    }

    await submitWager({ request })
  }

  return (
    <Tooltip title={translation('QUICK_WAGER') || ''}>
      <Button
        variant="outlined"
        size="small"
        aria-label={translation('QUICK_WAGER')}
        onClick={handleSubmitWager}
        disabled={busy}
        startIcon={<QuickWagerIcon fontSize="small" />}
      >
        <Typography variant="body2">{translation('QUICK_WAGER')}</Typography>
      </Button>
    </Tooltip>
  )
}
