import { Box, Chip, Divider, Stack, Typography, styled } from '@mui/material'
import { useMemo } from 'react'
import {
  EventResults,
  RaceResultDetails
} from 'src/placebet/products/horseraces/models'
import { RunnerPosition } from '../../../components'
import { useTranslation } from 'react-i18next'

const BoxWrapper = styled(Box)(
  ({ theme }) => `
      .MuiPaper-root {
        padding: unset;
        margin-top: 1.5px;
      }
      .MuiChip-root {
        min-width: 450px
      }
  `
)

const Content = ({ results }: { results: EventResults }) => {
  const { t }: { t: any } = useTranslation()

  const finishers = useMemo(() => {
    if (!results) {
      return []
    }

    const result = results.raceResults.positions
      ?.map((p) => {
        const { positionId } = p
        return p.finishers.map((f) => {
          return { ...f, positionId }
        })
      })
      .reduce((result, current) => result?.concat(current))

    return result || []
  }, [])

  return (
    <BoxWrapper sx={{ p: 2 }}>
      <Stack spacing={1} sx={{ flexDirection: 'column', maxWidth: '200px' }}>
        {results
          ? finishers.length > 0
            ? finishers.map((f) => {
                const entryId =
                  f.entryId && f.entryId.length > 0
                    ? `${f.runnerId}${f.entryId}`
                    : `${f.runnerId}`

                return (
                  <Chip
                    key={`${f.name}`}
                    label={
                      <Stack direction={'row'} spacing={1}>
                        <Typography display={'inline-block'}>
                          {`${f.positionId}`}
                        </Typography>
                        <Divider variant={'inset'} orientation="vertical" />
                        <RunnerPosition
                          variant="small"
                          runnerId={f.runnerId}
                          entryId={entryId}
                          scratched={false}
                        />
                        <Typography display={'flex'}>{`${f.name}`}</Typography>
                      </Stack>
                    }
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      minWidth: '10rem'
                    }}
                  />
                )
              })
            : t('NO_WINNERS')
          : t('NO_RESULTS')}
      </Stack>
    </BoxWrapper>
  )
}

export default Content
