import {
  Box,
  Divider,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import { t } from 'i18next'
import { formatShortTime } from 'src/placebet/core'

const BoxItemWrapper = styled(Box)(
  ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      background: ${theme.colors.alpha.black[5]};
      position: relative;
      padding: ${theme.spacing(1)};
      width: 100%;
      
      &::before {
        content: '.';
        background: ${theme.colors.error.main};
        color: ${theme.colors.error.main};
        border-radius: ${theme.general.borderRadius};
        position: absolute;
        text-align: center;
        width: 6px;
        left: 0;
        height: 100%;
        top: 0;
      }
      
      &.wrapper-info {
        &:before {
          background: ${theme.colors.info.main};
          color: ${theme.colors.info.main};
        }
      }
          
      &.wrapper-warning {
        &:before {
          background: ${theme.colors.warning.main};
          color: ${theme.colors.warning.main};
        }
      }
  `
)

const Header = ({
  name,
  live,
  scratched,
  time,
  poolList,
  selected
}: {
  name: string
  time: Date
  live?: string
  poolList?: string
  scratched?: string
  selected: boolean
}) => {
  const theme = useTheme()

  return (
    <BoxItemWrapper
      display={'flex'}
      flexDirection="column"
      className={selected ? 'wrapper-warning' : 'wrapper-info'}
      sx={{ px: 2 }}
    >
      <Typography
        variant="body2"
        sx={{
          display: 'flex',
          alignItems: 'center',
          mr: 2
        }}
      >
        <span>
          <Typography
            component="span"
            variant="h3"
            sx={{
              fontWeight: 'bold'
            }}
          >
            {`${t('CARRERA')} #`}&nbsp;
            <b>{`${name}`}</b>
          </Typography>
        </span>
      </Typography>
      <Stack direction={'row'} spacing={2}>
        <Typography color={theme.colors.primary.dark} variant="h5">
          {formatShortTime(time)}
        </Typography>
        {live && (
          <>
            {' '}
            <Divider orientation="vertical" variant="inset" />
            <Typography color={theme.colors.info.dark} variant="h5">
              {t('CORREDORES')}:&nbsp;{live}
            </Typography>
          </>
        )}
        {scratched && (
          <>
            <Divider orientation="vertical" variant="inset" />
            <Typography color={theme.colors.error.dark} variant="h5">
              {t('SCRATCHED')}:&nbsp;{scratched}
            </Typography>
          </>
        )}
      </Stack>
      {poolList && (
        <Typography color={theme.colors.secondary.dark} variant="overline">
          {poolList}
        </Typography>
      )}
    </BoxItemWrapper>
  )
}

export default Header
