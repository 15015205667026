import { AnyTicket } from 'src/placebet/core/models'
import {
  AllowedWager,
  FinishAt,
  Leg,
  Legs,
  Pool,
  PoolId,
  positionsMap
} from '../../models'
import { cartesian, distinct } from '../../services'

export const getTotal = (cart: AnyTicket[]) => {
  const total = cart.reduce((result: number, current: AnyTicket) => {
    return result + (current.totalAmount || 0)
  }, 0)

  return total
}

export const getUniquePositions = (legs: Legs) => {
  const uniqueRunners = Object.keys(legs)
    .map((k) => {
      return legs[k].runners.map((r) => r)
    })
    .reduce((result: number[], current: number[]) => {
      return result.concat(current)
    })
    .filter(distinct)

  return uniqueRunners
}

export const getAllCombinations = (
  legs: Legs,
  allowDuplicates: boolean = false
): AllowedWager[] => {
  // All runners selected on all legs

  const source = Object.keys(legs).map((k) => legs[k].runners.map((r) => r))
  const combinations = cartesian(...source)
    .map((set) => set as number[])
    .filter((set) =>
      allowDuplicates ? true : set.filter(distinct).length === set.length
    )
    .map((set) => set.map((r) => r.toString()).join('-'))

  const parsed = combinations.map((p) => {
    let result: AllowedWager = {}
    p.split('-').forEach((v, i) => {
      const legPositon = positionsMap[i]
      const runner = Number.parseInt(v)
      result = { ...result, [legPositon]: runner }
    })

    return result
  })

  return parsed
}

export const getValidWagers = (
  legs: Legs | undefined,
  pool: Pool
): AllowedWager[] => {
  if (legs === undefined || Object.keys(legs).length === 0) {
    return []
  }

  // Get the runners selected for the wager
  const runnersInWager = getUniquePositions(legs)
  if (runnersInWager.length < pool.legs) return []

  const singleRace = pool.poolRaces === 1

  if (
    (singleRace && Object.keys(legs).length < pool.legs) ||
    (!singleRace && Object.keys(legs).length < pool.poolRaces)
  )
    return []

  // Get all possible combinations for the runners on this pool
  const combinations = getAllCombinations(legs, !singleRace)

  return combinations
}

export const getPoolLegs = (pool?: Pool | PoolId): FinishAt[] => {
  if (!pool) {
    return []
  }

  if ((pool as PoolId) === 'WIN/PLC/SHW') return []

  let results: FinishAt[] = []
  for (var i = 0; i < (pool as Pool).legs; i++) {
    results.push(positionsMap[i])
  }

  return results
}

export const getBoxWagers = (
  legs: Legs | undefined,
  pool: Pool
): AllowedWager[] => {
  if (legs === undefined || Object.keys(legs).length === 0) {
    return []
  }

  // Get the runners selected for the wager
  const runnersInWager = getUniquePositions(legs)

  // Verify we have the minimun requirement of positions
  if (runnersInWager.length < pool.poolRaces) return []

  const positions = getPoolLegs(pool)
  const current = Object.keys(legs)
    .map((l) => legs[l])
    .reduce((result: Leg, current: Leg) => (result = current))

  let result: Legs = {}
  Object.keys(positions).forEach((k, i) => {
    const place = positionsMap[i]
    result = { ...result, [place]: { ...current } }
  })

  const combinations = getAllCombinations(result)

  return combinations
}

export function ticketUpdate(state, payload: AnyTicket) {
  const { tickets, grouping } = state
  if (payload.wagerCollections.length > 0) {
    state.tickets = { ...tickets, [payload.grouping]: payload }
    state.ticket = payload
  } else {
    // remove the ticket if there are no collections
    const { [grouping]: current, ...others } = tickets
    if (current) {
      state.tickets = { ...others }
      state.ticket = undefined
    }
  }
  state.busy = false
}

export const isValidTicketNumber = (
  ticketNumber: string,
  requiereSecurityCode?: boolean
) => {
  const regex = requiereSecurityCode
    ? new RegExp(/(^(\d+-[\w\W]{8})$)|(([\w\W]{5}-[\w\W]{5}-[\w\W]{5})$)/gm)
    : new RegExp(
        /(^(\d+-[\w\W]{8})$)|(([\w\W]{5}-[\w\W]{5}-[\w\W]{5})$)|(([\w\W]{5}-[\w\W]{5})$)/gm
      )

  var valid = regex.test(ticketNumber)

  return valid
}
