import { ApolloProvider } from '@apollo/client'
import { lazy, Suspense } from 'react'
import { PartialRouteObject } from 'react-router'

import { Navigate } from 'react-router-dom'
import SuspenseLoader from 'src/components/SuspenseLoader'
import CustomerLayout from 'src/placebet/components/CustomerLayout'
import { getAnonymousApolloClient, getApolloClient } from 'src/placebet/core'
import Protected from 'src/placebet/core/auth/Protected'

import Callback from 'src/placebet/core/auth/scenes/Callback'
import Logout from 'src/placebet/core/auth/scenes/Logout'
import LogoutCallback from 'src/placebet/core/auth/scenes/LogoutCallback'
import SilentRenew from 'src/placebet/core/auth/scenes/SilentRenew'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

// Status

const Status401 = Loader(
  lazy(() => import('src/placebet/pages/Status/Status401'))
)
const Status404 = Loader(
  lazy(() => import('src/placebet/pages/Status/Status404'))
)
const Status500 = Loader(
  lazy(() => import('src/placebet/pages/Status/Status500'))
)
const StatusComingSoon = Loader(
  lazy(() => import('src/placebet/pages/Status/ComingSoon'))
)
const StatusMaintenance = Loader(
  lazy(() => import('src/placebet/pages/Status/Maintenance'))
)
const WorkstationRegistration = Loader(
  lazy(() => import('src/placebet/pages/WorkstationRegistration'))
)

const BeginRegistration = Loader(
  lazy(() => import('src/placebet/pages/Registration/BeginRegistration'))
)

const VerifyRegistration = Loader(
  lazy(() => import('src/placebet/pages/Registration/VerifyRegistration'))
)

import BaseLayout from 'src/placebet/layouts/BaseLayout'
import Landing from 'src/placebet/pages/Landing'
import customerRoutes from 'src/placebet/router/customer'
import placebetRoutes from '../placebet/router/placebet'

const router: PartialRouteObject[] = [
  {
    path: '',
    element: <Landing />,
    children: []
  },
  {
    path: 'app',
    element: <BaseLayout />,
    children: placebetRoutes
  },
  {
    path: 'customer',
    element: <Navigate to="/customer/wallet" replace />
  },
  {
    path: 'customer',
    element: <CustomerLayout />,
    children: customerRoutes
  },
  {
    path: 'overview',
    element: <Navigate to="/" replace />
  },
  {
    path: 'signin-oidc',
    element: <Callback />
  },
  {
    path: 'logout',
    element: <Logout />
  },
  {
    path: 'logout/callback',
    element: <LogoutCallback />
  },
  {
    path: 'silentrenew',
    element: <SilentRenew />
  },
  {
    path: 'ws/registration',
    element: (
      <Protected>
        <ApolloProvider client={getApolloClient()}>
          <WorkstationRegistration />
        </ApolloProvider>
      </Protected>
    )
  },
  {
    path: 'registration',
    element: <Navigate to="/registration/begin" replace />
  },
  {
    path: 'registration',
    children: [
      {
        path: 'begin',
        element: (
          <ApolloProvider client={getAnonymousApolloClient()}>
            <BeginRegistration />
          </ApolloProvider>
        )
      },
      {
        path: 'verify',
        element: (
          <ApolloProvider client={getAnonymousApolloClient()}>
            <VerifyRegistration />
          </ApolloProvider>
        )
      }
    ]
  },
  {
    path: 'status',
    children: [
      {
        path: '/',
        element: <Navigate to="404" replace />
      },
      {
        path: '',
        element: <Status404 />
      },
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: '401',
        element: <Status401 />
        },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
]

export default router
