import React, { createContext, Dispatch } from 'react'
import { ActivityHistoryDetailState, initialState } from './reducer'
import { Actions } from './actions'

export const ActivityHistoryDetailContext = createContext<{
  state: ActivityHistoryDetailState
  dispatch: Dispatch<Actions>
}>({
  state: initialState,
  dispatch: () => {}
})
