import { Box, Stack, styled, Toolbar as MuiToolbar } from '@mui/material'
import { useContext } from 'react'
import PoolModifiers from './PoolModifiers'
import QuickWagerButton from './QuickWagerButton'
import PoolTabs from './PoolTabs'
import SubmitWagerButton from './SubmitWagerButton'
import { useAppSelector } from 'src/placebet/core'

const GrowDiv = styled('div')(({ theme }) => ({
  flexGrow: 1
}))

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  '& > *': {
    margin: theme.spacing(1)
  }
  //backgroundColor: 'rgb(247, 249, 252)',
  //color: theme.palette.primary.dark
}))

const RootWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingBottom: 0,
  position: 'relative',
  minHeight: '108px'
}))

const ToolbarWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  paddingTop: theme.spacing(0),
  minHeight: 48
}))

function EventToolbar() {
  const { pool } = useAppSelector((state) => state.horseRaces)

  return (
    <RootWrapper>
      <PoolTabs />
      <ToolbarWrapper>
        <Toolbar variant="dense">
          <PoolModifiers />
          <GrowDiv />
          {!!pool && (
            <Stack spacing={1} direction="row">
              <SubmitWagerButton />
              <QuickWagerButton />
            </Stack>
          )}
        </Toolbar>
      </ToolbarWrapper>
    </RootWrapper>
  )
}

export default EventToolbar
