import { useState, ChangeEvent, useMemo } from 'react'
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Avatar,
  Divider,
  lighten,
  styled,
  List,
  Stack,
  IconButton
} from '@mui/material'
import CheckTwoToneIcon from '@mui/icons-material/FolderOpen'
import DeleteAllIcon from '@mui/icons-material/DeleteForever'

import { CountDownTimer } from 'src/placebet/components'
import { formatAsCurrency } from 'src/placebet/core'
import SubmitButton from './SubmitButton'
import React from 'react'
import { t } from 'i18next'
import { AnyTicket } from 'src/placebet/core/models'

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.success.lighter};
          color: ${theme.colors.success.main};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          margin-left: auto;
          margin-right: auto;
    `
)

const RootWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.25),
  paddingRight: theme.spacing(1),
  height: '100%',
  backgroundColor: lighten(theme.colors.alpha.black[10], 0.5)
}))

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(3)};
                font-size: ${theme.typography.pxToRem(16)};

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.colors.alpha.black[100]};
            }
        }
  `
)

const WarnText = styled(Typography)(({ theme }) => ({
  color: theme.colors.warning.main
}))

const CountText = styled(Typography)(({ theme }) => ({
  color: theme.colors.primary.main
}))

interface TicketSidebarProps {
  event: any
  ticket: AnyTicket
  removeTicket: ({ ticketId }: { ticketId: string }) => void
  submitTicket: ({ ticketId }: { ticketId: string }) => void
  wagerInputComponent: React.ReactNode
  wagerComponent: React.ReactNode
  canSubmit?: boolean
}

const TicketSidebarCore = ({
  event,
  ticket,
  removeTicket,
  submitTicket,
  wagerInputComponent,
  wagerComponent,
  canSubmit
}: TicketSidebarProps) => {
  const [currentTab, setCurrentTab] = useState<string>('tickets')

  const tabs = [
    { value: 'tickets', label: t('TICKETS') }
    // { value: 'archived', label: t('ARCHIVADOS') }
  ]

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  const WagerPreview = wagerInputComponent as any
  const WagerComponent = wagerComponent as any

  const hasPayoff = useMemo(() => {
    return ticket && ticket?.payoff > 0
  }, [ticket])

  const wagerCount = useMemo(() => {
    let count = 0
    ticket?.wagerCollections.forEach((item) => {
      count = count + item.wagers.length
    })
    return count
  }, [ticket?.wagerCollections])

  const pricesCount = useMemo(() => {
    let count = 0
    ticket?.wagerCollections.forEach((item) => {
      const prices = item.wagers.filter((wager) => wager?.price > 100)
      count = prices.length
    })
    return count
  }, [ticket?.wagerCollections])

  return (
    <RootWrapper>
      <TabsContainerWrapper sx={{ p: 1 }}>
        <Tabs
          onChange={handleTabsChange}
          value={currentTab}
          variant="scrollable"
          scrollButtons="auto"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </TabsContainerWrapper>

      <Box>
        {currentTab === 'tickets' && (
          <>
            <Box sx={{ p: 0.75, pt: 2 }}>{event && <WagerPreview />}</Box>
            <Stack spacing={1}>
              {ticket && (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ pl: 2 }}
                  >
                    {wagerCount > 0 && (
                      <CountText variant="h4">
                        {`${wagerCount} ${
                          wagerCount > 1 ? t('JUGADAS') : t('JUGADA')
                        }`}
                        {pricesCount > 0 ? ` (${pricesCount}+)` : ''}
                      </CountText>
                    )}
                    <WarnText variant="h4">
                      <CountDownTimer
                        expiration={ticket?.expiration}
                        onCountDownCompleted={() =>
                          removeTicket({ ticketId: ticket?.id })
                        }
                      />
                    </WarnText>
                    <IconButton
                      onClick={() => removeTicket({ ticketId: ticket?.id })}
                    >
                      <DeleteAllIcon />
                    </IconButton>
                  </Box>
                  {ticket?.wagerCollections && (
                    <>
                      <List disablePadding component="div">
                        {ticket.wagerCollections?.map((wager, i) => (
                          <div key={wager.id}>
                            {i !== 0 && <Divider variant="fullWidth" />}
                            <WagerComponent wager={wager} />
                          </div>
                        ))}
                      </List>
                      <Divider variant="fullWidth" />
                      {ticket?.totalAmount && (
                        <Stack
                          sx={{ pl: 2, pr: 1, py: 1 }}
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                          display="flex"
                        >
                          <Typography variant="h5">{t('TOTAL')}</Typography>
                          <Typography variant="h5">
                            {formatAsCurrency(ticket?.totalAmount || 0, 0)}
                          </Typography>
                        </Stack>
                      )}
                      <Divider variant="fullWidth" />
                      {hasPayoff && (
                        <Stack
                          sx={{ pl: 2, pr: 1, py: 1 }}
                          direction="row"
                          justifyContent="space-between"
                          spacing={1}
                          display="flex"
                        >
                          <Typography variant="h5">{t('PAYOFF')}</Typography>
                          <Typography variant="h5">
                            {formatAsCurrency(ticket.payoff, 0)}
                          </Typography>
                        </Stack>
                      )}
                    </>
                  )}
                </>
              )}
            </Stack>
            <SubmitButton
              disabled={!ticket || !canSubmit}
              handleSubmit={() => submitTicket({ ticketId: ticket?.id })}
            />
          </>
        )}
        {/* {currentTab === 'archived' && (
          <Box pb={3}>
            <Divider
              sx={{
                mb: 3
              }}
            />
            <AvatarSuccess>
              <CheckTwoToneIcon />
            </AvatarSuccess>
            <Typography
              sx={{
                mt: 2,
                textAlign: 'center'
              }}
              variant="subtitle2"
            >
              {t('NO_EXISTEN_TICKETS')}
            </Typography>
            <Divider
              sx={{
                mt: 3
              }}
            />
          </Box>
        )} */}
      </Box>
    </RootWrapper>
  )
}

export default TicketSidebarCore
