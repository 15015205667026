import { useTranslation } from 'react-i18next'
import { Runner } from '../../../horseraces/models'

export const Runners = ({ runners }: { runners: Runner[] }) => {
  const { t }: { t: any } = useTranslation()

  const concatRunners = () => {
    let ids = ''

    runners.forEach((runner: Runner) => {
      if (runner.scratch) {
        ids += `${runner.runnerId},`
      }
    })
    if (ids.length > 0) {
      ids = ids.slice(0, -1)
      return `${t('SRC')} ${ids}`
    }
  }

  return <>{concatRunners()}</>
}
