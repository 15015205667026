import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import ip from 'ip'
import { useEffect, useState } from 'react'
import { getEnvSettings } from '../services'

const fpPromise = FingerprintJS.load({ apiKey: 'UQreLvVEdQFIupF6P2FP' })

export interface BrowserFingerprint {
  fingerprint?: string
  ipAddress?: string
  metadata?: unknown
}

export const useBrowserFingerprint = () => {
  const [fingerprintState, setFingerprintState] = useState<
    {
      ready?: boolean
      error?: Error
    } & BrowserFingerprint
  >({})

  const envSettings = getEnvSettings()

  useEffect(() => {
    const loadFingerprint = async () => {
      setFingerprintState({ ...fingerprintState, ready: false })
      try {
        // get stored fingerprint
        const stored = JSON.parse(
          sessionStorage.getItem('fingerprintData')
        ) as BrowserFingerprint
        if (
          (!!stored && !!stored.metadata) ||
          stored?.fingerprint === '' ||
          stored?.fingerprint === envSettings['REACT_APP_PB_DEV_FINGERPRINT']
        ) {
          setFingerprintState({
            ...fingerprintState,
            ...stored,
            ready: true
          })
        } else {
          // Get the visitor identifier when you need it.
          const fp = await fpPromise
          const result = await fp.get({ extendedResult: true })

          const { visitorId, ip, ...metadata } = result

          let data: BrowserFingerprint = {
            fingerprint: result.visitorId,
            ipAddress: result.ip,
            metadata: { ...metadata }
          }

          // const envSettings = getEnvSettings()

          // if (
          //   result.confidence.score < 90 &&
          //   envSettings['REACT_APP_PB_DEV_FINGERPRINT']
          // ) {
          //   data = {
          //     ...data,
          //     fingerprint: envSettings['REACT_APP_PB_DEV_FINGERPRINT']
          //   }
          // }

          // This is the visitor identifier:
          sessionStorage.setItem('fingerprintData', JSON.stringify(data))
          setFingerprintState({
            ...fingerprintState,
            ...data,
            ready: true
          })
        }
      } catch (error) {
        sessionStorage.removeItem('fingerprintData')
        setFingerprintState({
          error
        })
      }
    }
    loadFingerprint()
  }, [])

  return fingerprintState
}
