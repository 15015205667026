import { Box, styled, Typography } from '@mui/material'

export const Heading = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  color: theme.colors.alpha.trueWhite[50],
  textAlign: 'start'
}))

export const Caption = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(10),
  color: theme.palette.info.dark,
  textAlign: 'start'
}))

export const PulsatingIcon = styled(Box)(
  ({ theme }) => `
          position: relative;
          
          &::before {
              width: 24px;
              height: 24px;
              content: ' ';
              position: absolute;
              border-radius: 100px;
              background: ${theme.colors.warning.lighter};
              animation: ripple 1s infinite;
              transition: ${theme.transitions.create(['all'])};
          }

  `
)

export const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(3)};
                font-size: ${theme.typography.pxToRem(16)};

                .MuiTouchRipple-root {
                    display: none;
                }
                text-transform: initial;
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.colors.alpha.black[100]};
            }
        }

        .MuiTabs-scroller {
          overflow: auto!important;
        }
  `
)

export const ContentWrapper = styled(Box)(({ theme }) => ({
  height: `100%`,
  position: 'relative',
  paddingBottom: theme.header.height
}))
