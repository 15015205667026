import { Stack, styled } from '@mui/material'
import {
  currentUtcDate,
  customFormatDate,
  customFormatLocalDate,
  formatDateTime,
  ProductEnum,
  WorkStationMetadata
} from 'src/placebet/core'

import { DashedDivider } from '../../TicketReceipt'
import { ReceiptTypography } from '../styled'
import { t } from 'i18next'

const Logo = styled('img')(({ theme }) => ({
  display: 'block',
  width: 'auto',
  height: 32,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: 'auto',
  marginRight: 'auto'
}))

export interface ReceiptHeaderProps {
  id?: string
  date?: string | Date
  product: ProductEnum
  workstation: WorkStationMetadata
}

function ReceiptHeader({ id, date, product, workstation }: ReceiptHeaderProps) {
  if (!workstation) {
    return null
  }

  const {
    name: workstationName,
    agency: {
      name: agencyName,
      enterprise: { name: enterpriseName },
      products
    }
  } = workstation

  const productCode = products.find((p) => p.name === product)

  return (
    <Stack spacing={2} display={'flex'} direction={'column'}>
      <Stack
        direction="column"
        alignItems="center"
        alignContent="center"
        spacing={1}
      >
        <ReceiptTypography
          variant="caption"
          noWrap
          sx={{ textTransform: 'none' }}
        >
          {enterpriseName}
        </ReceiptTypography>
        <DashedDivider />
      </Stack>

      <Stack
        direction="column"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        {productCode && (
          <ReceiptTypography variant="body2">
            {`${productCode.code} ${agencyName}`}
          </ReceiptTypography>
        )}
        <ReceiptTypography variant="body2">{`${workstationName}`}</ReceiptTypography>
        <ReceiptTypography>
          {typeof date === 'string'
            ? date
            : date
            ? customFormatLocalDate(date)
            : currentUtcDate()}
        </ReceiptTypography>
        <ReceiptTypography>{t(product)}</ReceiptTypography>
      </Stack>

      {id && (
        <ReceiptTypography
          display={'flex'}
          justifyContent={'center'}
          justifySelf={'center'}
          variant="h4"
          noWrap
        >
          {`${id || 'Ticket #'}`}
        </ReceiptTypography>
      )}
    </Stack>
  )
}

export default ReceiptHeader
