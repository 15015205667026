import {
  Box,
  Divider,
  useTheme,
  Card,
  Button,
  ListItemButton
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo, useRef } from 'react'
import {
  LeagueEvent,
  normalizeRouteParam,
  useAppSelector
} from 'src/placebet/core'
import _ from 'lodash'
import { Entry, EventDetails, EventResults } from '../../../../models'
import Header from '../components/Header'
import Content from './Content'

import { Link as RouterLink } from 'react-router-dom'
import { CardActions, LabelInfo } from 'src/placebet/products/components/styled'

function EventDetailRow({
  event
}: {
  event: LeagueEvent<EventDetails, EventResults>
}) {
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()

  const {
    event: { id: selected },
    pool
  } = useAppSelector((state) => state.horseRaces)

  const elementRef = useRef(null)

  const entries = useMemo(() => {
    let result: Array<Entry> = []
    if (!event.details.runners) {
      return []
    }
    event.details.runners.forEach((runner) => {
      const { entries, odds, runnerId } = runner
      if (entries) {
        entries.forEach((entry) => {
          const { entryId } = entry
          const displayEntry =
            entryId && entryId.length > 0
              ? `${runner.runnerId}${entryId}`
              : `${runner.runnerId}`
          const updated = { ...entry, entryId: displayEntry }
          result.push({ odds, runnerId, ...updated })
        })
      }
    })
    return _.sortBy(result, ['runnerId'])
  }, [event.details.runners])

  const scratched = useMemo(() => {
    const scratched = entries.filter((e) => e.scratch).map((e) => e.entryId)
    return scratched.join(',')
  }, [entries])

  useEffect(() => {
    if (selected === event.id) {
      if (elementRef.current) {
        elementRef.current.focus()
      } else if (process.env.NODE_ENV !== 'production') {
        console.error(
          'MUI: Unable to set focus to a ListItemButton whose component has not been rendered.'
        )
      }
    }
  }, [selected])

  const {
    eventName: name,
    eventTime: time,
    details: { poolList, live }
  } = event

  const isSelected = selected === event.id

  return (
    <React.Fragment>
      <ListItemButton
        selected={isSelected}
        component="div"
        sx={{
          py: 0.25,
          px: 0.25,
          opacity: isSelected ? 1 : 0.9
        }}
        ref={elementRef}
      >
        <Card square sx={{ width: '100%' }}>
          <Box
            component={RouterLink}
            to={`?race=${normalizeRouteParam(event.eventName)}`}
            sx={{
              position: 'relative',
              textDecoration: 'none'
            }}
          >
            <Header
              name={name}
              time={time}
              poolList={poolList}
              live={live}
              scratched={scratched}
              selected={isSelected}
            />
            <CardActions>
              <Button
                sx={{ width: '100%' }}
                component={RouterLink}
                to={`${normalizeRouteParam(event.eventName)}?pool=${
                  pool?.poolId
                }`}
              >
                <LabelInfo
                  sx={{
                    borderRadius: 50
                  }}
                >
                  {t('DETALLES')}
                </LabelInfo>
              </Button>
            </CardActions>
          </Box>
          <Box p={2}>
            <Content entries={entries} />
          </Box>
        </Card>
      </ListItemButton>
      <Divider />
    </React.Fragment>
  )
}

export default EventDetailRow
