import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ConnectionStatus,
  LeagueEvent,
  normalizeRouteParam,
  ProductEnum
} from 'src/placebet/core'
import {
  isBusyAction,
  isCompletedAction,
  isRejectedAction
} from 'src/placebet/core/api/productApi/productSlice'
import { parseErrorResponse } from 'src/placebet/core/api/productApi/services'
import { AnyTicket, DictionaryOf } from 'src/placebet/core/models'
import {
  getRunnersLookup,
  handleKeyPressed
} from './wagerPreviewSlice/services'
import {
  AllowedWager,
  EventDetails,
  EventResults,
  FinishAt,
  InputState,
  Legs,
  Pool,
  PoolId,
  RunnersLookup,
  WagerModifier
} from '../models'
import horseRacesApi from './horseRacesProductApi'
import {
  getBoxWagers,
  getValidWagers,
  ticketUpdate
} from './wagerRequestSlice/services'

export interface WagerPreviewState {
  disabled: boolean
  runners?: RunnersLookup
  modifier: WagerModifier
  legs?: Legs
  displayValue?: string
  wagerRequest?: any
  wagerCombinations?: string[]
  inputState?: InputState
  bet?: number
  wagerDefaultBet?: number
  key?: { value: string; nonce: number }
  error?: Error
}

export interface WagerRequestState {
  modifier?: WagerModifier
  selected?: string[]
  exclusions?: string[]
  legs?: Legs
  canSubmitWager?: boolean
  allowedWagers?: AllowedWager[]
  total?: number
  error?: Error
  salesTotal?: number
}

interface LeagueEventsState<TEventDetail, TEventResults> {
  product: ProductEnum
  events: LeagueEvent<TEventDetail, TEventResults>[]
  event?: LeagueEvent<TEventDetail, TEventResults>
  grouping?: string
  error?: Error
  tickets: DictionaryOf<AnyTicket>
  ticketTypebetSales: DictionaryOf<AnyTicket>[]
  ticket?: AnyTicket
  realtime: {
    lastUpdated?: Date
    hubError?: Error
    hubStatus?: ConnectionStatus
  }
  busy?: boolean
  printQueue?: AnyTicket[]
  pool?: Pool
  poolIdParam?: PoolId
  raceParam?: string
  wagerPreview: WagerPreviewState
  boardWagerRequest: WagerRequestState
}

export interface HorseRacesLeagueEventsState
  extends LeagueEventsState<EventDetails, EventResults> {}

const initialState: HorseRacesLeagueEventsState = {
  product: 'HORSE_RACES',
  events: [],
  tickets: {},
  ticketTypebetSales: [],
  realtime: {},
  wagerPreview: {
    disabled: false,
    inputState: 'Legs',
    wagerRequest: undefined,
    modifier: 'NONE',
    legs: {}
  },
  boardWagerRequest: {
    modifier: 'NONE',
    legs: {}
  }
}

interface EventMap {
  [key: string]: LeagueEvent<EventDetails, EventResults>
}

function handleWagerPreviewEventChange(
  state: HorseRacesLeagueEventsState
): WagerPreviewState {
  const {
    event: {
      details: { runners, raceId }
    },
    events,
    pool,
    wagerPreview
  } = state

  return {
    ...wagerPreview,
    modifier: 'NONE',
    wagerRequest: undefined,
    inputState: 'Legs',
    bet: pool?.wagerMinimum,
    wagerDefaultBet: pool?.wagerMinimum,
    runners: getRunnersLookup(raceId, runners, events),
    wagerCombinations: [],
    legs: {}
  }
}

function handleWagerRequestEventChange(
  state: HorseRacesLeagueEventsState
): WagerRequestState {
  const { boardWagerRequest } = state
  return {
    ...boardWagerRequest,
    modifier: 'NONE',
    legs: {}
  }
}

function parseCurrentEvent(
  payload: LeagueEvent<EventDetails, EventResults>[],
  state: HorseRacesLeagueEventsState
) {
  if (payload.length > 0) {
    state.event =
      payload.find((e) => e.eventName === state.raceParam) ||
      payload.filter((e) => e.eventStatus === 'Open')[0] ||
      payload[0]

    state.grouping = `${normalizeRouteParam(
      state.event?.league.name || 'UNKNONW_LEAGUE'
    )}-${state.event.eventName || '01'}`
  }
  if (!state.pool) {
    state.pool = state.event?.details.pools.find(
      (p) => p.poolId === (state.poolIdParam || 'WIN')
    )
  }

  if (state.event) {
    state.wagerPreview = handleWagerPreviewEventChange(state)
    state.boardWagerRequest = handleWagerRequestEventChange(state)
  }
}

const horseRacesProductSlice = createSlice({
  name: 'horseRaces',
  initialState,
  reducers: {
    setLeagueEvents: (
      state,
      { payload }: PayloadAction<LeagueEvent<EventDetails, EventResults>[]>
    ) => {
      state.events = payload
      if (payload.length > 0) {
        if (state.raceParam) {
          state.event =
            state.events.find((e) => e.eventName === state.raceParam) ||
            state.events[0]

          state.pool = state.event.details.pools.find((p) => p.poolId === 'WIN')
        } else {
          state.event = state.events[0]
          state.pool = state.events[0].details.pools.find(
            (p) => p.poolId === 'WIN'
          )
        }
      }
    },
    updateLeagueEvents: (
      state,
      action: PayloadAction<{
        data: LeagueEvent<EventDetails, EventResults>[]
        lastUpdate: Date
      }>
    ) => {
      const { events } = state
      let eventsMap = events.reduce((map: EventMap, item) => {
        map[item.id] = item
        return map
      }, {})

      action.payload.data.forEach((updatedEvent) => {
        const {
          id,
          takeDown,
          details,
          results: raceResults,
          eventStatus
        } = updatedEvent
        let current = eventsMap[id]
        eventsMap[id] = {
          ...current,
          takeDown,
          eventStatus,
          details,
          updated: action.payload.lastUpdate,
          results: raceResults
        }
      })

      const merged = Object.values(eventsMap) || []
      state.events = [...merged]
      state.realtime.lastUpdated = action.payload.lastUpdate

      if (state.events.length > 0) {
        if (state.raceParam) {
          const currentEvent = state.events.find(
            (e) => e.eventName === state.raceParam
          )
          if (currentEvent) state.event = currentEvent
          else {
            state.event = state.events[0]
            state.pool = state.event.details.pools.find(
              (p) => p.poolId === 'WIN'
            )
          }
        } else {
          state.event = state.events[0]
          state.pool = state.events[0].details.pools.find(
            (p) => p.poolId === 'WIN'
          )
        }
      }
    },
    updateRealtimeConnectionStatus: (
      state,
      action: PayloadAction<{ hubStatus: ConnectionStatus; hubError?: Error }>
    ) => {
      if (action.payload.hubError) {
        state.realtime.hubError = action.payload.hubError
      }
      state.realtime.hubStatus = action.payload.hubStatus
    },
    setPool: (state, { payload }: PayloadAction<PoolId>) => {
      state.poolIdParam = payload
      if (state.event) {
        state.pool = state.event?.details.pools.find(
          (p) => p.poolId === (state.poolIdParam || 'WIN')
        )

        if (state.event) {
          state.grouping = `${normalizeRouteParam(
            state.event?.league.name || 'UNKNONW_LEAGUE'
          )}-${state.event.eventName || '01'}`

          state.ticket = state.tickets[state.grouping]
          state.wagerPreview = handleWagerPreviewEventChange(state)
          state.boardWagerRequest = handleWagerRequestEventChange(state)
        }
      }
    },
    setWagerModifier: (state, { payload }: PayloadAction<WagerModifier>) => {
      state.boardWagerRequest.modifier = payload
      state.wagerPreview.modifier = payload
    },
    clearPrintQueue: (state) => {
      state.printQueue = []
    },
    selectRace: (state, { payload }: PayloadAction<string>) => {
      state.raceParam = payload
      state.wagerPreview = { modifier: 'NONE', disabled: false }
      if (state.events.length > 0) {
        state.event =
          state.events.find((e) => e.eventName === state.raceParam) ||
          state.events.filter((e) => e.eventStatus === 'Open')[0] ||
          state.events[0]

        if (state.event) {
          state.grouping = `${normalizeRouteParam(
            state.event?.league.name || 'UNKNONW_LEAGUE'
          )}-${state.event.eventName || '01'}`

          state.ticket = state.tickets[state.grouping]
          state.wagerPreview = handleWagerPreviewEventChange(state)
        }
      }
    },
    setKeyPressed: (state, { payload: keyPressed }: PayloadAction<string>) => {
      const {
        wagerPreview: { disabled }
      } = state

      if (disabled) {
        return { ...state }
      }
      return {
        ...handleKeyPressed(state, keyPressed)
      }
    },
    setInputState: (state, { payload }: PayloadAction<InputState>) => {
      const {
        wagerPreview: { inputState }
      } = state

      switch (inputState) {
        case 'Amount':
          return {
            ...state
          }

        case 'Legs': {
          return {
            ...state,
            wagerPreview: {
              ...state.wagerPreview,
              wagerDefaultBet: undefined,
              bet: undefined,
              inputState: payload
            }
          }
        }
      }
    },
    setDisabled: (state, { payload }: PayloadAction<boolean>) => {
      state.wagerPreview.disabled = payload
    },
    changeModifier: (state, { payload }: PayloadAction<WagerModifier>) => {
      state.wagerPreview.modifier = payload
    },
    addWagerLegRunner: (
      state,
      {
        payload
      }: PayloadAction<{
        key: string
        pool: Pool
        race: number
        leg: FinishAt
        runner: number
      }>
    ) => {
      const { legs = {}, selected = [], modifier } = state.boardWagerRequest

      // selected entry identifiers
      if (!!selected[payload.key]) {
        return
      }

      // updated selections
      const updatedSelected = [...selected, payload.key]

      // Get Legs in Race
      const leg = legs[payload.leg] || {
        raceId: payload.race,
        runners: []
      }

      const updatedLegs: Legs = {
        ...legs,
        [payload.leg]: {
          raceId: payload.race,
          runners: [...leg.runners, payload.runner]
        }
      }

      const validWagers =
        !!modifier && modifier === 'BOX'
          ? getBoxWagers(updatedLegs, payload.pool)
          : getValidWagers(updatedLegs, payload.pool)

      // Return
      return {
        ...state,
        boardWagerRequest: {
          ...state.boardWagerRequest,
          selected: updatedSelected,
          legs: updatedLegs,
          canSubmitWager: validWagers.length > 0,
          allowedWagers: validWagers
        }
      }
    },
    removeWagerLegRunner: (
      state,
      {
        payload
      }: PayloadAction<{
        key: string
        pool: Pool
        race: number
        leg: FinishAt
        runner: number
      }>
    ) => {
      const { legs = {}, selected = [], modifier } = state.boardWagerRequest

      // selected entry identifiers
      if (!!selected[payload.key]) {
        return { ...state }
      }

      // updated selections
      const updatedSelected = [...selected]
      const keyIndex = selected.indexOf(payload.key)
      if (keyIndex !== -1) {
        updatedSelected.splice(keyIndex, 1)
      }

      let leg = legs[payload.leg]
      if (!leg) {
        return { ...state }
      }

      const runners = [...leg.runners]
      const index = runners.indexOf(payload.runner)
      if (index === -1) {
        return { ...state }
      }

      runners.splice(index, 1)

      let updatedLegs = {}
      if (runners.length === 0) {
        updatedLegs = { ...legs }
        delete updatedLegs[payload.leg]
      } else {
        updatedLegs = {
          ...legs,
          [payload.leg]: {
            raceId: payload.race,
            runners: [...runners]
          }
        }
      }

      const validWagers =
        Object.keys(updatedLegs).length === 0
          ? []
          : !!modifier && modifier === 'BOX'
          ? getBoxWagers(updatedLegs, payload.pool)
          : getValidWagers(updatedLegs, payload.pool)

      return {
        ...state,
        boardWagerRequest: {
          ...state.boardWagerRequest,
          selected: updatedSelected,
          legs: updatedLegs,
          canSubmitWager: validWagers.length > 0,
          allowedWagers: validWagers
        }
      }
    },
    clearSalesTotal: (state) => {
      state.boardWagerRequest.salesTotal = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isBusyAction, (state) => {
        state.busy = true
      })
      .addMatcher(isCompletedAction, (state) => {
        state.busy = false
      })
      .addMatcher(isRejectedAction, (state, { error }) => {
        state.error = parseErrorResponse(error)
        state.busy = false
      })
      .addMatcher(
        horseRacesApi.endpoints.getHorseRacesLeagueEvents.matchFulfilled,
        (state, { payload: { events, tickets } }) => {
          state.events = events
          parseCurrentEvent(events, state)
          state.tickets = tickets
          state.ticket = tickets[state.grouping]
          state.busy = false
          state.raceParam = undefined
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.getHorseRacesPoolLeagueEvents.matchFulfilled,
        (state, { payload: { events, tickets } }) => {
          state.events = events
          parseCurrentEvent(events, state)
          state.tickets = tickets
          state.ticket = tickets[state.grouping]
          state.busy = false
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.submitHorseRacesTicketWager.matchFulfilled,
        (state, { payload }) => {
          if (payload.grouping === state.grouping) {
            state.tickets = { ...state.tickets, [state.grouping]: payload }
            state.ticket = payload

            // reset wagerRequest
            const boardWagerRequest = {
              ...state.boardWagerRequest,
              legs: {},
              allowedWagers: [],
              canSubmitWager: false,
              selected: []
            }

            state.boardWagerRequest = boardWagerRequest
          }

          state.busy = false
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.removeHorseRacesTicket.matchFulfilled,
        (state, { payload }) => {
          if (payload.success) {
            const { tickets, grouping } = state
            const { [grouping]: current, ...others } = tickets
            if (current) {
              state.tickets = { ...others }
              state.ticket = undefined
            }
          }
          state.busy = false
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.removeHorseRacesWager.matchFulfilled,
        (state, { payload }) => {
          ticketUpdate(state, payload)
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.removeHorseRacesWagerCollection.matchFulfilled,
        (state, { payload }) => {
          ticketUpdate(state, payload)
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.submitHorseRacesTicket.matchFulfilled,
        (state, { payload }) => {
          const { [state.ticket?.grouping]: current, ...others } = state.tickets
          state.tickets = { ...others }
          state.ticket = undefined
          state.printQueue = [payload]
          state.busy = false

          const boardWagerRequest = {
            ...state.boardWagerRequest,
            salesTotal:
              (payload?.totalAmount || 0) +
              (state.boardWagerRequest.salesTotal || 0)
          }

          state.boardWagerRequest = boardWagerRequest
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.getHorseRacesTickets.matchFulfilled,
        (state, { payload }) => {
          state.tickets = payload
          state.ticket = payload[state.grouping]
          state.busy = false
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.getHorseRacesTicketTypebetSales.matchFulfilled,
        (state, { payload }) => {
          state.ticketTypebetSales = payload
          state.busy = false
        }
      )
  }
})

export const {
  setLeagueEvents,
  updateLeagueEvents,
  setPool,
  setWagerModifier,
  selectRace,
  updateRealtimeConnectionStatus,
  clearPrintQueue,
  setKeyPressed,
  setInputState,
  setDisabled,
  addWagerLegRunner,
  removeWagerLegRunner,
  clearSalesTotal
} = horseRacesProductSlice.actions
export default horseRacesProductSlice
