import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getAccessToken } from '../auth/authService'
import { BrowserFingerprint } from '../hooks'
import { getEnvSettings } from './appConfiguration'
import { GraphQLClient } from 'graphql-request'

export const getApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const envSettings = getEnvSettings()

  const httpLink = createHttpLink({
    uri: envSettings.REACT_APP_PB_GRAPH_BASE_URL
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getAccessToken()

    const fingerprintData = JSON.parse(
      sessionStorage.getItem('fingerprintData') || '{}'
    ) as BrowserFingerprint
    // return the headers to the context so httpLink can read them

    if (
      fingerprintData &&
      fingerprintData.fingerprint &&
      fingerprintData.ipAddress &&
      fingerprintData.metadata
    ) {
      const { fingerprint, ipAddress, metadata } = fingerprintData
      const meta = Buffer.from(JSON.stringify(metadata)).toString('base64')

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
          'x-placebet-fingerprint': fingerprint,
          'x-placebet-ip-address': ipAddress,
          'x-placebet-device-metadata': meta
        }
      }
    } else {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      }
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })

  return client
}

export const getAnonymousApolloClient =
  (): ApolloClient<NormalizedCacheObject> => {
    const envSettings = getEnvSettings()

    const httpLink = createHttpLink({
      uri: envSettings.REACT_APP_PB_GRAPH_BASE_URL
    })

    const authLink = setContext((_, { headers }) => {
      const fingerprintData = JSON.parse(
        sessionStorage.getItem('fingerprintData') || '{}'
      ) as BrowserFingerprint

      if (
        fingerprintData &&
        fingerprintData.fingerprint &&
        fingerprintData.ipAddress &&
        fingerprintData.metadata
      ) {
        const { fingerprint, ipAddress, metadata } = fingerprintData
        const meta = Buffer.from(JSON.stringify(metadata)).toString('base64')

        return {
          headers: {
            ...headers,
            'x-placebet-fingerprint': fingerprint,
            'x-placebet-ip-address': ipAddress,
            'x-placebet-device-metadata': meta
          }
        }
      } else {
        return {
          headers: {
            ...headers
          }
        }
      }
    })

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    })

    return client
  }

const buildHeaders: any = () => {
  // get the authentication token from local storage if it exists
  const token = getAccessToken()

  const fingerprintData = JSON.parse(
    sessionStorage.getItem('fingerprintData') || '{}'
  ) as BrowserFingerprint
  // return the headers to the context so httpLink can read them

  const headers = {
    Accept: 'application/json'
  }

  if (
    fingerprintData &&
    fingerprintData.fingerprint &&
    fingerprintData.ipAddress &&
    fingerprintData.metadata
  ) {
    const { fingerprint, ipAddress, metadata } = fingerprintData
    const meta = Buffer.from(JSON.stringify(metadata)).toString('base64')

    return {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'x-placebet-fingerprint': fingerprint,
      'x-placebet-ip-address': ipAddress,
      'x-placebet-device-metadata': meta
    }
  } else {
    return {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
}

export const getRtkApolloClient = () => {
  const envSettings = getEnvSettings()

  const headers = buildHeaders()

  const client = new GraphQLClient(envSettings.REACT_APP_PB_GRAPH_BASE_URL, {
    //credentials: 'same-origin',
    headers
  })

  return client
}
