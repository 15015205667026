import { gql, useLazyQuery } from '@apollo/client'
import { SearchGraphql } from 'src/placebet/core'
import { ActivityHistoryDetail } from '../models'

const GET_ACTIVITIES_HISTORY_DETAILS = gql`
query GetActivityHistoryDetail($where: ActivityHistoryDetailFilterInput, $skip: Int, $take: Int) {
  activityHistoryDetail(where: $where, skip: $skip, take: $take, order: {
    time: DESC
  }) {
    items {
      productId
      date
      time
      activityType
      amount
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    totalCount
  }
}
`

interface ActivityHistoryDetailData {
  activityHistoryDetail: {
    items: ActivityHistoryDetail[]
    totalCount: number
  }
}

export const useActivitiesHistoryDetail = () => {
  const [getResults, { loading, error, data }] = useLazyQuery<ActivityHistoryDetailData>(GET_ACTIVITIES_HISTORY_DETAILS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const getActivitiesHistoryDetail = ({ where, take, skip }: SearchGraphql) => {
    getResults({
      variables: {
        where: where,
        skip: skip ? skip : 0,
        take: take ? take : 100,
      },
    })
  }

  const results = {
    results: data?.activityHistoryDetail.items || [],
    metadata: {
      count: data?.activityHistoryDetail.totalCount,
    },
  }
  
  return { getActivitiesHistoryDetail, loading, error, results }
}