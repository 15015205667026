import {
  IconButton,
  Typography,
  alpha,
  Tooltip,
  styled,
  useTheme,
  Box
} from '@mui/material'

import CartIcon from '@mui/icons-material/ShoppingCart'
import { useContext } from 'react'

import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone'
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone'
import { toggleSidebar } from 'src/placebet/core/appContext/appContextSlice'
import { useAppDispatch, useAppSelector } from 'src/placebet/core'

const ImageWrapper = styled(CartIcon)(
  () => `
        width: 30px;
`
)

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
)

function BurgerButton() {
  const theme = useTheme()

  const { sidebarOpen } = useAppSelector((state) => state.appContext)

  const dispatch = useAppDispatch()

  return (
    <Box
      component="span"
      sx={{
        ml: 2,
        display: { lg: 'none', xs: 'inline-block' }
      }}
    >
      <Tooltip arrow title="Toggle Menu">
        <IconButton color="primary" onClick={() => dispatch(toggleSidebar())}>
          {!sidebarOpen ? (
            <MenuTwoToneIcon fontSize="small" />
          ) : (
            <CloseTwoToneIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default BurgerButton
