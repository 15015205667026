import { KeyboardMapping } from '../models'

export const CockFightsSpecialKeyboard: KeyboardMapping = {
  keys: {
    'ScrollLock+Y': 'A',
    'ScrollLock+U': 'B',
    'ScrollLock+I': 'C',
    'ScrollLock+O': 'D',
    'ScrollLock+P': 'E',
    'ScrollLock+Q': 'F',
    'ScrollLock+W': 'G',
    'ScrollLock+E': 'H',
    'ScrollLock+R': 'I',
    'ScrollLock+T': 'J',
    'ScrollLock+y': 'K',
    'ScrollLock+u': 'L',
    'ScrollLock+i': 'M',
    'ScrollLock+o': 'N',
    'ScrollLock+p': 'P',
    'ScrollLock+q': '!',
    'ScrollLock+w': '@',
    'ScrollLock+e': '#',
    'ScrollLock+r': '$',
    'ScrollLock+t': '%',
    a: 'QU',
    A: 'DQNL',
    s: 'OMN',
    N: 'EXL',
    n: 'EXA',
    Z: 'WET',
    z: 'WIN',
    h: 'TT',
    j: 'PK3',
    m: 'TRI',
    X: 'WP',
    x: 'PLC',
    k: 'PK4',
    ',': 'SPR',
    C: 'WS',
    c: 'SHW',
    _: 'BOX',
    d: 'NUM',
    l: 'PK5',
    '>': 'PEN',
    V: 'PS',
    v: 'WPS',
    ';': 'PK6',
    '?': 'HEX',
    B: 'XDBL',
    b: 'DD',
    '`': 'All',
    PageUp: 'Tickets',
    PageDown: 'CE/A',
    End: 'CE/T',
    '[': 'Quick',
    ']': 'CCST',
    f: 'With',
    Y: '26',
    Q: '21',
    y: '16',
    q: '11',
    U: '27',
    W: '22',
    u: '17',
    w: '12',
    I: '28',
    E: '23',
    i: '18',
    e: '13',
    O: '29',
    R: '24',
    o: '19',
    r: '14',
    P: '30',
    T: '25',
    p: '20',
    t: '15',
    ')': '10',
    Home: 'CE/$',
    Meta: 'CE/$',
    F2: 'NextEvents',
    F3: 'Tickets',
    F4: 'SalesSummary',
    F5: 'Draw',
    F6: 'PayDraw',
    F8: 'Pay',
    F9: 'Void',
    F10: 'Deposit',
    F11: 'Withdraw',
    F12: 'Fn',
    '=': 'RPT',
    'Ctrl+Enter': 'Print',
    'Alt+Meta': 'Print',
    'Ctrl+Backspace': 'CE/T',
    'Shift+Backspace': 'CE/A',
    Escape: 'Cancel',
    '.': 'CE/$',
    'Insert': 'Print'
  },
  controlKeys: {
    '/': 'EVENTO',
    '*': 'PELEA',
    Pay: 'Pay',
    Void: 'Void',
    Draw: 'Draw',
    PayDraw: 'PayDraw',
    Withdraw: 'Withdraw'
  },
  actionKeys: {
    Deposit: 'Deposit'
  },
  navigation: {
    NextEvents: 'NextEvents',
    Tickets: 'Tickets',
    SalesSummary: 'SalesSummary'
  },
  lockKey: 'ScrollLock'
}
