import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

export const ConfirmationMessage = (props) => {
  const { title, message, onConfirm, onCancel } = props

  const { t: translation } = useTranslation()
  return (
    <SweetAlert
      warning
      showCancel
      showCloseButton
      customButtons={
        <>
          <Button
            onClick={onCancel}
            size={'small'}
            variant="outlined"
            color="error"
          >
            {translation('NO')}
          </Button>

          <Button
            sx={{ ml: 2 }}
            onClick={onConfirm}
            size={'small'}
            variant="outlined"
            color="primary"
          >
            {translation('SI')}
          </Button>
        </>
      }
      //confirmBtnCssClass={classes.button + ' ' + classes.danger}
      //cancelBtnCssClass={classes.button + ' ' + classes.info}
      //customIcon="https://raw.githubusercontent.com/djorg83/react-bootstrap-sweetalert/master/demo/assets/thumbs-up.jpg"
      title={translation(title)}
    >
      {message}
    </SweetAlert>
  )
}
