import { useMemo, useEffect } from 'react'
import { Box, Tabs, Tab, styled } from '@mui/material'
import { mergedWinPlaceShow, PoolId } from '../../../../../../models'
import _ from 'lodash'

import { useAppDispatch, useAppSelector } from 'src/placebet/core'
import { useSearchParams } from 'react-router-dom'
import {
  setPool,
  setWagerModifier
} from 'src/placebet/products/horseraces/redux/horseRacesProductSlice'

const RootWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingTop: 0,
  paddingBottom: 0,
  height: 40
}))

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(3)};
                font-size: ${theme.typography.pxToRem(16)};

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.colors.alpha.black[100]};
            }
        }

        .MuiTabs-scroller {
          overflow: auto!important;
        }
  `
)

function PoolTabs() {
  const {
    pool,
    event: {
      details: { pools }
    }
  } = useAppSelector((state) => state.horseRaces)

  const dispatch = useAppDispatch()

  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    window.addEventListener('mappedKeyPressed', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('mappedKeyPressed', downHandler)
    }
  }, [])

  useEffect(() => {
    if (pool?.poolId) {
      const params = Object.fromEntries([...searchParams])
      setSearchParams({ ...params, pool: pool.poolId as PoolId })
    }
  }, [pool?.poolId])

  const downHandler = (event: any) => {
    const { key, mapped, isToggle } = event.detail as {
      key: string
      mapped: boolean
      isToggle?: boolean
    }

    if (supportedPools.indexOf(key as PoolId) === -1) return

    dispatch(setPool(key as PoolId))
  }

  const supportedPools = useMemo(() => {
    const poolTypesList = pools.map((p) => p.poolId)
    const result = _.difference(poolTypesList, mergedWinPlaceShow)
    return result
  }, [pools])

  const handleChangePool = (newValue?: PoolId) => {
    dispatch(setPool(newValue))
    dispatch(setWagerModifier('NONE'))
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: PoolId) => {
    handleChangePool(newValue)
  }

  return (
    <RootWrapper>
      <TabsContainerWrapper>
        <Box sx={{ flexGrow: 1, maxWidth: 'minContent' }}>
          <Tabs
            onChange={handleChange}
            value={pool?.poolId}
            variant="scrollable"
            scrollButtons="auto"
            textColor="primary"
            indicatorColor="primary"
            allowScrollButtonsMobile
            // visibleScrollbar
            // sx={{
            //   [`& .${tabsClasses.scrollButtons}`]: {
            //     '&.Mui-disabled': { opacity: 0.3 }
            //   }
            // }}
          >
            {supportedPools.map((item, index) => (
              <Tab
                key={`denseTab${index}`}
                label={item as string}
                value={item}
              />
            ))}
          </Tabs>
        </Box>
      </TabsContainerWrapper>
    </RootWrapper>
  )
}

export default PoolTabs
