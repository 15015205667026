import Typography from '@mui/material/Typography'
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack
} from '@mui/material'
import { formatAsCurrency } from '../../../../../../core'
import { useTheme } from '@emotion/react'

import TicketListItem from '../../../../../components/TicketsSidebarCore/TicketListItem'

import MoreIcon from '@mui/icons-material/UnfoldMore'
import LessIcon from '@mui/icons-material/UnfoldLess'
import { useState } from 'react'
import SplitDisplayValues from '../../../../../components/recepits/TicketReceipt/SplitDisplayValues'
import { HorseRacesWager } from 'src/placebet/products/horseraces/models'

export default function WagerItem({
  wager,
  canExpand
}: {
  wager: HorseRacesWager
  canExpand: boolean
}) {
  const theme = useTheme()
  const [collapsed, setCollapsed] = useState<boolean>(true)

  const toggleCollapsed = () => setCollapsed(!collapsed)

  const WagerDisplay = () => {
    let displayValue: string | React.ReactElement = wager.displayValue
    if (wager.displayValue.includes('/')) {
      displayValue = <SplitDisplayValues pick={wager.displayValue} />
    }

    const legend =
      wager.wagerCombinations.length > 1
        ? `(${wager.wagerCombinations.length})`
        : undefined

    return (
      <TicketListItem
        variant="subtitle2"
        primary={displayValue}
        secondary={formatAsCurrency(
          wager.bet * (wager.combine || 1) * wager.wagerCombinations.length,
          0
        )}
        legend={legend}
      />
    )
  }

  function calculateWagerBetPerCombination(wager: HorseRacesWager) {
    return wager.combine && wager.combine > 0
      ? wager.bet * wager.combine
      : wager.bet
  }

  return !canExpand ? (
    <Box sx={{ pr: 1 }}>
      <WagerDisplay />
    </Box>
  ) : (
    <Button
      sx={{
        p: 0,
        m: 0,
        borderRadius: 0,
        width: '100%',
        '&:hover': {
          background: 'none'
        }
      }}
      onClick={toggleCollapsed}
      variant="text"
    >
      <Stack
        sx={{ p: 0, width: '100%' }}
        display={'flex'}
        direction={'row-reverse'}
        justifyItems={'left'}
        justifyContent={'left'}
      >
        <IconButton
          onClick={toggleCollapsed}
          sx={{
            p: 0.25,
            pr: 0,
            '&:hover': {
              background: 'none'
            }
          }}
        >
          {collapsed && <MoreIcon fontSize="small" />}
          {!collapsed && <LessIcon fontSize="small" />}
        </IconButton>
        <Box
          sx={{ py: 1, width: '100%' }}
          flexDirection={'column'}
          display={'flex'}
        >
          <WagerDisplay />
          <Collapse in={!collapsed} collapsedSize={0}>
            <Divider />
            <List
              disablePadding
              component="nav"
              aria-label="wager combinations"
            >
              {wager.wagerCombinations.map((wc, i) => (
                <ListItem
                  sx={{ pl: 0, p: 0.5 }}
                  key={`wager-combination-${wager.id}-${i}`}
                >
                  <ListItemText
                    primary={
                      <Stack justifyContent="space-between" direction="row">
                        <Typography>{wc}</Typography>
                        <Typography>
                          {formatAsCurrency(
                            calculateWagerBetPerCombination(wager)
                          )}
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      </Stack>
    </Button>
  )
}
