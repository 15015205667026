import React, { useMemo, useReducer } from 'react'
import {
  KeyboardMapperContext,
  reducer
} from 'src/placebet/products/providers/CustomKeyboardProvider/state'
import { KeyboardMapping } from '../models'
import CustomKeyboardListener from './CustomKeyboardListener'

function CustomKeyboard({
  customKeyboard,
  children
}: {
  customKeyboard: KeyboardMapping
  children: React.ReactNode
}) {
  const [state, dispatch] = useReducer(reducer, {
    customKeyboard,
    mode: 'Standard',
    mapped: true
  })

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return (
    <KeyboardMapperContext.Provider value={contextValue}>
      <CustomKeyboardListener>{children}</CustomKeyboardListener>
    </KeyboardMapperContext.Provider>
  )
}

export default CustomKeyboard
