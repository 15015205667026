import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Box, Drawer, Grid, useTheme, styled } from '@mui/material'
import Scrollbar from 'src/components/Scrollbar'

import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from 'src/placebet/core'
import { toggleRightSidebar } from 'src/placebet/core/appContext/appContextSlice'

const RootWrapper = styled(Box)(({ theme }) => ({
  // height: `calc(100vh)`,
  // paddingTop: `${theme.header.height}px`,
  // backgroundColor: 'red'
}))

const DrawerWrapper = styled(Drawer)(
  ({ theme }) => `
    width: 360px;
    flex-shrink: 0;
    z-index: 3;


    & > .MuiPaper-root {
        width: 360px;
        height: 100%;
        position: absolute;
        top: ${theme.header.height};
        right: 0;
        z-index: 3;
        background: ${theme.colors.alpha.white[100]};
    }
`
)

const DrawerWrapperMobile = styled(Drawer)(
  () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
)

const MainContentWrapper = styled(Box)(
  () => `
  flex-grow: 1;
`
)

const ContentWrapper = styled(Box)(({ theme }) => ({
  height: `calc(100% - ${theme.header.height})`,
  top: `${theme.header.height}`
}))

function Dashboard({
  boardComponent,
  ticketComponent
}: {
  boardComponent: React.ReactNode
  ticketComponent: React.ReactNode
}) {
  const theme = useTheme()
  const { t } = useTranslation()

  const { product } = useAppSelector((state) => state.appContext)

  const { rightSidebarOpen } = useAppSelector((state) => state.appContext)

  const dispatch = useAppDispatch()

  const closeSidebar = () => dispatch(toggleRightSidebar())

  const sidebarContent = <Scrollbar>{ticketComponent}</Scrollbar>

  return (
    <>
      <Helmet>
        <title>{t(product)}</title>
      </Helmet>
      <RootWrapper
        sx={{
          width: { xs: '100%', lg: 'calc(100% - 360px)' }
        }}
      >
        <MainContentWrapper>
          <Grid
            sx={{
              px: 0
            }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={4}
          >
            <Grid item xs={12}>
              <ContentWrapper>{boardComponent}</ContentWrapper>
            </Grid>
          </Grid>
          <Box mt={3} />
        </MainContentWrapper>
        <DrawerWrapperMobile
          sx={{
            display: { lg: 'none', xs: 'inline-block' }
          }}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'left' : 'right'}
          open={rightSidebarOpen}
          onClose={closeSidebar}
        >
          {sidebarContent}
        </DrawerWrapperMobile>
        <DrawerWrapper
          sx={{
            display: { xs: 'none', lg: 'inline-block' }
          }}
          className="MuiDrawer-hd"
          variant="permanent"
          anchor={theme.direction === 'rtl' ? 'left' : 'right'}
          open
        >
          {sidebarContent}
        </DrawerWrapper>
      </RootWrapper>
    </>
  )
}

export default Dashboard
