import { useEffect } from 'react'
import { useParams } from 'react-router'
import { Loader } from 'src/placebet/components'
import LeagueCard from './LeagueCard'

import Dashboard from 'src/placebet/products/components/Dashboard'

import TicketSidebar from '../../tickets/TicketsSidebar'
import { useSearchParams } from 'react-router-dom'
import { selectRace, setPool } from '../../../redux/horseRacesProductSlice'
import { useAppDispatch } from 'src/placebet/core'
import { PoolId } from '../../../models'
import { useSubscribeToLeagueEventUpdates } from '../../../redux/useSubscribeToLeagueEventUpdates'
import { useLazyGetHorseRacesLeagueEventsQuery } from '../../../redux/horseRacesProductApi'

function League() {
  const { league } = useParams() as {
    league: string
  }

  const dispatch = useAppDispatch()

  let [searchParams] = useSearchParams()
  useEffect(() => {
    const queryParams = Object.fromEntries([...searchParams])
    const { race } = queryParams
    if (race) {
      dispatch(selectRace(race))
    }

    const pool = (queryParams.pool ?? 'WIN') as PoolId
    dispatch(setPool(pool))
  }, [searchParams])

  const [
    fetchLeagueEvents,
    { isFetching: loading, isUninitialized: notCalled }
  ] = useLazyGetHorseRacesLeagueEventsQuery()

  useEffect(() => {
    fetchLeagueEvents({ name: league })
  }, [league])

  // subscribe to league events
  useSubscribeToLeagueEventUpdates(league)

  return (
    <Loader loading={loading || (!loading && notCalled)}>
      <Dashboard
        boardComponent={<LeagueCard />}
        ticketComponent={<TicketSidebar />}
      />
    </Loader>
  )
}

export default League
