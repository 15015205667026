import React, { Dispatch } from 'react'
import { KeyboardMapping, KeyMaping } from '../models'
import { Actions } from './actions'

export type KeyboardMode = 'Standard' | 'ModalInput' | 'Input'

export type ToolbarAction =
  | 'NextEvents'
  | 'Tickets'
  | 'SalesSummary'
  | 'Deposit'
  | 'Withdraw'

// Keyboard Context
export interface KeyboardMapperState {
  value?: string
  modalCommand?: string
  mode: KeyboardMode
  bufferedValue?: string
  keyPressed?: { key: string; nonce?: number }
  mapped?: boolean
  customKeyboard: KeyboardMapping
  bypass?: boolean
  toolbarAction?: ToolbarAction
}

export const KeyboardMapperContext = React.createContext<{
  state: KeyboardMapperState
  dispatch: Dispatch<Actions>
}>({
  state: {
    mode: 'Standard',
    mapped: false,
    customKeyboard: { keys: {} }
  },
  dispatch: () => {}
})
