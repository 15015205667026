import { Dictionary } from "../models"

export const forbiddenCustomerModules = ['SalesSummary', 'Draw', 'PayDraw', 'Pay', 'Void', 'Deposit', 'Withdraw']

export const isUserCustomer = (claims: Dictionary): boolean => {
  let isCustomer = false
  Object.keys(claims).forEach((key) => {
    if (key === 'role') {
      const roles: string[] | any = claims['role']
      if (roles && Array.isArray(roles) && roles.length > 0) {
        isCustomer = roles.includes('customer')
      } else {
        // Validate if the role is a string
        isCustomer = roles === 'customer'
      }
    }
  })
  
  return isCustomer
}

