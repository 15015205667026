import { gql } from '@apollo/client'
import {
  ACCOUNT_TRANSACTION_FIELDS,
  ACCOUNT_TRANSACTION_RECEIPT_FIELDS,
  BEGIN_WITHDRAWAL_RECEIPT_FIELDS
} from './fragments'

export const USER_ACCOUNTS_QUERY = gql`
  query GetWalletUserAccounts {
    userAccounts {
      items {
        id
        number
        name
        description
        currency
        status
        providerId
        provider {
          id
          name
          logo
          product
        }
        balance
        active
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const PROVIDERS_QUERY = gql`
  query WalletUserLinkedProviders($where: AccountFilterInput) {
    providers {
      items {
        id
        name
        description
        logo
        signInUrl
        url
        disabled
        accounts(where: $where) {
          id
          name
          description
          number
          balance
          number
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`

export const PROFILE_ACCOUNTS_QUERY = gql`
  query GetWalletAccountsByProfile($where: ProfileFilterInput) {
    profiles(where: $where) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        id
        email
        phoneNumber
        name
        accounts {
          id
          number
          name
          balance
          currency
          providerId
          provider {
            id
            name
          }
        }
      }
    }
  }
`

export const ACCOUNT_DEPOSIT = gql`
  ${ACCOUNT_TRANSACTION_RECEIPT_FIELDS}
  mutation AccountDeposit($requestInput: AccountTransactionRequestInput) {
    accountDeposit(requestInput: $requestInput) {
      ...AccountTransactionFragment
    }
  }
`

export const BEGIN_WITHDRAWAL = gql`
  ${BEGIN_WITHDRAWAL_RECEIPT_FIELDS}
  mutation BeginAccountWidrawal($requestInput: AccountTransactionRequestInput) {
    beginAccountWithdrawal(requestInput: $requestInput) {
      ...BeginWithdrawalTransactionFragment
    }
  }
`

export const CANCEL_WITDRAWAL = gql`
  ${ACCOUNT_TRANSACTION_RECEIPT_FIELDS}
  mutation CancelTransaction($requestInput: AccountOperationRequestInput) {
    cancelAccountWithdrawal(requestInput: $requestInput) {
      ...AccountTransactionFragment
    }
  }
`

export const COMPLETE_WITDRAWAL = gql`
  ${ACCOUNT_TRANSACTION_RECEIPT_FIELDS}
  mutation CompleteAccountWithdrawal(
    $requestInput: AccountOperationRequestInput
  ) {
    completeAccountWithdrawal(requestInput: $requestInput) {
      ...AccountTransactionFragment
    }
  }
`

export const CHANGE_ACCOUNT_STATUS = gql`
  mutation ChangeAccountStatus(
    $requestInput: ChangeAccountStatusRequestAsyncInput
  ) {
    changeAccountStatus(requestInput: $requestInput) {
      accountId
      status
    }
  }
`

export const LINK_WALLET_ACCOUNT = gql`
  mutation CreateAccount($requestInput: CreateWalletAccountRequestInput) {
    createWalletAccount(requestInput: $requestInput) {
      accountId
      assigned
    }
  }
`

export const ACCOUNT_TRANSACTIONS_QUERY = gql`
  ${ACCOUNT_TRANSACTION_FIELDS}
  query AccountTransactions(
    $where: TransactionFilterInput
    $order: [TransactionSortInput!]
    $skip: Int
    $take: Int
  ) {
    transactions(where: $where, order: $order, skip: $skip, take: $take) {
      items {
        ...AccountTransactionFieldsFragment
      }
    }
  }
`
