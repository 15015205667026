import _ from 'lodash'
import { LeagueEvent } from 'src/placebet/core'
import {
  AllowedWager,
  EventDetails,
  EventResults,
  Legs,
  Pool,
  positionsMap,
  Runner,
  RunnersLookup,
  WagerModifier
} from 'src/placebet/products/horseraces/models'
import { HorseRacesLeagueEventsState } from 'src/placebet/products/horseraces/redux/horseRacesProductSlice'
import {
  getBoxWagers,
  getValidWagers
} from 'src/placebet/products/horseraces/redux/wagerRequestSlice/services'
import { distinct } from 'src/placebet/products/horseraces/services'

export const getWagerCombinations = (
  allowedWagers: AllowedWager[]
): string[] => {
  const result = allowedWagers.map((aw) => {
    const display = Object.keys(aw)
      .map((k) => aw[k])
      .join('-')
    return display
  })

  return result
}

export const verifyRunnersExists = (
  runners: Runner[],
  legRunners: number[]
): boolean => {
  try {
    const filteredRunners: number[] = runners
      .filter((e) => !e.scratch)
      .map((r) => r.runnerId)

    const exists = legRunners.every((r) => filteredRunners.includes(r))

    return exists
  } catch (err) {
    return false
  }
}

export const getNextRaceId = (raceId: number, legs: Legs): number => {
  const key = Object.keys(legs)
    .map((k) => k)
    .pop()

  return !!key ? legs[key].raceId + 1 : raceId
}

export const getNonEmptyLegs = (wagerLegs: Legs) => {
  return Object.keys(wagerLegs)
    .map((k) => wagerLegs[k].runners)
    .filter((r) => r.length > 0)
}

export const tryInferWagers = (
  modifier: WagerModifier,
  pool: Pool,
  raceId: number,
  legs: Legs
) => {
  const wagerLegs = { ...legs }

  const providedLegs = getNonEmptyLegs(wagerLegs)
  if (modifier && modifier !== 'NONE') {
    return {
      submittedWagerLegs: wagerLegs,
      nonEmptyLegs: providedLegs.length
    }
  }

  const isSingleRace = pool.poolRaces === 1

  if (!isSingleRace) {
    if (providedLegs.length > 1 && pool.poolRaces) {
      let updated: Legs = { ...wagerLegs }
      const lastLegindex = providedLegs.length - 1
      const last = providedLegs[lastLegindex]
      for (let index = providedLegs.length; index < pool.poolRaces; index++) {
        const position = positionsMap[index]
        updated = {
          ...updated,
          [position]: { raceId: raceId + index, runners: [...last] }
        }
      }

      return { submittedWagerLegs: updated, nonEmptyLegs: pool?.poolRaces }
    }
    // 1,2,3,4 => 1,2,3,4
    else if (
      providedLegs.length === 1 &&
      providedLegs[0].length === pool.poolRaces
    ) {
      let updated: Legs = {}
      const runners = providedLegs[0]
      runners.forEach((runner, i) => {
        const position = positionsMap[i]
        updated = {
          ...updated,
          [position]: { raceId: raceId + i, runners: [runner] }
        }
      })

      return { submittedWagerLegs: updated, nonEmptyLegs: pool?.poolRaces }
    }
  } else {
    if (providedLegs.length > 1 && pool.legs) {
      let updated: Legs = { ...wagerLegs }
      const lastLegindex = providedLegs.length - 1
      const last = providedLegs[lastLegindex]
      for (let index = providedLegs.length; index < pool.legs; index++) {
        const position = positionsMap[index]
        updated = {
          ...updated,
          [position]: { raceId, runners: [...last] }
        }
      }

      return { submittedWagerLegs: updated, nonEmptyLegs: pool?.legs }
    }
    // 1,2,3,4 => 1/2/3/4
    else if (
      providedLegs.length === 1 &&
      providedLegs[0].length === pool.legs
    ) {
      let updated: Legs = {}
      const runners = providedLegs[0]
      runners.forEach((runner, i) => {
        const position = positionsMap[i]
        updated = {
          ...updated,
          [position]: { raceId, runners: [runner] }
        }
      })

      return { submittedWagerLegs: updated, nonEmptyLegs: pool?.legs }
    }
  }

  return { submittedWagerLegs: wagerLegs, nonEmptyLegs: providedLegs.length }
}

export const parseAndValidateWagerLegs = (
  pool: Pool,
  runners: RunnersLookup,
  modifier: WagerModifier,
  wagerLegs: Legs
) => {
  const legsArray = Object.keys(wagerLegs).map((k) => wagerLegs[k])

  // // 1) Confirm that the runners provider on the legs actually exist
  // const hasInvalidRunners = legsArray
  //   .map((leg) => verifyRunnersExists(runners[leg.raceId], leg.runners))
  //   .includes(false)

  // if (hasInvalidRunners) {
  //   throw new Error('POSICION_NO_EXISTENTE_O_DESCARTADA')
  // }

  // 2) Confirm there are no duplicated races in a single race leg
  const hasDuplicatedRunnersInLeg = legsArray
    .map((leg) => leg.runners.filter(distinct).length === leg.runners.length)
    .includes(false)

  if (hasDuplicatedRunnersInLeg) {
    throw new Error('CORREDORES_DUPLICADOS_EN_UNA_PATA')
  }

  if (modifier === 'NONE') {
    const numberOfLegs = pool.poolRaces === 1 ? pool.legs : pool.poolRaces
    if (numberOfLegs !== legsArray.length) {
      throw new Error('JUGADA_EXCEDE_PATAS')
    }
  }

  return legsArray
}

export const keyboardSubmitWager = (
  state: HorseRacesLeagueEventsState
): HorseRacesLeagueEventsState => {
  const {
    pool,
    event: {
      details: { raceId }
    },
    wagerPreview: { modifier, runners, bet, legs }
  } = state

  const hasLegs = Object.keys(legs).length > 0
  if (!hasLegs) {
    return {
      ...state
    }
  }

  const singleRace = pool?.poolRaces === 1

  const { submittedWagerLegs, nonEmptyLegs } = tryInferWagers(
    modifier,
    pool,
    raceId,
    legs
  )

  if (
    (singleRace && modifier === 'NONE' && nonEmptyLegs !== pool.legs) ||
    (!singleRace && modifier === 'NONE' && nonEmptyLegs !== pool.poolRaces)
  ) {
    return {
      ...state,
      error: new Error('JUGADA_INVALIDA_PATAS_INCOMPLETAS')
    }
  }

  try {
    // validate the wager
    const legs = parseAndValidateWagerLegs(
      pool,
      runners,
      modifier,
      submittedWagerLegs
    )
    const wagerRequest = {
      typeBet: pool.poolId,
      race: raceId,
      quick: false,
      bet,
      modifier,
      legs
    }

    return {
      ...state,
      error: undefined,
      wagerPreview: {
        ...state.wagerPreview,
        legs: {},
        wagerRequest,
        wagerCombinations: []
      }
    }
  } catch (error) {
    return {
      ...state,
      error
    }
  }
}

export const getRunnersLookup = (
  raceId: number,
  runners: Runner[],
  siblings: LeagueEvent<EventDetails, EventResults>[]
) => {
  let result: RunnersLookup = { [raceId]: runners }
  if (siblings) {
    siblings.forEach((e) => {
      result = {
        ...result,
        [e.details.raceId]: e.details.runners
      }
    })
  }

  return result
}

export function handleKeyPressed(
  state: HorseRacesLeagueEventsState,
  keyPressed: string
): HorseRacesLeagueEventsState {
  const {
    pool,
    raceParam,
    wagerPreview: { modifier, legs, runners, inputState, wagerDefaultBet }
  } = state

  const raceId = Number.parseInt(raceParam || '1')
  if (inputState === 'Amount') {
    switch (keyPressed) {
      case 'CE/$':
      case 'Enter': {
        const currentWager = !!wagerDefaultBet
          ? wagerDefaultBet > pool.maximum
            ? pool.maximum
            : wagerDefaultBet < pool.wagerMinimum
            ? pool.wagerMinimum
            : wagerDefaultBet
          : pool.wagerMinimum

        const updatedBet = currentWager

        return {
          ...state,
          wagerPreview: {
            ...state.wagerPreview,
            bet: updatedBet,
            wagerDefaultBet: currentWager,
            inputState: 'Legs'
          }
        }
      }

      case 'Escape':
      case 'Cancel': {
        return {
          ...state,
          wagerPreview: {
            ...state.wagerPreview,
            inputState: 'Legs'
          }
        }
      }

      case 'Backspace': {
        if (!wagerDefaultBet) {
          return {
            ...state
          }
        }

        const updatedBet =
          Number.parseFloat(wagerDefaultBet.toString().slice(0, -1)) ||
          undefined
        return {
          ...state,
          wagerPreview: {
            ...state.wagerPreview,
            wagerDefaultBet: updatedBet
          }
        }
      }
    }

    const updatedBet = Number.parseFloat(
      (wagerDefaultBet || 0).toString() + keyPressed
    )
    if (!updatedBet) {
      return {
        ...state,
        error: new Error('SOLO_DIGITOS_PERMITIDOS_EN_MONTO')
      }
    }

    return {
      ...state,
      wagerPreview: {
        ...state.wagerPreview,
        wagerDefaultBet: updatedBet
      }
    }
  } else if (inputState === 'Legs') {
    const numberOfLegs = Object.keys(legs).length

    if (!pool) {
      return {
        ...state
      }
    }

    const noErrorState = { ...state, error: undefined }

    switch (keyPressed) {
      case 'With':
        {
          if (modifier !== 'NONE') {
            return {
              ...state
            }
          }

          if (numberOfLegs == 0) {
            return {
              ...state,
              error: new Error('NO_ES_POSSIBLE_ANADIR_PATAS_VACIAS')
            }
          }

          if (numberOfLegs > 0) {
            const position = positionsMap[numberOfLegs - 1]
            if (legs[position].runners.length === 0) {
              return {
                ...state,
                error: new Error('NO_ES_POSSIBLE_ANADIR_PATAS_VACIAS')
              }
            }
          }

          const singleRace = pool?.poolRaces === 1
          if (singleRace) {
            if (numberOfLegs + 1 > pool.legs) {
              return {
                ...state,
                error: new Error('NO_ES_POSSIBLE_ANADIR_MAS_PATAS')
              }
            }

            let updated = ({ ...legs } || {}) as Legs
            const position = positionsMap[numberOfLegs]
            updated[position] = { raceId, runners: [] }
            return {
              ...state,
              wagerPreview: {
                ...state.wagerPreview,
                displayValue: `${state.wagerPreview.displayValue}/`,
                legs: updated
              }
            }
          } else {
            if (numberOfLegs + 1 > pool.poolRaces) {
              return {
                ...state,
                error: new Error('NO_ES_POSSIBLE_ANADIR_MAS_PATAS')
              }
            }
            let nextRaceId = getNextRaceId(raceId, legs)
            let updated = ({ ...legs } || {}) as Legs
            const position = positionsMap[numberOfLegs]
            updated[position] = { raceId: nextRaceId, runners: [] }
            return {
              ...state,
              wagerPreview: {
                ...state.wagerPreview,
                displayValue: `${state.wagerPreview.displayValue}/`,
                legs: updated
              }
            }
          }
        }
        break

      case 'Enter': {
        {
          return {
            ...keyboardSubmitWager(state)
          }
        }
      }

      case 'Backspace': {
        const keys = Object.keys(legs).map((k) => k)
        if (keys.length === 0) {
          return {
            ...state
          }
        }

        const legsClone = _.cloneDeep(legs)
        const last = keys[keys.length - 1]
        let leg = _.cloneDeep(legsClone[last])

        let updated = { ...legsClone }

        if (leg && leg.runners?.length > 1) {
          leg.runners.pop()
          updated = {
            ...legsClone,
            [last]: { ...leg }
          }

          const validWagers =
            !!modifier && modifier === 'BOX'
              ? getBoxWagers(updated, pool)
              : getValidWagers(updated, pool)

          return {
            ...noErrorState,
            wagerPreview: {
              ...state.wagerPreview,
              legs: { ...updated },
              wagerCombinations: getWagerCombinations(validWagers)
            }
          }
        } else {
          delete legsClone[last]
          updated = { ...legsClone }

          return {
            ...noErrorState,
            wagerPreview: {
              ...state.wagerPreview,
              legs: { ...updated },
              wagerCombinations: []
            }
          }
        }
      }

      case 'CE/$': {
        return {
          ...state,
          wagerPreview: {
            ...state.wagerPreview,
            inputState: 'Amount',
            wagerDefaultBet: undefined,
            bet: undefined
          }
        }
      }

      case 'Escape':
      case 'Cancel': {
        return {
          ...state,
          wagerPreview: {
            ...state.wagerPreview,
            legs: {},
            wagerCombinations: undefined,
            inputState: 'Legs'
          }
        }
      }

      case 'All': {
        const current = numberOfLegs - 1 < 0 ? 0 : numberOfLegs - 1
        let updated = ({ ...legs } || {}) as Legs
        const position = positionsMap[current]
        let leg = legs[position] || { raceId, runners: [] }

        const runnerIds = runners[leg.raceId]
          .filter((e) => !e.scratch)
          .map((r) => r.runnerId)
          .filter(distinct)
          .sort((a, b) => a - b)

        leg = { ...leg, runners: [...runnerIds] }
        updated = { ...updated, [position]: leg }

        const validWagers =
          !!modifier && modifier === 'BOX'
            ? getBoxWagers(updated, pool)
            : getValidWagers(updated, pool)

        return {
          ...state,
          wagerPreview: {
            ...state.wagerPreview,
            legs: updated,
            wagerCombinations: getWagerCombinations(validWagers)
          }
        }
      }

      default:
        {
          const runner = Number.parseInt(keyPressed)
          if (!runner || !pool.poolId) {
            return {
              ...state,
              wagerPreview: {
                ...state.wagerPreview,
                key: { value: keyPressed, nonce: Date.now() }
              }
            }
          }

          const current = numberOfLegs - 1 < 0 ? 0 : numberOfLegs - 1
          let position = positionsMap[current]
          let updated = { ...legs } || {}
          let leg = updated[position]

          if (leg) {
            leg = { ...leg, runners: [...leg.runners, runner] }
          } else {
            leg = { raceId, runners: [runner] }
          }

          updated = { ...updated, [position]: leg }

          const validWagers =
            !!modifier && modifier === 'BOX'
              ? getBoxWagers(updated, pool)
              : getValidWagers(updated, pool)

          return {
            ...noErrorState,
            wagerPreview: {
              ...state.wagerPreview,
              key: { value: keyPressed, nonce: Date.now() },
              legs: updated,
              wagerCombinations: getWagerCombinations(validWagers)
            }
          }
        }
        break
    }
  }
}
