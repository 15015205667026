import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductEnum } from 'src/placebet/core'
import {
  isBusyAction,
  isRejectedAction
} from 'src/placebet/core/api/productApi/productSlice'

import { parseErrorResponse } from 'src/placebet/core/api/productApi/services'
import { AnyTicket } from 'src/placebet/core/models'
import cockfightsApi from 'src/placebet/products/cockfights/redux/cockfightsProductApi'
import horseRacesApi from 'src/placebet/products/horseraces/redux/horseRacesProductApi'
import { isValidTicketNumber } from 'src/placebet/products/horseraces/redux/wagerRequestSlice/services'

export interface TicketManagementState {
  product?: ProductEnum
  ticketNumber?: string
  ticket?: AnyTicket
  busy?: boolean
  error?: Error
}

const initialState: TicketManagementState = {}

const ticketManagementSlice = createSlice({
  name: 'ticketManagement',
  initialState,
  reducers: {
    setTicketNumber: (state, { payload }: PayloadAction<string>) => {
      if (isValidTicketNumber(payload)) {
        state.ticketNumber = payload
        state.error = undefined
      } else {
        state.error = new Error('INVALID_TICKET_NUMBER')
      }
      state.busy = false
    },
    clearTicket: (state) => {
      state.ticket = undefined
      state.busy = false
      state.ticketNumber = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isBusyAction, (state) => {
        // state.busy = true
      })
      .addMatcher(isRejectedAction, (state, action) => {
        const { meta } = action
        const error = parseErrorResponse(meta)
        if (error) {
          state.error = error
        }
        state.busy = false
      })
      .addMatcher(
        horseRacesApi.endpoints.getHorseRacesTicket.matchFulfilled,
        (state, { payload }) => {
          state.ticket = payload
          state.busy = false
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.getCockfightsTicket.matchFulfilled,
        (state, { payload }) => {
          state.ticket = payload
          state.busy = false
        }
      )
  }
})

export const { setTicketNumber, clearTicket } = ticketManagementSlice.actions
export default ticketManagementSlice
