interface Languages {
  [key: string]: string
}

const locales: Languages = {
  ES: 'es-US',
  EN: 'en-US'
}

export default locales
