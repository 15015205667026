import React, { useContext } from 'react'
import { Grid, TextField } from '@mui/material'
import DatePicker from '@mui/lab/DatePicker'
import { ActivityHistoryDetailContext, setFilters } from '../state'
import { useAppSelector } from 'src/placebet/core'

export const HeaderFilters: React.FC = () => {
  const { workstation } = useAppSelector((state) => state.appContext)
  const {
    state: { filters },
    dispatch
  } = useContext(ActivityHistoryDetailContext)

  const handleChangeDate = (date: Date) => {
    dispatch(
      setFilters({
        ...filters,
        date: date
      })
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          value={filters.date}
          onChange={handleChangeDate}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>
      {workstation && (
        <>
          {/* <Grid item xs={12} md={6} lg={6}>
            <Select
              disabled
              fullWidth
              labelId={workstation.defaultCurrency.value}
              id="currency"
              value={workstation.defaultCurrency.value}
            >
              <MenuItem key={1} value={workstation.defaultCurrency.value}>
                {workstation.defaultCurrency.value}
              </MenuItem>
            </Select>
          </Grid> */}
        </>
      )}
    </Grid>
  )
}
