import React from 'react'
import { Box, CircularProgress } from '@mui/material'

import { AuthConsumer } from '../AuthProvider'

const Protected = ({
  children,
  loadingComponent
}: {
  children: React.ReactNode
  loadingComponent?: JSX.Element
}) => {
  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (!!children && isAuthenticated()) {
          return <React.Fragment>{children}</React.Fragment>
        } else {
          signinRedirect()
          return loadingComponent ? (
            loadingComponent
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                my: 5
              }}
            >
              <CircularProgress />
            </Box>
          )
        }
      }}
    </AuthConsumer>
  )
}

export default Protected
