import { WorkStationMetadata } from '../../models'
import { ProductEnum, currentUtcDate } from '../../services'
import {
  SalesCategoryItem,
  WorkstationSalesByCategory,
  TransactionCategoryEnum,
  TicketTransactionsSummary,
  CashTransactionsSummary,
  WalletTransactionsSummary
} from './models'

export function parseErrorResponse(meta: any): Error {
  if (!meta?.baseQueryMeta) {
    return undefined
  }

  const errors = meta?.baseQueryMeta?.response?.errors
  if (!errors) {
    return new Error('UNABLE_TO_COMPLETE_OPERATION')
  }

  const all = (errors as any[]).map(
    (e) => new Error(e.message.toUpperCase().replaceAll(' ', '_'))
  )

  return all[0]
}

interface AccountTotals {
  product: ProductEnum
  workstation?: WorkStationMetadata
  category: TransactionCategoryEnum
  date: Date
  balance: number
  categories: { [key: string]: SalesCategoryItem[] }
}

function reduceCashTotals(
  accountTransactions: CashTransactionsSummary[]
): AccountTotals {
  const accountTotal = accountTransactions
    .map((t) => {
      const {
        amount,
        count,
        key: { product, currency, date, workstation, category, transactionType }
      } = t

      return {
        product,
        currency,
        date,
        workstation,
        category,
        transactionType,
        amount,
        count
      }
    })
    .reduce((result, curr) => {
      return {
        ...result,
        currency: curr.currency,
        product: curr.product,
        date: curr.date,
        workstation: curr.workstation,
        category: curr.category,
        [curr.transactionType]: {
          amount: (result.amount || 0) + (curr.amount || 0),
          count: (result.count || 0) + (curr.count || 0)
        }
      }
    }, {} as any)

  const categoriesItems: SalesCategoryItem[] = [
    {
      title: 'DRAW',
      amount: accountTotal.DRAW?.amount || 0,
      currency: accountTotal.currency,
      count: accountTotal.DRAW?.count || 0
    },
    {
      title: 'PAY_DRAW',
      amount: accountTotal.PAY_DRAW?.amount || 0,
      currency: accountTotal.currency,
      count: accountTotal.PAY_DRAW?.count || 0
    },
    {
      title: 'PENDING_DRAW',
      amount:
        (accountTotal.DRAW?.amount || 0) - (accountTotal.PAY_DRAW?.amount || 0),
      currency: accountTotal.currency
    }
  ]

  const accountResults = {
    product: accountTotal.product,
    workstation: accountTotal.workstation,
    category: accountTotal.category,
    date: accountTotal.date,
    balance:
      (accountTotal.DRAW?.amount || 0) - (accountTotal.PAY_DRAW?.amount || 0),
    categories: { Cash: categoriesItems }
  }

  return accountResults
}

function reduceTicketTotals(
  accountTransactions: TicketTransactionsSummary[]
): AccountTotals {
  const accountTotal = accountTransactions
    .map((t) => {
      const {
        amount,
        count,
        surcharge,
        key: { product, currency, date, workstation, category, transactionType }
      } = t

      return {
        product,
        currency,
        date,
        workstation,
        category,
        transactionType,
        amount,
        count,
        surcharge
      }
    })
    .reduce((result, curr) => {
      return {
        ...result,
        currency: curr.currency,
        product: curr.product,
        date: curr.date,
        workstation: curr.workstation,
        category: curr.category,
        [curr.transactionType]: {
          amount: (result.amount || 0) + (curr.amount || 0),
          count: (result.count || 0) + (curr.count || 0),
          surcharge: (result.surcharge || 0) + (curr.surcharge || 0)
        }
      }
    }, {} as any)

  const balance =
    (accountTotal.SALE?.amount || 0) -
    (accountTotal.CANCELLATION?.amount || 0) -
    (accountTotal.PAYMENT?.amount || 0) +
    ((accountTotal.SALE?.surcharge || 0) -
      (accountTotal.CANCELLATION?.surcharge || 0))

  const categoriesItems: SalesCategoryItem[] = [
    {
      title: 'SALES',
      amount: (accountTotal.SALE?.amount || 0) + accountTotal.SALE?.surcharge,
      currency: accountTotal.currency,
      count: accountTotal.SALE?.count || 0
    },
    {
      title: 'CANCELLATIONS',
      amount:
        (accountTotal.CANCELLATION?.amount || 0) +
        accountTotal.CANCELLATION?.surcharge,
      currency: accountTotal.currency,
      count: accountTotal.CANCELLATION?.count || 0
    },
    {
      title: 'PAYMENTS',
      amount: accountTotal.PAYMENT?.amount || 0,
      currency: accountTotal.currency,
      count: accountTotal.PAYMENT?.count || 0
    },
    // {
    //   title: 'SURCHARGE',
    //   amount:
    //     (accountTotal.SALE?.surcharge || 0) -
    //     (accountTotal.CANCELLATION?.surcharge || 0),
    //   currency: accountTotal.currency
    // },
    {
      title: 'BALANCE',
      amount: balance,
      currency: accountTotal.currency
    }
  ]

  const accountResults = {
    product: accountTotal.product,
    workstation: accountTotal.workstation,
    category: accountTotal.category,
    date: accountTotal.date,
    balance: balance,
    categories: { Tickets: categoriesItems }
  }

  return accountResults
}

function reduceWalletTotals(
  accountTransactions: WalletTransactionsSummary[]
): AccountTotals {
  const walletTotal = accountTransactions
    .map((t) => {
      const {
        amount,
        count,
        key: { currency, date, workstation, transactionType }
      } = t

      return {
        currency,
        date,
        workstation,
        transactionType,
        amount,
        count
      }
    })
    .reduce((result, curr) => {
      return {
        ...result,
        currency: curr.currency,
        // product: curr.product,
        date: curr.date,
        workstation: curr.workstation,
        //category: curr.category,
        [curr.transactionType]: {
          amount: (result.amount || 0) + (curr.amount || 0),
          count: (result.count || 0) + (curr.count || 0)
        }
      }
    }, {} as any)

  const categoriesItems: SalesCategoryItem[] = [
    {
      title: 'DEPOSIT',
      amount: walletTotal.DEPOSIT?.amount || 0,
      currency: walletTotal.currency,
      count: walletTotal.DEPOSIT?.count || 0
    },
    {
      title: 'WITHDRAWAL',
      amount: walletTotal.WITHDRAWAL?.amount || 0,
      currency: walletTotal.currency,
      count: walletTotal.WITHDRAWAL?.count || 0
    },
    {
      title: 'BALANCE',
      amount:
        (walletTotal.DEPOSIT?.amount || 0) -
        (walletTotal.WITHDRAWAL?.amount || 0),
      currency: walletTotal.currency
    }
  ]

  const accountResults = {
    product: walletTotal.product,
    workstation: walletTotal.workstation,
    category: walletTotal.category,
    date: walletTotal.date,
    balance:
      (walletTotal.DEPOSIT?.amount || 0) -
      (walletTotal.WITHDRAWAL?.amount || 0),
    categories: { Wallet: categoriesItems }
  }

  return accountResults
}

function reduceCommissionTickets(
  accountTransactions: TicketTransactionsSummary[]
): number {
  const commDebit = accountTransactions.reduce(function (prev, cur) {
    return prev + (cur.key.category === 'DEBIT' ? cur.commission : 0)
  }, 0)

  const commCredit = accountTransactions.reduce(function (prev, cur) {
    return prev + (cur.key.category === 'CREDIT' ? cur.commission : 0)
  }, 0)

  return commDebit - commCredit
}

export function transformDailySales(
  accountTransactions: CashTransactionsSummary[],
  tickeTransactions: TicketTransactionsSummary[],
  walletTransactions: WalletTransactionsSummary[]
): WorkstationSalesByCategory {
  const cashtotals = reduceCashTotals(accountTransactions)

  const ticketTotals = reduceTicketTotals(tickeTransactions)

  const walletTotals = reduceWalletTotals(walletTransactions)

  const commissionTotal = reduceCommissionTickets(tickeTransactions)

  const {
    categories: ticketCategories,
    balance: ticketsBalance,
    workstation: ticketsWorkstation,
    ...ticketCommon
  } = ticketTotals
  const {
    categories: cashCategories,
    balance: cashBalance,
    workstation: cashWorkstation,
    ...cashCommon
  } = cashtotals
  const {
    categories: walletCategories,
    balance: walletBalance,
    ...walletCommon
  } = walletTotals

  const summaryCategory = {
    All: [
      {
        title: 'CASH_REGISTER',
        amount:
          (ticketsBalance || 0) + (cashBalance || 0) + (walletBalance || 0),
        currency: 'DOP'
      },
      {
        title: 'GENERAL_BALANCE',
        amount: (ticketsBalance || 0) + (walletBalance || 0),
        currency: 'DOP'
      },
      {
        title: 'COMMISSION',
        amount: reduceCommissionTickets(tickeTransactions),
        currency: 'DOP'
      }
    ]
  }

  const result = {
    ...ticketCommon,
    ...cashCommon,
    //...walletCommon,
    workstation: ticketsWorkstation || cashWorkstation,
    categories: {
      ...summaryCategory,
      ...ticketCategories,
      ...cashCategories,
      ...walletCategories
    }
  }

  return result as any
}
