import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ConnectionStatus,
  LeagueEvent,
  normalizeRouteParam,
  ProductEnum
} from 'src/placebet/core'
import {
  isBusyAction,
  isRejectedAction
} from 'src/placebet/core/api/productApi/productSlice'
import { parseErrorResponse } from 'src/placebet/core/api/productApi/services'
import { AnyTicket, DictionaryOf } from 'src/placebet/core/models'
import { EventDetails, EventResults } from '../models'
import cockfightsApi from './cockfightsProductApi'
import { ticketUpdate } from './services'

interface LeagueEventsState<TEventDetail, TEventResults> {
  product: ProductEnum
  events: LeagueEvent<TEventDetail, TEventResults>[]
  event?: LeagueEvent<TEventDetail, TEventResults>
  grouping?: string
  error?: Error
  tickets: DictionaryOf<AnyTicket>
  ticket?: AnyTicket
  realtime: {
    lastUpdated?: Date
    hubError?: Error
    hubStatus?: ConnectionStatus
  }
  busy?: boolean
  printQueue?: AnyTicket[]
  matchParm?: string
}

interface CockfightsLeagueEventsState
  extends LeagueEventsState<EventDetails, EventResults> {}

const initialState: CockfightsLeagueEventsState = {
  product: 'COCKFIGHTS',
  events: [],
  tickets: {},
  realtime: {}
}

interface EventMap {
  [key: string]: LeagueEvent<EventDetails, EventResults>
}

function parseCurrentEvent(
  payload: LeagueEvent<EventDetails, EventResults>[],
  state: CockfightsLeagueEventsState
) {
  if (payload.length > 0) {
    state.event =
      payload.find((e) => e.eventName === state.matchParm) ||
      payload.filter((e) => e.eventStatus === 'Open')[0] ||
      payload[0]

    state.grouping = `${normalizeRouteParam(
      state.event?.league.name || 'UNKNONW_LEAGUE'
    )}`
  }

  // if (state.event) {
  //   state.wagerPreview = handleWagerPreviewEventChange(state)
  //   state.boardWagerRequest = handleWagerRequestEventChange(state)
  // }
}

const cockfigthsLeagueEventsSlice = createSlice({
  name: 'cockfights',
  initialState,
  reducers: {
    setLeagueEvents: (
      state,
      action: PayloadAction<LeagueEvent<EventDetails, EventResults>[]>
    ) => {
      state.events = action.payload
      if (action.payload.length > 0) {
        if (state.matchParm) {
          state.event =
            state.events.find((e) => e.eventName === state.matchParm) ||
            state.events[0]
        } else {
          state.event = state.events[0]
        }
      }
    },
    updateLeagueEvents: (
      state,
      action: PayloadAction<{
        data: LeagueEvent<EventDetails, EventResults>[]
        lastUpdate: Date
      }>
    ) => {
      const { events } = state
      let eventsMap = events.reduce((map: EventMap, item) => {
        map[item.id] = item
        return map
      }, {})

      action.payload.data.forEach((updatedEvent) => {
        const {
          id,
          takeDown,
          details,
          results: raceResults,
          eventStatus
        } = updatedEvent
        let current = eventsMap[id]
        eventsMap[id] = {
          ...current,
          takeDown,
          eventStatus,
          details,
          updated: action.payload.lastUpdate,
          results: raceResults
        }
      })

      const merged = Object.values(eventsMap) || []
      state.events = [...merged]
      state.realtime.lastUpdated = action.payload.lastUpdate

      if (state.events.length > 0) {
        if (state.matchParm) {
          state.event =
            state.events.find((e) => e.eventName === state.matchParm) ||
            state.events[0]
        } else {
          state.event = state.events[0]
        }
      }
    },
    updateRealtimeConnectionStatus: (
      state,
      action: PayloadAction<{ hubStatus: ConnectionStatus; hubError?: Error }>
    ) => {
      if (action.payload.hubError) {
        state.realtime.hubError = action.payload.hubError
      }
      state.realtime.hubStatus = action.payload.hubStatus
    },
    clearPrintQueue: (state) => {
      state.printQueue = []
    },
    selectMatch: (state, { payload }: PayloadAction<string>) => {
      state.matchParm = payload
      if (state.events.length > 0) {
        state.event =
          state.events.find((e) => e.eventName === state.matchParm) ||
          state.events.filter((e) => e.eventStatus === 'Open')[0]
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isBusyAction, (state) => {
        state.busy = true
      })
      .addMatcher(isRejectedAction, (state, { error }) => {
        state.busy = false
        state.error = parseErrorResponse(error)
      })
      .addMatcher(
        cockfightsApi.endpoints.getCockfightsLeagueEvents.matchFulfilled,
        (state, { payload: { events, tickets } }) => {
          state.events = events
          parseCurrentEvent(events, state)
          state.tickets = tickets
          state.ticket = tickets[state.grouping]
          state.busy = false
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.getCockfightsLeagueEvent.matchFulfilled,
        (state, { payload: { event, tickets } }) => {
          state.events = [event]
          parseCurrentEvent([event], state)
          state.tickets = tickets
          state.ticket = tickets[state.grouping]
          state.busy = false
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.submitCockfightsTicketWager.matchFulfilled,
        (state, { payload }) => {
          if (payload.grouping === state.grouping) {
            state.tickets = { ...state.tickets, [state.grouping]: payload }
            state.ticket = payload
          }

          state.busy = false
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.updateCockfightsTicketWagerAmount
          .matchFulfilled,
        (state, { payload }) => {
          state.ticket = payload
          state.busy = false
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.removeCockfightsTicket.matchFulfilled,
        (state, { payload }) => {
          if (payload.success) {
            const { tickets, grouping } = state
            const { [grouping]: current, ...others } = tickets
            if (current) {
              state.tickets = { ...others }
              state.ticket = undefined
            }
          }
          state.busy = false
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.removeCockfightsWager.matchFulfilled,
        (state, { payload }) => {
          ticketUpdate(state, payload)
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.removeCockfightsWagerCollection.matchFulfilled,
        (state, { payload }) => {
          ticketUpdate(state, payload)
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.submitCockfightsTicket.matchFulfilled,
        (state, { payload }) => {
          const { [state.ticket?.grouping]: current, ...others } = state.tickets
          state.tickets = { ...others }
          state.ticket = undefined
          state.printQueue = [payload]
          state.busy = false
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.getCockfightsTickets.matchFulfilled,
        (state, { payload }) => {
          state.tickets = payload
          state.ticket = payload[0]
          state.busy = false
        }
      )
  }
})

export const {
  setLeagueEvents,
  updateLeagueEvents,
  selectMatch,
  updateRealtimeConnectionStatus,
  clearPrintQueue
} = cockfigthsLeagueEventsSlice.actions
export default cockfigthsLeagueEventsSlice
