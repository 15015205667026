import React, { useCallback, useState } from 'react'
import { boolean } from 'yup'
import { number } from 'yup/lib/locale'

interface CounDownState {
  timeRemaining: number
  warning: boolean
  completed: boolean
}

const useCountDownTimer = (countdownFrom: number, warnAt?: number) => {
  const [state, setState] = useState<CounDownState>({
    timeRemaining: countdownFrom,
    warning: false,
    completed: false
  })

  const tick = () => {
    const { timeRemaining, warning, completed } = state
    if (completed) {
      return
    }

    const remaining = timeRemaining - 1
    if (remaining <= 0) {
      setState({ timeRemaining: 0, warning: false, completed: true })
    } else if (remaining <= (warnAt ?? 60) && !warning) {
      setState({ ...state, timeRemaining: remaining, warning: true })
    } else {
      setState({ ...state, timeRemaining: remaining })
    }
  }

  const reset = useCallback(() => {
    setState({ timeRemaining: countdownFrom, warning: false, completed: false })
  }, [])

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000)
    return () => clearInterval(timerId)
  })

  return { state, reset }
}

export default useCountDownTimer
