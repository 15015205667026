import { t } from 'i18next'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import {
  normalizeProductRouteParam,
  useAppDispatch,
  useAppSelector
} from 'src/placebet/core'
import { useLazyWorkstationBalanceQuery } from 'src/placebet/core/api/productApi/productApi'
import { useWalletCompleteWithdrawalMutation } from 'src/placebet/core/api/walletApi/walletApi'
import { currencies, CurrencyTypeEnum } from 'src/placebet/core/models'
import { KeyboardMapperContext } from '../../providers/CustomKeyboardProvider/state'
import { handleErrorMessage } from '../../../core/api/productApi/productSlice'
import DepositTransaction from '../DepositTransaction'

const KeyboardModalShortcutsCore = ({
  payTicket,
  cancelTicket,
  drawCash,
  paybackCash
}: {
  payTicket: (ticketNumber: string) => void
  cancelTicket: (ticketNumber: string) => void
  drawCash: (amount: number) => void
  paybackCash: (amount: number) => void
}) => {
  const {
    state: { value, modalCommand, toolbarAction }
  } = useContext(KeyboardMapperContext)

  const [walletWithdraw] = useWalletCompleteWithdrawalMutation()
  const [getWorkstationBalance] = useLazyWorkstationBalanceQuery()

  const navigate = useNavigate()

  const {
    product,
    workstation: { id: workstationId }
  } = useAppSelector((state) => state.appContext)

  const dispatch = useAppDispatch()

  const handleWalletWithdraw = (number: string, referenceId: string) => {
    const handleWithdrawAsync = async (number: string, referenceId: string) => {
      const tokens = number.split('-')
      if (tokens.length !== 3) return

      // build currencies regex
      const regex = `(?:${currencies.join('|')})`
      const currencyValue = tokens[2].match(regex)
      if (currencyValue.length !== 1) return

      const currency = currencyValue[0] as CurrencyTypeEnum

      getWorkstationBalance({
        product,
        currency,
        referenceId: workstationId
      }).then((results) => {
        const { data: balance } = results
        const amountString = tokens[2].replace(currency, '')
        const amount = Number.parseFloat(amountString)

        if (!amount) return

        if (amount > (balance ?? 0)) {
          dispatch(handleErrorMessage(t('AMOUNT_EXCEEDS_CURRENT_BALANCE')))
          return
        }

        walletWithdraw({ number, referenceId })
      })
    }

    handleWithdrawAsync(number, referenceId)
  }

  useEffect(() => {
    if (!value) {
      return
    }

    switch (modalCommand) {
      case 'Pay':
        payTicket(value)
        break

      case 'Void':
        cancelTicket(value)
        break

      case 'Draw':
        drawCash(Number.parseFloat(value))
        break

      case 'PayDraw':
        paybackCash(Number.parseFloat(value))
        break

      case 'Withdraw':
        handleWalletWithdraw(value, workstationId)
        break

      default:
        break
    }
  }, [value, modalCommand])

  useEffect(() => {
    switch (toolbarAction) {
      case 'NextEvents':
        {
          navigate(`/app/${normalizeProductRouteParam(product)}/`)
        }
        break

      case 'Tickets':
        {
          navigate(`/app/${normalizeProductRouteParam(product)}/tickets`)
        }
        break

      case 'SalesSummary':
        {
          navigate(
            `/app/${normalizeProductRouteParam(product)}/reports/sales-resume`
          )
        }
        break
    }
  }, [toolbarAction])

  return (
    <>
      <DepositTransaction />
      {/* {printContent || cashReceiptContent || walletPrintContent} */}
    </>
  )
}

export default KeyboardModalShortcutsCore
