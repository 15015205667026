import { Grid, Stack } from '@mui/material'
import { customFormatLocalDate, useAppSelector } from 'src/placebet/core'
import { useTranslation } from 'react-i18next'
import { ReceiptTypography } from 'src/placebet/products/components/recepits/components/styled'
import { DashedDivider } from 'src/placebet/products/components/recepits/TicketReceipt'

const InfoHeader = () => {
  const { t }: { t: any } = useTranslation()
  const now = new Date()
  const {
    appContext: { workstation }
  } = useAppSelector((state) => state)

  const enterpriseName = workstation.agency.enterprise.name
  const workstationName = workstation.name

  return (
    <Stack spacing={2} display={'flex'} direction={'column'}>
      <Stack
        direction="column"
        alignItems="center"
        alignContent="center"
        spacing={1}
      >
        <ReceiptTypography
          variant="caption"
          noWrap
          sx={{ textTransform: 'none' }}
        >
          {enterpriseName}
        </ReceiptTypography>
        <DashedDivider />
      </Stack>

      <Stack
        direction="column"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <ReceiptTypography variant="body2">{`${workstationName}`}</ReceiptTypography>
        <ReceiptTypography>{customFormatLocalDate(now)}</ReceiptTypography>
      </Stack>
    </Stack>
  )
}

export default InfoHeader
