import { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/material/styles'
import {
  completeToolbarAction,
  KeyboardMapperContext
} from 'src/placebet/products/providers/CustomKeyboardProvider/state'
import {
  Box,
  Divider,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import { t } from 'i18next'
import UserAccounts from './UserAccounts'

import { SearchTwoTone as SearchTwoToneIcon } from '@mui/icons-material'
import EmptyAnimation from 'src/placebet/components/EmptyAnimation'
import NotFoundAnimation from 'src/placebet/components/NotFoundAnimation'
import { Loader } from 'src/placebet/components'
import {
  useWalletAccountDepositMutation,
  useLazyWalletGetUserAccountsQuery
} from 'src/placebet/core/api/walletApi/walletApi'
import { DepositRequest } from 'src/placebet/core/api/walletApi/models'

const ErrorMessage = styled('span')(({ theme }) => ({
  color: theme.colors.error.main
}))

export default function DepositTransaction() {
  const {
    state: { toolbarAction },
    dispatch
  } = useContext(KeyboardMapperContext)

  const [accountDeposit] = useWalletAccountDepositMutation()
  const [queryProfileAccounts, { data, isFetching: loading, error }] =
    useLazyWalletGetUserAccountsQuery()

  // const { queryProfileAccounts, printContent } = useWallet()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [value, setValue] = useState<string>('')
  const [open, setOpen] = useState<boolean>(false)
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    switch (toolbarAction) {
      case 'Deposit':
        {
          setOpen(true)
        }
        break

      default:
        setOpen(false)
        break
    }
  }, [toolbarAction])

  const handleClose = () => {
    dispatch(completeToolbarAction())
    setValue('')
    setTouched(false)
  }

  const handleSubmitCommand = (event: any) => {
    if (event.keyCode === 13) {
      if (
        !event.target.value ||
        (event.target.value as string).trim().length === 0
      ) {
        event.preventDefault()
        return
      }

      queryProfileAccounts({
        where: {
          or: [
            {
              email: {
                eq: value
              }
            },
            {
              phoneNumber: {
                eq: value
              }
            }
          ]
        }
      })
      setTouched(true)
      event.preventDefault()
    }
  }

  const handleCommandChange = (event: any) => {
    const value = event.target.value as string
    const cleanValue = value.replace(/'/g, '-')
    setValue(cleanValue)
  }

  const handledSubmitDeposit = (depositRequest: DepositRequest) => {
    accountDeposit(depositRequest)
    dispatch(completeToolbarAction())
    setValue('')
  }

  const accountNotFoundMessage =
    (data && data.totalCount === 0) || error == 'USER_NOT_FOUND'

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Typography variant="h3">{t('DEPOSITOS')}</Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 0, minHeight: 420 }}>
          <Divider />
          <TextField
            autoFocus={!data}
            sx={{
              px: 2,
              py: 0
            }}
            InputProps={{
              style: {
                borderRadius: 0
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              )
            }}
            onKeyDown={handleSubmitCommand}
            onKeyPress={handleSubmitCommand}
            onChange={handleCommandChange}
            placeholder={t('BUSCAR_POR_EMAIL_O_TELEFONO')}
            value={value}
            size="small"
            fullWidth
            margin="normal"
            variant="outlined"
          />

          <Divider variant="middle" />
          <Loader loading={loading}>
            {touched && data && data.items.length > 0 ? (
              <UserAccounts
                profile={data.items[0]}
                onSubmit={handledSubmitDeposit}
              />
            ) : (
              <Box
                display={'flex'}
                justifyContent="center"
                justifyItems="center"
                sx={{
                  width: '100%',
                  height: '360px'
                }}
              >
                {loading === undefined ? (
                  <EmptyAnimation />
                ) : (
                  <NotFoundAnimation />
                )}
              </Box>
            )}
          </Loader>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('CLOSE')}</Button>
        </DialogActions>
      </Dialog>
      {/* {printContent} */}
    </div>
  )
}
