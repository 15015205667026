import React from 'react'
import { AuthConsumer } from '../AuthProvider'
import { LoadingAnimation  } from '../../../components'

const LogoutCallback = () => (
  <AuthConsumer>
    {({ signoutRedirectCallback }) => {
      signoutRedirectCallback()
      return <LoadingAnimation />
    }}
  </AuthConsumer>
)

export default LogoutCallback
