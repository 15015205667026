import {
  Button,
  Card as MuiCard,
  Box,
  Typography,
  Divider,
  List,
  styled,
  Tabs,
  Tab,
  Stack,
  useTheme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import EventDetailRow from '../EventDetailRow'
import EventResultsRow from '../EventResultsRow'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import PreviewIcon from '@mui/icons-material/Preview'
import LeagueEventReceipt from '../../../../../reports/LeagueEventReceipt'
import { useReactToPrint } from 'react-to-print'
import EventHeader from '../../Event/EventCard/EventHeader'
import Scrollbar from 'src/components/Scrollbar'
import PreviewReport from 'src/placebet/products/reports/components/Preview'
import { useAppSelector } from 'src/placebet/core'
import { useSearchParams } from 'react-router-dom'
import { PulsatingIcon } from 'src/placebet/layouts/ExtendedSidebarLayout/Sidebar/components/styled'
import { SyncProblemTwoTone as SyncIcon } from '@mui/icons-material'

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(3)};
                font-size: ${theme.typography.pxToRem(16)};

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.colors.alpha.black[100]};
            }
        }
  `
)

const pageStyle = `
  @page {
    size: 80mm 297mm; 
    margin: 0mm;  
  }
`

const PrintWrapper = styled(Box)(() => ({
  width: '100%',
  '& .MuiTypography-root': {
    lineHeight: '15px'
  }
}))

const UpcomingContentWrapper = styled(Box)(({ theme }) => ({
  height: `100%`,
  position: 'relative',
  paddingBottom: '196px'
}))

const Card = styled(MuiCard)(({ theme }) => ({
  height: `calc(100vh - ${theme.header.height})`,
  paddingBottom: theme.spacing(0)
}))

const ContentWrapper = styled(Box)(({ theme }) => ({
  height: `100%`,
  position: 'relative',
  paddingBottom: theme.header.height
}))

function League() {
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()

  const [contentToPrint, setContentToPrint] = useState<JSX.Element | null>(null)
  const [contentToPreview, setContentToPreview] = useState<JSX.Element | null>(
    null
  )
  const componentRef = useRef<any>(null)

  const {
    realtime: { lastUpdated, hubStatus },
    events: results,
    event
  } = useAppSelector((state) => state.horseRaces)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
    onAfterPrint: () => {
      window.focus()
    }
  })

  let [searchParams, setSearchParams] = useSearchParams()
  let currentTab = useMemo(() => {
    const queryParams = Object.fromEntries([...searchParams])
    const { tab } = queryParams
    if (!tab) {
      return event?.eventStatus === 'Open' ? 'upcoming' : 'results'
    } else if (tab === 'upcoming' && event?.eventStatus !== 'Open') {
      setSearchParams({ ...queryParams, tab: 'results' })
    } else {
      return tab
    }
  }, [searchParams, event])

  const noUpcomingEvents = useMemo(() => {
    return results.every((item) => item.eventStatus !== 'Open')
  }, [results])

  const tabs = [
    { value: 'upcoming', label: t('PROXIMOS'), disabled: noUpcomingEvents },
    { value: 'results', label: t('RESULTADOS') }
  ]

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    const params = Object.fromEntries([...searchParams])
    setSearchParams({ ...params, tab: value })
  }

  const printReceipt = () => {
    setContentToPrint(
      <LeagueEventReceipt
        leagueName={event?.league.name}
        events={results}
        print={true}
      />
    )

    setTimeout(() => {
      handlePrint()
      setContentToPrint(null)
    }, 1)
  }

  const previewReceipt = () => {
    setContentToPreview(
      <LeagueEventReceipt leagueName={event?.league.name} events={results} />
    )
  }

  return (
    <>
      <Card square>
        <Box
          px={2}
          py={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography gutterBottom variant="h4">
                {`${event?.league.name}`}
              </Typography>

              {hubStatus === 'Disconnected' && (
                <PulsatingIcon sx={{ mr: 2 }}>
                  <SyncIcon
                    fontSize="medium"
                    sx={{ color: theme.palette.error.light }}
                  />
                </PulsatingIcon>
              )}
            </Stack>
            <TabsContainerWrapper sx={{ p: 0 }}>
              <Tabs
                onChange={handleTabsChange}
                value={currentTab}
                variant="scrollable"
                scrollButtons="auto"
                textColor="primary"
                indicatorColor="primary"
              >
                {tabs.map((tab) => (
                  <Tab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </Tabs>
            </TabsContainerWrapper>
          </Stack>

          <Box display="flex" alignItems="flex-start">
            <Button
              fullWidth
              variant="contained"
              startIcon={<PreviewIcon />}
              color="primary"
              onClick={previewReceipt}
              size={'small'}
              sx={{ mr: 1 }}
            >
              {t('VER_RETIROS')}
            </Button>

            <Button
              fullWidth
              variant="contained"
              startIcon={<LocalPrintshopIcon />}
              color="primary"
              onClick={printReceipt}
              size={'small'}
            >
              {t('IMPRIMIR')}
            </Button>
          </Box>
        </Box>

        {currentTab === 'upcoming' && (
          <UpcomingContentWrapper>
            <EventHeader />
            <Divider />
            <Scrollbar>
              <List component="div" disablePadding>
                {results
                  .filter(
                    (e) =>
                      e.eventStatus === 'Open' &&
                      e.eventName == event?.eventName
                  )
                  .map((event) => (
                    <EventDetailRow key={event.id} event={event} />
                  ))}
              </List>
            </Scrollbar>
          </UpcomingContentWrapper>
        )}
        {currentTab === 'results' && (
          <ContentWrapper>
            <Scrollbar>
              <List component="div" disablePadding>
                {results
                  .filter((e) => e.eventStatus !== 'Open')
                  .map((event) => (
                    <EventResultsRow key={event.id} event={event} />
                  ))}
              </List>
            </Scrollbar>
          </ContentWrapper>
        )}
      </Card>
      <PrintWrapper ref={componentRef}>
        {contentToPrint && (contentToPrint as JSX.Element)}
      </PrintWrapper>
      <PreviewReport
        open={!!contentToPreview}
        handlePrint={printReceipt}
        handleClose={() => setContentToPreview(null)}
      >
        {contentToPreview && (contentToPreview as JSX.Element)}
      </PreviewReport>
    </>
  )
}

export default League
