import { Profile } from 'oidc-client'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from './AuthProvider'

const useAuth = () => {
  const { logout, getUserProfile, getUser, isAuthenticated } =
    useContext(AuthContext)

  const [profile, setProfile] = useState<Profile>()

  useEffect(() => {
    const loadProfileAsync = async () => {
      const profile = await getUserProfile()
      setProfile(profile)
    }
    if (!profile) {
      loadProfileAsync()
    }
  }, [])

  return { user: profile, logout, isAuthenticated, getUserProfile }
}

export default useAuth
