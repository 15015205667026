import { Box, Divider, ListItemButton, Card, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import React, { useContext, useEffect, useRef } from 'react'
import {
  LeagueEvent,
  normalizeRouteParam,
  useAppSelector
} from 'src/placebet/core'
import _ from 'lodash'
import { EventDetails, EventResults } from '../../../../models'
import Header from '../components/Header'
import Content from './Content'
import { CardActions, LabelInfo } from 'src/placebet/products/components/styled'

function EventResultsRow({
  event
}: {
  event: LeagueEvent<EventDetails, EventResults>
}) {
  const { t }: { t: any } = useTranslation()

  const {
    event: { id: selected }
  } = useAppSelector((state) => state.horseRaces)

  const elementRef = useRef(null)

  useEffect(() => {
    if (selected === event.id) {
      if (elementRef.current) {
        elementRef.current.focus()
      } else if (process.env.NODE_ENV !== 'production') {
        console.error(
          'MUI: Unable to set focus to a ListItemButton whose component has not been rendered.'
        )
      }
    }
  }, [selected])

  const isSelected = selected === event.id

  const { eventName: name, eventTime: time } = event

  return (
    <React.Fragment>
      <ListItemButton
        selected={isSelected}
        sx={{
          px: 0.25,
          py: 0.25
        }}
      >
        <Card square variant="outlined" sx={{ width: '100%' }}>
          <Box
            ref={elementRef}
            component={RouterLink}
            to={`?race=${normalizeRouteParam(event.eventName)}`}
            sx={{
              position: 'relative',
              textDecoration: 'none'
            }}
          >
            <Header name={name} time={time} selected={isSelected} />
            <CardActions>
              <Button
                sx={{ width: '100%' }}
                component={RouterLink}
                to={`${normalizeRouteParam(event.eventName)}`}
              >
                <LabelInfo
                  sx={{
                    borderRadius: 50
                  }}
                >
                  {t('DETALLES')}
                </LabelInfo>
              </Button>
            </CardActions>
          </Box>
          <Content results={event?.results} />
        </Card>
      </ListItemButton>
      <Divider />
    </React.Fragment>
  )
}

export default EventResultsRow
