import { Stack, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { WorkstationTransaction } from 'src/placebet/core/models'
import Header from '../components/Header'
import TicketListItem from '../../TicketsSidebarCore/TicketListItem'
import { ReceiptTypography } from '../components/styled'
import { formatAsCurrency } from 'src/placebet/core'

export const DashedDivider = styled('hr')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  border: `1px dashed #ccc`
}))

function TicketTransactionReceipt({
  transaction
}: {
  transaction: WorkstationTransaction
}) {
  const { t } = useTranslation()

  const {
    currency,
    amount,
    date,
    product,
    workstation,
    transaction: type,
    transactionEvent: { number, transactionType, surcharge }
  } = transaction

  const totalAmount = amount + surcharge || 0
  const amountTotal =
    transactionType === 'CANCELLATION' ? amount + surcharge || 0 : amount || 0
  return (
    <Stack
      display="flex"
      sx={{
        width: '100%'
      }}
      direction={'column'}
      padding={0}
      spacing={1}
    >
      <Header
        id={number}
        date={date}
        product={product}
        workstation={workstation}
      />

      <DashedDivider />
      <Stack
        display={'flex'}
        direction={'column'}
        spacing={0.5}
        sx={{ width: '100%', p: 0.2 }}
      >
        <TicketListItem
          fontSize={13}
          typographyColor={'black'}
          variant="h5"
          primary={
            <Stack spacing={1} direction={'row'}>
              <ReceiptTypography>
                {t(
                  `${transactionType === 'CANCELLATION' ? 'CANCELLED' : 'PAY'}`
                )}
              </ReceiptTypography>
              <ReceiptTypography>{`(${currency || 'DOP'})`}</ReceiptTypography>
            </Stack>
          }
          secondary={
            <ReceiptTypography>
              {formatAsCurrency(amountTotal || 0, 2)}
            </ReceiptTypography>
          }
        />
      </Stack>
    </Stack>
  )
}

export default TicketTransactionReceipt
