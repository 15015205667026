import _ from 'lodash'
import { SearchGraphqlType, GraphqlExpression } from '../models/core'

export const filterBuilder = (
  filter: string,
  columns: Array<string>
): string => {
  const query = columns
    .map((column) => {
      return `${column}.Contains("${filter}")`
    })
    .join(' || ')

  return `(${query})`
}

export function generateFilterGraphql(
  value: string | boolean | number,
  type: SearchGraphqlType
): GraphqlExpression {
  const eqFilter: GraphqlExpression = {
    [type]: value
  }
  return eqFilter
}
