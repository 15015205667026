import { Action, AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConnectionStatus, ProductEnum } from 'src/placebet/core'

import productApi from './productApi'
import cockfightsApi from '../../../products/cockfights/redux/cockfightsProductApi'
import horseRacesApi from '../../../products/horseraces/redux/horseRacesProductApi'
import {
  parseErrorResponse,
  transformDailySales
} from 'src/placebet/core/api/productApi/services'
import walletApi from 'src/placebet/core/api/walletApi'

import {
  LeagueEventsGroup,
  TicketTransactionEvent,
  UpcomingEvent,
  WorkstationSalesByCategory,
  WorkstationTransactionItem
} from 'src/placebet/core/api/productApi/models'
import {
  AnyTicket,
  LeagueEvent,
  WalletTransaction,
  WorkstationTransaction
} from '../../models'
import {
  EventDetails,
  EventResults
} from 'src/placebet/products/cockfights/models'

type PrinterContentRequest =
  | { product: ProductEnum; kind: 'SALE'; payload: AnyTicket[] }
  | {
      product: ProductEnum
      kind: 'CANCELATION' | 'PAYMENT'
      payload: WorkstationTransaction
    }
  | {
      kind: 'DRAW' | 'PAYDRAW'
      payload: WorkstationTransaction
    }
  | {
      kind: 'DEPOSIT' | 'WITHDRAWAL'
      payload: WalletTransaction
    }
  | {
      product: ProductEnum
      kind: 'SALES_SUMMARY'
      payload: WorkstationSalesByCategory
      date?: Date
    }
  | {
      product: ProductEnum
      kind: 'COCKFIGHTS_LEAGUE_EVENT_OPEN'
      payload: LeagueEvent<EventDetails, EventResults>[]
    }
  | {
      product: ProductEnum
      kind: 'COCKFIGHTS_LEAGUE_EVENT_RESULTS'
      payload: LeagueEvent<EventDetails, EventResults>[]
    }
  | {
      product: ProductEnum
      kind: 'TICKET_ACTIVITY'
      payload: WorkstationTransactionItem<TicketTransactionEvent>[]
    }
type AppProductState = {
  upcomingEvents: UpcomingEvent[]
  todayEvents: LeagueEventsGroup[]
  busy?: boolean
  error?: Error
  printContent?: PrinterContentRequest
  lastTransactionTime?: Date
  worstationTodaySales?: WorkstationSalesByCategory
  realtime: {
    lastUpdated?: Date
    hubError?: Error
    hubStatus?: ConnectionStatus
  }
}

const initialState: AppProductState = {
  upcomingEvents: [],
  todayEvents: [],
  realtime: {}
}

interface RejectedAction extends Action {
  error: unknown
  payload: unknown
  meta: unknown
}

export function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected')
}

export function isBusyAction(action: AnyAction): action is Action {
  return action.type.endsWith('pending')
}

export function isCompletedAction(action: AnyAction): action is Action {
  return action.type.endsWith('fulfilled')
}

const productsAppSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setUpcomingEvents: (state, action: PayloadAction<UpcomingEvent[]>) => {
      state.upcomingEvents = action.payload
    },
    updateUpcomingEvents: (
      state,
      action: PayloadAction<{ data: UpcomingEvent[]; lastUpdate: Date }>
    ) => {
      state.upcomingEvents = action.payload.data || []
      state.realtime.lastUpdated = action.payload.lastUpdate
    },
    updateTodayEvents: (
      state,
      action: PayloadAction<{ data: LeagueEventsGroup[]; lastUpdate: Date }>
    ) => {
      state.todayEvents = action.payload.data || []
      state.realtime.lastUpdated = action.payload.lastUpdate
    },
    updateRealtimeConnectionStatus: (
      state,
      action: PayloadAction<{ hubStatus: ConnectionStatus; hubError?: Error }>
    ) => {
      if (action.payload.hubError) {
        state.realtime.hubError = action.payload.hubError
      }
      state.realtime.hubStatus = action.payload.hubStatus
    },
    setPrintContent: (
      state,
      { payload }: PayloadAction<PrinterContentRequest>
    ) => {
      state.printContent = payload
    },
    clearPrintContent: (state) => {
      state.printContent = null
    },
    handleErrorMessage: (state, { payload }: PayloadAction<string>) => {
      state.error = new Error(payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isBusyAction, (state) => {
        state.busy = true
      })
      .addMatcher(isCompletedAction, (state) => {
        state.busy = false
      })
      .addMatcher(isRejectedAction, (state, action) => {
        const { meta } = action
        const error = parseErrorResponse(meta)
        if (error) {
          state.error = error
        }
        state.busy = false
      })
      .addMatcher(
        productApi.endpoints.getNextEvents.matchFulfilled,
        (state, { payload }) => {
          state.upcomingEvents = payload
          state.busy = false
        }
      )
      .addMatcher(
        productApi.endpoints.getTodayCalendar.matchFulfilled,
        (state, { payload }) => {
          state.todayEvents = payload
        }
      )
      // Cash Transactions
      .addMatcher(
        productApi.endpoints.dailySales.matchFulfilled,
        (state, { payload }) => {
          const { wallet, account, tickets } = payload
          state.worstationTodaySales = transformDailySales(
            account,
            tickets,
            wallet
          )
          state.busy = false
        }
      )
      .addMatcher(
        productApi.endpoints.draw.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            kind: 'DRAW',
            payload: payload
          } as PrinterContentRequest
        }
      )
      .addMatcher(
        productApi.endpoints.payDraw.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            kind: 'PAYDRAW',
            payload: payload
          } as PrinterContentRequest
        }
      )
      // Wallet Transactions
      .addMatcher(
        walletApi.endpoints.walletCompleteWithdrawal.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            kind: 'WITHDRAWAL',
            payload: payload
          } as PrinterContentRequest
          state.busy = false
        }
      )
      .addMatcher(
        walletApi.endpoints.walletAccountDeposit.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            kind: 'DEPOSIT',
            payload: payload
          } as PrinterContentRequest
          state.busy = false
        }
      )
      // Cockfights
      .addMatcher(
        cockfightsApi.endpoints.submitCockfightsTicket.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            product: 'COCKFIGHTS',
            kind: 'SALE',
            payload: [payload]
          } as PrinterContentRequest
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.cancelCockfightsTicket.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            product: 'COCKFIGHTS',
            kind: 'CANCELATION',
            payload: payload
          } as PrinterContentRequest
        }
      )
      .addMatcher(
        cockfightsApi.endpoints.payCockfightsTicket.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            product: 'COCKFIGHTS',
            kind: 'PAYMENT',
            payload: payload
          } as PrinterContentRequest
        }
      )
      // Horse Races
      .addMatcher(
        horseRacesApi.endpoints.submitHorseRacesTicket.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            product: 'HORSE_RACES',
            kind: 'SALE',
            payload: [payload]
          } as PrinterContentRequest
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.cancelHorseRacesTicket.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            product: 'HORSE_RACES',
            kind: 'CANCELATION',
            payload: payload
          } as PrinterContentRequest
        }
      )
      .addMatcher(
        horseRacesApi.endpoints.payHorseRacesTicket.matchFulfilled,
        (state, { payload }) => {
          state.printContent = {
            product: 'HORSE_RACES',
            kind: 'PAYMENT',
            payload: payload
          } as PrinterContentRequest
        }
      )
    // .addMatcher(
    //   horseRacesApi.endpoints.payHorseRacesTicket.matchRejected,
    //   (state, action) => {
    //     const { error, type } = action
    //
    //     state.error = parseErrorResponse(error)
    //   }
    // )
  }
})

export const {
  setUpcomingEvents,
  updateUpcomingEvents,
  updateTodayEvents,
  updateRealtimeConnectionStatus,
  handleErrorMessage,
  setPrintContent,
  clearPrintContent
} = productsAppSlice.actions

export default productsAppSlice
