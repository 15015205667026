import {
  useLazyDrawQuery,
  useLazyPayDrawQuery
} from 'src/placebet/core/api/productApi/productApi'
import KeyboardModalShortcutsCore from 'src/placebet/products/components/KeyboardModalShortcutsCore'
import {
  useCancelCockfightsTicketMutation,
  usePayCockfightsTicketMutation
} from '../../redux/cockfightsProductApi'

const KeyboardModalShortcuts = () => {
  const [payTicket] = usePayCockfightsTicketMutation()
  const [cancelTicket] = useCancelCockfightsTicketMutation()
  const [draw] = useLazyDrawQuery()
  const [payDraw] = useLazyPayDrawQuery()

  const handlePayTicket = (ticketNumber: string) => {
    payTicket({ ticketNumber, allowProviderTicket: true })
  }

  const handleCancelTicket = (ticketNumber: string) => {
    cancelTicket({ ticketNumber, allowProviderTicket: true })
  }

  const handleDraw = (amount: number) => {
    draw({ amount, product: 'COCKFIGHTS', currency: 'DOP' })
  }

  const handlePayDraw = (amount: number) => {
    payDraw({ amount, product: 'COCKFIGHTS', currency: 'DOP' })
  }

  return (
    <KeyboardModalShortcutsCore
      payTicket={handlePayTicket}
      cancelTicket={handleCancelTicket}
      drawCash={handleDraw}
      paybackCash={handlePayDraw}
    />
  )
}

export default KeyboardModalShortcuts
