import React from 'react'
import { AuthConsumer } from '../AuthProvider'
import { LoadingAnimation  } from '../../../components'

const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback()
      return <LoadingAnimation />
    }}
  </AuthConsumer>
)

export default SilentRenew
