import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import './placebet/i18n'
import ScrollTop from 'src/placebet/hooks/useScrollTop'

import 'nprogress/nprogress.css'

import App from 'src/placebet/App'
import * as serviceWorker from 'src/serviceWorker'
import { AuthProvider } from 'src/placebet/contexts/JWTAuthContext'
import React from 'react'

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ScrollTop />
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
