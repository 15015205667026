import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import React from 'react'
import { Columns, Dictionary } from 'src/placebet/core'
import cockfightsApi from 'src/placebet/products/cockfights/redux/cockfightsProductApi'

export type InputState = 'Wager' | 'Amount'

export interface Rules extends Dictionary {
  wagerMaximum: number
  wagerMinimum: number
}

export interface WagerPreviewState {
  disabled: boolean
  displayValue?: string
  command?: string
  wagerRequest?: any
  inputState?: InputState
  wagerAmount?: number
  wagerDefaultBet?: number
  error?: Error
  rules?: Rules
  key?: { value: string; nonce: number }
  columns?: Columns
}

const initialState: WagerPreviewState = {
  disabled: false,
  inputState: 'Wager',
  rules: {
    wagerMaximum: 20000,
    wagerMinimum: 20
  },
  wagerRequest: undefined,
  command: ''
}

const keyboardSubmitWager = (state: WagerPreviewState) => {
  const { command } = state

  try {
    // validate the wager
    // const wager = parseAndValidateWager(state)
    state.wagerRequest = {
      expression: command
    }
  } catch (error) {
    state.error = error
  }
}

const cockfightsWagerPreviewSlice = createSlice({
  name: 'cockfighsWagerPreview',
  initialState,
  reducers: {
    setKeyPressed: (
      state,
      { payload }: PayloadAction<{ key: string; mapped: boolean }>
    ) => {
      const {
        inputState,
        wagerDefaultBet,
        rules: { wagerMinimum, wagerMaximum }
      } = state

      const { key, mapped } = payload

      if (inputState === 'Amount') {
        switch (key) {
          case 'CE/$':
          case 'Enter':
          case 'Escape':
          case 'Cancel':
            {
              const currentWager = !!wagerDefaultBet
                ? wagerDefaultBet > wagerMaximum
                  ? wagerMaximum
                  : wagerDefaultBet < wagerMinimum
                  ? wagerMinimum
                  : wagerDefaultBet
                : wagerMinimum

              const updatedBet = currentWager

              state.wagerAmount = updatedBet
              state.wagerDefaultBet = currentWager
              state.inputState = 'Wager'
            }
            break
          case 'Backspace':
            {
              if (wagerDefaultBet) {
                const updatedBet =
                  Number.parseFloat(wagerDefaultBet.toString().slice(0, -1)) ||
                  undefined

                state.wagerDefaultBet = updatedBet
              }
            }
            break

          default:
            {
              const updatedBet = Number.parseFloat(
                (wagerDefaultBet || 0).toString() + key
              )

              if (!updatedBet) {
                state.error = new Error('SOLO_DIGITOS_PERMITIDOS_EN_MONTO')
              } else {
                state.wagerDefaultBet = updatedBet
                state.wagerAmount = updatedBet
              }
            }
            break
        }
      } else if (inputState === 'Wager') {
        switch (key) {
          case 'Enter':
            {
              if (state.command || state.command?.length > 0) {
                state.wagerRequest = {
                  expression: state.command
                }
              }
            }
            break

          case 'Backspace':
            {
              if (state.command) {
                const updated = state.command.slice(0, -1) || undefined
                state.command = updated
              }
              state.error = undefined
            }
            break

          case 'CE/$':
            {
              state.inputState = 'Amount'
              state.wagerDefaultBet = undefined
              state.wagerAmount = undefined
            }
            break

          case 'Cancel':
            break

          case 'CapsLock':
          case 'Tab':
            break

          default:
            {
              if (key.length > 1) {
                state.key = { value: key, nonce: Date.now() }
                return
              }

              const updated = (state.command || '') + key
              state.command = updated
              state.key = { value: key, nonce: Date.now() }
              state.error = undefined
            }
            break
        }
      }
    },
    setInputState: (state, { payload }: PayloadAction<InputState>) => {
      const { inputState } = state
      state.inputState = payload
      switch (inputState) {
        case 'Amount':
          break

        case 'Wager':
          {
            state.wagerAmount = undefined
            state.wagerDefaultBet = undefined
            state.inputState = payload
          }
          break
      }
    },
    setDisabled: (state, { payload }: PayloadAction<boolean>) => {
      state.disabled = payload
    },
    setResetCommand: (state, { payload }: PayloadAction<boolean>) => {
      state.command = ''
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      cockfightsApi.endpoints.submitCockfightsTicketWager.matchFulfilled,
      (state) => {
        state.command = ''
        state.inputState = 'Wager'
        state.wagerRequest = undefined
      }
    )
  }
})

export const { setKeyPressed, setInputState, setResetCommand } =
  cockfightsWagerPreviewSlice.actions
export default cockfightsWagerPreviewSlice
