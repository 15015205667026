import { Typography } from '@mui/material'
import { Suspense, lazy } from 'react'

import SuspenseLoader from 'src/components/SuspenseLoader'
import BaseLayout from '../BaseLayout'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const Leagues = Loader(lazy(() => import('../components/board/Leagues')))
const League = Loader(lazy(() => import('../components/board/League')))
const Event = Loader(lazy(() => import('../components/board/Event')))
const SalesResume = Loader(lazy(() => import('../../reports/SalesResume')))
const TicketManagement = Loader(
  lazy(() => import('../components/tickets/TicketManagement'))
)

const dashboardsRoutes = [
  {
    path: '',
    element: (
      <BaseLayout>
        <Leagues />
      </BaseLayout>
    )
  },
  {
    path: 'events',
    element: (
      <BaseLayout>
        <Leagues />
      </BaseLayout>
    )
  },
  {
    path: ':league',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <League />
      },

      {
        path: '/:event',
        element: <Event />
      }
    ]
  },
  {
    path: 'tickets',
    element: <BaseLayout />,
    children: [
      {
        path: '',
        element: <TicketManagement />
      }
    ]
  },
  {
    path: 'reports',
    element: <BaseLayout />,
    children: [
      {
        path: '/sales-resume',
        element: <SalesResume />
      }
      // {
      //   path: '/activities-history-detail',
      //   element: <ActivitiesHistoryDetail />
      // }
    ]
  }
]
export default dashboardsRoutes
