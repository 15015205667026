import {
  Backdrop as MuiBackdrop,
  CircularProgress,
  Fade,
  styled
} from '@mui/material'

const Backdrop = styled(MuiBackdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.primary.dark
}))

export const Busy = ({
  delay = 300,
  busy
}: {
  delay?: number
  busy: boolean
}) => {
  return (
    <Backdrop open={busy}>
      <Fade
        in={busy}
        style={{
          transitionDelay: busy ? `${delay}ms` : '0ms'
        }}
        unmountOnExit
      >
        <CircularProgress size={48} color="inherit" />
      </Fade>
    </Backdrop>
  )
}
