import { Box, List, Stack, styled } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { ProductEnum, formatAsCurrency } from 'src/placebet/core'
import { AnyTicket } from 'src/placebet/core/models'
import Header from '../components/Header'
import TicketListItem from '../../TicketsSidebarCore/TicketListItem'
import Footer from '../components/Footer'
import { ReceiptTypography } from '../components/styled'

export const DashedDivider = styled('hr')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  border: `1px dashed #ccc`
}))

function TicketReceipt({
  ticket,
  wagerCollectionComponent,
  preview
}: {
  ticket: AnyTicket
  wagerCollectionComponent: React.ReactNode
  preview?: boolean
}) {
  const { t } = useTranslation()

  const Component = wagerCollectionComponent as any

  const {
    number,
    date,
    product,
    workstation,
    currency,
    totalAmount,
    wagerCollections,
    payoff
  } = ticket

  const hasPayoff = payoff !== undefined && payoff > 0

  return (
    <Stack
      display="flex"
      sx={{
        width: '100%',
        p: 0
      }}
      direction={'column'}
      spacing={0}
    >
      <Header
        id={number}
        date={date}
        product={product}
        workstation={workstation}
      />

      <Box sx={{ p: 0 }}>
        <List disablePadding component="div">
          {wagerCollections.map((wc) => (
            <div key={wc.id}>
              <Component preview={preview} wagerCollection={wc} />
            </div>
          ))}
        </List>
      </Box>

      {wagerCollections.length > 1 && (
        <>
          <hr />
          <Box display={'flex'} sx={{ width: '100%', p: 0.2 }}>
            <TicketListItem
              fontSize={13}
              typographyColor={'black'}
              variant="h5"
              primary={
                <Stack spacing={1} direction={'row'}>
                  <ReceiptTypography>{t('APOSTADO')}</ReceiptTypography>
                  <ReceiptTypography>{`(${
                    currency || 'DOP'
                  })`}</ReceiptTypography>
                </Stack>
              }
              secondary={formatAsCurrency(totalAmount || 0, 2)}
            />
          </Box>
        </>
      )}
      {hasPayoff && (
        <Box display={'flex'} sx={{ width: '100%', p: 0.2 }}>
          <TicketListItem
            fontSize={13}
            typographyColor={'black'}
            variant="h5"
            primary={
              <Stack spacing={1} direction={'row'}>
                <ReceiptTypography>{t('PAYOFF')}</ReceiptTypography>
                <ReceiptTypography>{`(${
                  currency || 'DOP'
                })`}</ReceiptTypography>
              </Stack>
            }
            secondary={formatAsCurrency(payoff || 0, 2)}
          />
        </Box>
      )}

      <Footer
        showBardCode={true}
        identifier={number}
        disclaimer={t(getDisclaimerByProduct(product))}
      />
    </Stack>
  )
}

const getDisclaimerByProduct = (product: ProductEnum): string => {
  switch (product) {
    case 'HORSE_RACES':
      return 'HORSE_RACES_TICKET_ANULACION_DISCLAIMER'
    case 'COCKFIGHTS':
      return 'COCKFIGHTS_TICKET_ANULACION_DISCLAIMER'
    default:
      return 'DEFAULT_TICKET_ANULACION_DISCLAIMER'
  }
}

export default TicketReceipt
