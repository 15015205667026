import type { ReactNode } from 'react'

import AccountsIcon from '@mui/icons-material/AccountBalanceWalletTwoTone'
import ProductsIcon from '@mui/icons-material/AccountBalanceTwoTone'
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone'

export interface MenuItem {
  link?: string
  icon?: ReactNode
  badge?: string
  badgeTooltip?: string

  items?: MenuItem[]
  name: string
}

export interface MenuItems {
  items: MenuItem[]
  heading: string
}

const menuItems: MenuItems[] = [
  {
    heading: 'Wallet',
    items: [
      {
        name: 'Accounts',
        icon: AccountsIcon,
        link: '/customer/wallet',
        badgeTooltip: 'MY_ACCOUNTS'
      },
      {
        name: 'Products',
        icon: ProductsIcon,
        link: '/customer/wallet/products',
        badgeTooltip: 'PRODUCTS'
      }
    ]
  },
  // {
  //   heading: 'Foundation',
  //   items: [
  //     {
  //       name: 'Documentation',
  //       icon: SupportTwoToneIcon,
  //       link: '/docs'
  //     }
  //   ]
  // }
]

export default menuItems
