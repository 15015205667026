import { t } from 'i18next'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import {
  ConnectionStatus,
  Dictionary,
  EventStatus,
  LeagueEvent,
  formatStringAsShortTime,
  normalizeRouteParam,
  useAppDispatch,
  useAppSelector
} from 'src/placebet/core'
import { getStatusIcon } from '../../hooks/statusIconFactory'
import { MenuItem } from '../../models'

import {
  List,
  CardHeader,
  Divider,
  Stack,
  useTheme,
  Box,
  Tabs,
  Tab,
  styled,
  Grid,
  Paper
} from '@mui/material'
import { Card, MenuWrapper, SubMenuWrapper } from '../styles'
import SidebarMenuItem from '../item'
import {
  Heading,
  PulsatingIcon,
  TabsContainerWrapper
} from '../../components/styled'
import { SyncProblemTwoTone as SyncIcon } from '@mui/icons-material'
import KeyboardNavigation from './KeyboardNavigation'
import { CustomKeyboardModalInput } from 'src/placebet/products/components/ModalInputListener'
import { LeagueEventsGroup } from 'src/placebet/core/api/productApi/models'
import { closeSidebar } from 'src/placebet/core/appContext/appContextSlice'
import { useNavigate } from 'react-router'
import {
  EventDetails,
  EventResults,
  Runner
} from 'src/placebet/products/horseraces/models'

type CalendarEventFilter = 'Open' | 'Final' | 'All'
const renderSidebarMenuItems = ({
  items,
  path,
  handleNavigation
}: {
  items: MenuItem[]
  path: string
  handleNavigation: (link: string) => void
}): JSX.Element => (
  <SubMenuWrapper>
    <List component="div">
      {items.reduce(
        (ev, item) => reduceChildRoutes({ ev, item, path, handleNavigation }),
        []
      )}
    </List>
  </SubMenuWrapper>
)

const reduceChildRoutes = ({
  ev,
  path,
  item,
  handleNavigation
}: {
  ev: JSX.Element[]
  path: string
  item: MenuItem
  handleNavigation: (link: string) => void
}): Array<JSX.Element> => {
  if (item.items) {
    ev.push(
      <SidebarMenuItem
        key={item.id}
        uid={item.id}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        handleNavigation={handleNavigation}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
          handleNavigation
        })}
      </SidebarMenuItem>
    )
  } else {
    ev.push(
      <SidebarMenuItem
        key={item.id}
        uid={item.id}
        name={item.name}
        secondaryText={item.metadata['secondaryText']}
        link={item.link}
        badge={item.badge}
        badgeTooltip={item.badgeTooltip}
        icon={item.icon}
        handleNavigation={handleNavigation}
      />
    )
  }

  return ev
}

const CalendarEvents = ({
  calendarEvents,
  hubStatus
}: {
  calendarEvents: LeagueEventsGroup[]
  hubStatus: ConnectionStatus
}) => {
  const { appContext } = useAppSelector((state) => state)

  const { product } = appContext

  const { events } = useAppSelector((state) => state.horseRaces)

  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const theme = useTheme()

  const eventLabel = useMemo(() => {
    switch (product) {
      case 'HORSE_RACES':
        return `${t('CARRERA')} #`
      case 'COCKFIGHTS':
        return t('PELEA')
      default:
        return ''
    }
  }, [product])

  const eventParamName = useMemo(() => {
    switch (product) {
      case 'HORSE_RACES':
        return `race`
      case 'COCKFIGHTS':
        return 'match'
      default:
        return ''
    }
  }, [product])

  const [filter, setFilter] = useState<CalendarEventFilter>('Open')

  const getScratchedRunners = useCallback(
    (id: string, events: LeagueEvent<EventDetails, EventResults>[]) => {
      const event = events.find((event) => event.id === id)
      const runners = event ? event.details.runners : []

      if (runners.length > 0) {
        let ids = ''

        runners.forEach((runner: Runner) => {
          if (runner.scratch) {
            ids += `${runner.runnerId},`
          }
        })
        if (ids.length > 0) {
          ids = ids.slice(0, -1)
          return `${t('SRC')} ${ids}`
        }
      }
      return ''
    },
    [events]
  )

  const getLiveDetail = useCallback(
    (id: string, events: LeagueEvent<EventDetails, EventResults>[]) => {
      const event = events.find((event) => event.id === id)
      return event ? event?.details.live : ''
    },
    [events]
  )

  const { sections, leagueNavigationMap } = useMemo(() => {
    let leagueNavigationMap: Dictionary = {}

    const menuItems = calendarEvents
      .map((ce) => {
        const leagueGroup: MenuItem = {
          id: ce.league.id,
          name: `${ce.league.keyboardCode} - ${ce.league.name}`,
          link: `${normalizeRouteParam(ce.league.name)}`,
          items: ce.events
            .filter((e) => {
              return filter === 'All'
                ? true
                : e.eventStatus === (filter as EventStatus)
            })
            .map((e) => {
              const scratched =
                product === 'HORSE_RACES'
                  ? getScratchedRunners(e.id, events)
                  : ''

              const live =
                product === 'HORSE_RACES' ? getLiveDetail(e.id, events) : ''

              const leagueEvent: MenuItem = {
                id: e.id,
                name: (
                  <>
                    <Stack spacing={2} direction={'row'}>
                      <Grid
                        item
                        sx={{ textAlign: 'initial' }}
                      >{`${eventLabel} ${e.eventName}`}</Grid>
                      <Grid>{formatStringAsShortTime(e.eventTime)}</Grid>
                    </Stack>
                    <Grid
                      item
                      sx={{
                        textAlign: 'initial',
                        fontSize: 'smaller'
                      }}
                    >
                      {live} {scratched.length > 0 ? scratched : ''}
                    </Grid>
                  </>
                ),
                icon: getStatusIcon(e.eventStatus),
                link: `${normalizeRouteParam(
                  ce.league.name
                )}?${eventParamName}=${normalizeRouteParam(e.eventName)}`,
                metadata: {
                  secondaryText: '',
                  eventStatus: e.eventStatus
                }
              }
              return leagueEvent
            })
        }

        leagueNavigationMap = {
          ...leagueNavigationMap,
          [ce.league.keyboardCode]: ce.league.name
        }

        return leagueGroup
      })
      .filter((e) => e.items.length > 0)

    return {
      leagueNavigationMap,
      sections: [
        {
          name: 'todayEvents',
          heading: 'EVENTOS_DEL_DIA',
          //lastUpdated,
          items: menuItems
        }
      ]
    }
  }, [calendarEvents, filter, events])

  const pathAndQuery = useMemo(() => {
    let path = location.pathname.endsWith('/')
      ? location.pathname.slice(0, -1)
      : location.pathname

    return `${path}${location.search}`
  }, [location])

  const filters: CalendarEventFilter[] = ['Open', 'Final', 'All']

  const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
    setFilter(value as CalendarEventFilter)
  }

  const handleNavigation = (link: string) => {
    dispatch(closeSidebar())
    navigate(link)
  }

  return (
    <CustomKeyboardModalInput>
      <KeyboardNavigation map={leagueNavigationMap}>
        {sections.map((section) => {
          return (
            <Card key={section.name} elevation={0} square>
              <CardHeader
                title={
                  <>
                    <Stack direction={'row'} justifyContent={'space-between'}>
                      <Heading>
                        <b>{t(section.heading)}</b>
                      </Heading>
                      {hubStatus === 'Disconnected' && (
                        <PulsatingIcon sx={{ mr: 2 }}>
                          <SyncIcon
                            fontSize="small"
                            sx={{ color: theme.palette.error.light }}
                          />
                        </PulsatingIcon>
                      )}
                    </Stack>
                    <TabsContainerWrapper sx={{ px: 0, py: 0 }}>
                      <Tabs
                        onChange={handleTabsChange}
                        value={filter}
                        variant="scrollable"
                        scrollButtons="auto"
                        textColor="inherit"
                        indicatorColor="primary"
                      >
                        {filters.map((tab) => (
                          <Tab
                            sx={{ color: theme.palette.primary.contrastText }}
                            key={tab}
                            label={t(tab.toUpperCase())}
                            value={tab}
                          />
                        ))}
                      </Tabs>
                    </TabsContainerWrapper>
                  </>
                }
              />
              <Divider />
              <MenuWrapper>
                <List disablePadding component="div">
                  {renderSidebarMenuItems({
                    items: section.items,
                    path: pathAndQuery,
                    handleNavigation
                  })}
                </List>
              </MenuWrapper>
            </Card>
          )
        })}
      </KeyboardNavigation>
    </CustomKeyboardModalInput>
  )
}

export default CalendarEvents
