import { useEffect } from 'react'
import { useAppDispatch, useSignalR } from 'src/placebet/core'
import { BroadcastContent, LeagueEvent } from 'src/placebet/core/models'

import { EventResults, EventDetails } from '../models'
import {
  updateLeagueEvents,
  updateRealtimeConnectionStatus
} from './horseRacesProductSlice'

////

interface EventMap {
  [key: string]: LeagueEvent<EventDetails, EventResults>
}

export const useSubscribeToLeagueEventUpdates = (name: string) => {
  const dispatch = useAppDispatch()

  const {
    broadcastResults,
    error: hubError,
    status: hubStatus
  } = useSignalR<BroadcastContent<LeagueEvent<EventDetails, EventResults>[]>>(
    `${name}`
  )

  useEffect(() => {
    if (broadcastResults) {
      dispatch(
        updateLeagueEvents({
          data: broadcastResults?.content,
          lastUpdate: broadcastResults?.timeStamp
        })
      )
    }
  }, [broadcastResults])

  useEffect(() => {
    dispatch(updateRealtimeConnectionStatus({ hubStatus, hubError }))
  }, [hubError, hubStatus])

  return {
    hubError,
    hubStatus
  }
}
