import { gql } from '@apollo/client'

export const VERIFY_REGISTRATION = gql`
  query VerifyRegistration {
    verifyRegistration {
      registered
      workstation {
        id
        name
        agency {
          name
          enterprise {
            name
          }
        }
      }
    }
  }
`
