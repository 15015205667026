import { t } from 'i18next'
import { isArray, isNumber } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { formatAsCurrency, useAppSelector } from 'src/placebet/core'

const ErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    product: { error: product },
    horseRaces: {
      error: horseRaces,
      wagerPreview: { error: horseRacesWagerPreview }
    },
    cockfights: { error: cockfights },
    appContext: { error: appContext },
    ticketManagement: { error: ticketManagement }
  } = useAppSelector((state) => state)

  useEffect(() => {
    const error =
      product ||
      horseRaces ||
      cockfights ||
      appContext ||
      ticketManagement ||
      horseRacesWagerPreview
    if (error) {
      enqueueSnackbar(mapErrorMessage(error.message), { variant: 'error' })
    }
  }, [
    product,
    horseRaces,
    cockfights,
    appContext,
    horseRacesWagerPreview,
    ticketManagement
  ])

  const mapErrorMessage = (errorMessage: string) => {
    try {
      const splitMessage = errorMessage.split('|')
      if (isArray(splitMessage)) {
        let message = ''
        splitMessage.forEach((text) => {
          if (!isNaN(Number(text))) {
            message = `${message} ${formatAsCurrency(Number.parseFloat(text))}`
          } else {
            message = `${message} ${t(text)}`
          }
        })
        return message
      }
      return t(errorMessage)
    } catch (error) {
      return t(errorMessage)
    }
  }

  return <></>
}

export default ErrorHandler
