import Typography from '@mui/material/Typography'
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack
} from '@mui/material'

import MoreIcon from '@mui/icons-material/UnfoldMore'
import LessIcon from '@mui/icons-material/UnfoldLess'
import { useState } from 'react'
import TicketListItem from '../../../../../components/TicketsSidebarCore/TicketListItem'
import { formatAsCurrency } from 'src/placebet/core'

export default function WagerPreviewItem({
  displayValue,
  bet,
  wagerCombinations
}: {
  displayValue: string
  bet: number
  inputState: 'Legs' | 'Amount'
  wagerCombinations: string[]
}) {
  const [collapsed, setCollapsed] = useState<boolean>(true)

  const toggleCollapsed = () => setCollapsed(!collapsed)

  const WagerDisplay = () => {
    const legend =
      wagerCombinations.length > 1 ? `(${wagerCombinations.length})` : undefined

    return (
      <TicketListItem
        variant="subtitle2"
        primary={displayValue}
        secondary={
          <Typography fontSize={17}>
            {formatAsCurrency(
              (bet || 1) *
                (wagerCombinations?.length === 0 ? 1 : wagerCombinations.length)
            )}
          </Typography>
        }
        legend={legend}
      />
    )
  }

  const canExpand = wagerCombinations.length > 1

  return !canExpand ? (
    <Box sx={{ pr: 1, py: 1 }}>
      <WagerDisplay />
    </Box>
  ) : (
    <Button
      sx={{
        p: 0,
        m: 0,
        borderRadius: 0,
        width: '100%',
        '&:hover': {
          background: 'none'
        }
      }}
      onClick={toggleCollapsed}
      variant="text"
    >
      <Stack
        sx={{ p: 0, width: '100%' }}
        display={'flex'}
        direction={'row-reverse'}
        justifyItems={'left'}
        justifyContent={'left'}
      >
        <IconButton
          onClick={toggleCollapsed}
          sx={{
            p: 0.25,
            pr: 0,
            '&:hover': {
              background: 'none'
            }
          }}
        >
          {collapsed && <MoreIcon fontSize="small" />}
          {!collapsed && <LessIcon fontSize="small" />}
        </IconButton>
        <Box
          sx={{ py: 1, width: '100%' }}
          flexDirection={'column'}
          display={'flex'}
        >
          <WagerDisplay />
          <Collapse in={!collapsed} collapsedSize={0}>
            <Divider />
            <List
              disablePadding
              component="nav"
              aria-label="wager combinations"
            >
              {wagerCombinations.map((wc, i) => (
                <ListItem sx={{ pl: 0, p: 0.5 }} key={`wc-${i}`}>
                  <ListItemText
                    primary={
                      <Stack justifyContent="space-between" direction="row">
                        <Typography>{wc}</Typography>
                        <Typography>{formatAsCurrency(bet, 0)}</Typography>
                      </Stack>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      </Stack>
    </Button>
  )
}
