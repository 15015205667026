import { Card, Divider, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet-async'
import { ActivityHistoryDetailProvider } from './state'
import { QueryActivitiesHistoryDetail } from './components/QueryActivitiesHistoryDetail'
import { HeaderFilters } from './components/HeaderFilters'
import { ActivityTypeFilter } from './components/ActivityTypeFilter'
import ActivitiesTable from './components/ActivitiesTable'
import { Loader } from 'src/placebet/components'
import { useAppSelector } from 'src/placebet/core'

function ActivitiesHistoryDetail() {
  const { t }: { t: any } = useTranslation()
  const { workstation } = useAppSelector((state) => state.appContext)

  const isFetching = workstation === null

  return (
    <>
      <Helmet>
        <title>{t('ACTIVE_HISTORY_DETAIL_TITLE')}</title>
      </Helmet>
      <Loader loading={isFetching}>
        <Card>
          <ActivityHistoryDetailProvider>
            <Grid container spacing={2} p={2.5}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography gutterBottom variant="h4" mt={2}>
                  {t('ACTIVE_HISTORY_DETAIL_TITLE')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <HeaderFilters />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2} p={2.5}>
              <Grid item xs={12} md={12} lg={12}>
                <ActivityTypeFilter />
              </Grid>
            </Grid>

            <QueryActivitiesHistoryDetail>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <ActivitiesTable />
                </Grid>
              </Grid>
            </QueryActivitiesHistoryDetail>
          </ActivityHistoryDetailProvider>
        </Card>
      </Loader>
    </>
  )
}

export default ActivitiesHistoryDetail
