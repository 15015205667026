import { gql } from '@apollo/client'

export const ACCOUNT_TRANSACTION_RECEIPT_FIELDS = gql`
  fragment AccountTransactionFragment on AccountTransaction {
    id
    number
    amount
    balance
    currency
    kind
    status
    user {
      name
    }
    accountId
    account {
      name
      product
      number
      provider {
        name
      }
    }
    referenceId
    workstation {
      name
      agency {
        name
        enterprise {
          name
        }
        products {
          name
          code
        }
      }
    }
  }
`
export const BEGIN_WITHDRAWAL_RECEIPT_FIELDS = gql`
  fragment BeginWithdrawalTransactionFragment on AccountTransaction {
    id
    number
    amount
    balance
    kind
    createdDate
    completedDate
    currency
    status
    userId
    user {
      name
    }
    accountId
    account {
      name
    }
  }
`
export const ACCOUNT_TRANSACTION_FIELDS = gql`
  fragment AccountTransactionFieldsFragment on AccountTransaction {
    id
    number
    amount
    balance
    kind
    createdDate
    completedDate
    currency
    status
    userId
    user {
      name
    }
    accountId
    account {
      name
    }
    referenceId
    workstation {
      name
      agency {
        name
        enterprise {
          name
        }
        products {
          name
          code
        }
      }
    }
  }
`
