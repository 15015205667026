import React, { useMemo } from 'react'
import {
  List,
  styled,
  Grid,
  Box,
  Divider,
  Stack,
  Paper,
  Typography
} from '@mui/material'

import { useTranslation } from 'react-i18next'
import {
  formatAsCurrency,
  customFormatDate,
  decode,
  isWagerMultiRace
} from 'src/placebet/core'
import TicketListItem from '../../../../components/TicketsSidebarCore/TicketListItem'
import { DashedDivider } from 'src/placebet/products/components/recepits/TicketReceipt'
import { HorseRacesWagerCollection, WagerLeg } from '../../../models'
import { DisplayLegs } from 'src/placebet/products/components/recepits/TicketReceipt/DisplayLegs'
import { ReceiptTypography } from 'src/placebet/products/components/recepits/components/styled'

const MainWrapper = styled(Box)(
  ({ theme }) => `
          margin: ${theme.spacing(0)};
          border-radius: {theme.general.borderRadius};
          padding: ${theme.spacing(0.25)};
    `
)

const validTicketPattern = /^[A-Z0-9]{5}-[A-Z0-9]{5}-[A-Z0-9]{5}$/i

type ContentProps = {
  wagerCollection: HorseRacesWagerCollection
  preview?: boolean
}

function WagerCollection({ wagerCollection, preview }: ContentProps) {
  const { t } = useTranslation()
  const {
    providerTicketId,
    securityCode,
    providerError,
    wagers,
    wagerPayoff,
    total,
    surcharge,
    event
  } = wagerCollection

  const {
    league: { name: leagueName }
  } = event

  const ticketNumber = `${providerTicketId}-${decode(securityCode)}`

  return (
    <MainWrapper flexDirection={'column'}>
      <Box
        sx={{ py: 0.5 }}
        display="flex"
        alignItems="flex-start"
        flexDirection={'column'}
      >
        <ReceiptTypography variant="h4">{`${t('CARRERA')} ${
          wagerCollection.race
        }`}</ReceiptTypography>
        <ReceiptTypography variant="body2">{`${leagueName}`}</ReceiptTypography>
        {providerTicketId && providerTicketId !== 'null' && (
          <ReceiptTypography
            variant="subtitle1"
            display="inline"
            align="right"
            textTransform={'uppercase'}
          >
            {!!preview
              ? `${providerTicketId}-*****`
              : `${providerTicketId}-${decode(securityCode)}`}
          </ReceiptTypography>
        )}
        <Divider />
      </Box>

      <List sx={{ width: '100%' }} disablePadding>
        {wagers &&
          wagers.map((wager) => {
            const total =
              wager.bet * (wager.combine || 1) * wager.wagerCombinations.length

            const legend =
              wager.wagerCombinations.length > 1
                ? `(${wager.wagerCombinations.length})`
                : undefined

            const isMulti = isWagerMultiRace(wager.poolId)

            const renderLegs = (
              <DisplayLegs legs={wager.legs} isMulti={isMulti} />
            )

            return (
              <Box key={`wager-${wager.id}`} display={'flex'} sx={{ p: 0 }}>
                <TicketListItem
                  fontSize={13}
                  typographyColor="black"
                  variant="subtitle2"
                  primary={
                    <Stack direction={'column'}>
                      <Typography>
                        {[
                          t(wager.poolId),
                          t(wager.modifier !== 'NONE' ? wager.modifier : '')
                        ].join(' ')}
                        {legend}
                        {renderLegs}
                      </Typography>
                    </Stack>
                  }
                  secondary={formatAsCurrency(total, 2)}
                  primaryWidth={'80%'}
                />
              </Box>
            )
          })}
      </List>

      {!!wagerPayoff && (
        <>
          <DashedDivider />
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="subtitle1" color="primary" align="left">
                {wagerPayoff.payoff.sourceId}{' '}
                {customFormatDate(wagerPayoff.payoff.time)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle1" color="primary" ml={-2}>
                {`${t('PAYOFF')}: ${formatAsCurrency(
                  wagerPayoff.payoff.net || 0,
                  2
                )}`}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}

      {!validTicketPattern.test(ticketNumber) && (
        <>
          {!!providerError && !!providerError.message && (
            <div>
              <DashedDivider style={{ marginTop: 0, marginBottom: 0 }} />
              <Typography variant="body1" color="error">
                {`${t('ERROR')}: ${t(providerError.message)}`}
              </Typography>
            </div>
          )}
        </>
      )}

      <>
        <DashedDivider style={{ marginTop: 0, marginBottom: 0 }} />
        {surcharge > 0 && (
          <Box display={'flex'} sx={{ p: 0 }}>
            <TicketListItem
              fontSize={13}
              typographyColor="black"
              variant="subtitle2"
              primary={<Typography>{t('SURCHARGE') as string}</Typography>}
              secondary={formatAsCurrency(surcharge, 2)}
            />
          </Box>
        )}
        <Box display={'flex'} sx={{ py: 0.5 }}>
          <TicketListItem
            fontSize={13}
            typographyColor="black"
            variant="subtitle2"
            primary={<Typography>{t('TOTAL') as string}</Typography>}
            secondary={formatAsCurrency(total, 2)}
          />
        </Box>
      </>
    </MainWrapper>
  )
}

export default WagerCollection
