import { t } from 'i18next'
import { useSnackbar } from 'notistack'
import { useContext, useEffect } from 'react'
import { matchPath, useNavigate } from 'react-router'
import {
  Dictionary,
  normalizeRouteParam,
  routeParamAsProduct
} from 'src/placebet/core'
import { KeyboardMapperContext } from 'src/placebet/products/providers/CustomKeyboardProvider/state'

const getProductEventParam = (productParam: string) => {
  const product = routeParamAsProduct(productParam)

  switch (product) {
    case 'HORSE_RACES':
      return 'race'
    case 'COCKFIGHTS':
      return 'match'
    default:
      return 'event'
  }
}

const KeyboardNavigation = ({
  map,
  children
}: {
  map: Dictionary
  children: React.ReactNode
}) => {
  const {
    state: { value, modalCommand }
  } = useContext(KeyboardMapperContext)

  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  useEffect(() => {
    if (!modalCommand) {
      return
    }

    if (!value) {
      return
    }

    const getMatch = () => {
      const eventMatch = matchPath(
        {
          path: '/app/:product/:league/:eventId',
          end: true
        },
        location.pathname
      )

      const leagueMatch = matchPath(
        {
          path: '/app/:product/:league',
          end: true
        },
        location.pathname
      )

      const productMatch = matchPath(
        {
          path: '/app/:product',
          end: true
        },
        location.pathname
      )

      return eventMatch || leagueMatch || productMatch
    }

    const match = getMatch()
    switch (modalCommand) {
      case 'EVENTO':
        {
          if (match) {
            const { product } = match?.params as {
              product?: string
            }
            const option = value.padStart(2, '0')
            const to = map[option] as string
            if (to) {
              navigate(
                `/app/${product}/${normalizeRouteParam(map[option] as string)}`
              )
            } else {
              enqueueSnackbar(t('EVENTO_NO_EXISTE'), { variant: 'warning' })
            }
          }
        }
        break

      case 'CARRERA':
      case 'PELEA':
        {
          if (match) {
            const { product, league } = match?.params as {
              product?: string
              league?: string
            }

            if (!league) return

            const option = value.padStart(2, '0')
            navigate(
              `${match.pathname}?${getProductEventParam(product)}=${option}`
            )
          }
        }
        break
    }
  }, [value])

  return <>{children}</>
}

export default KeyboardNavigation
