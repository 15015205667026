import { colors } from '@mui/material'

export interface PositionColor {
  color?: string
  backgroundColor: string
}
interface ColorMap {
  [key: string]: PositionColor
}
export const positionColors: ColorMap = {
  position1: {
    color: '#fff',
    backgroundColor: '#D20001'
  },
  position2: {
    color: colors.grey[900],
    backgroundColor: '#F0F0F0'
  },
  position3: {
    color: '#fff',
    backgroundColor: '#2D2EFA'
  },
  position4: {
    color: colors.grey[900],
    backgroundColor: '#E8EA31'
  },
  position5: {
    color: '#fff',
    backgroundColor: '#008001'
  },
  position6: {
    color: colors.yellow.A100,
    backgroundColor: '#242424'
  },
  position7: {
    backgroundColor: '#FB581F'
  },
  position8: {
    color: colors.grey[900],
    backgroundColor: '#FBAABD'
  },
  position9: {
    backgroundColor: '#22C5C4'
  },
  position10: {
    color: '#fff',
    backgroundColor: '#8700CF'
  },
  position11: {
    color: '#fff',
    backgroundColor: '#8A8A8A'
  },
  position12: {
    color: colors.grey[900],
    backgroundColor: '#A2DBAE'
  },
  position13: {
    color: '#fff',
    backgroundColor: '#6C4735'
  },
  position14: {
    color: '#fff',
    backgroundColor: '#5D1231'
  },
  position15: {
    backgroundColor: '#C9C2BA'
  },
  position16: {
    color: '#fff',
    backgroundColor: '#888ABA'
  },
  position17: {
    color: '#fff',
    backgroundColor: '#2D3573'
  },
  position18: {
    backgroundColor: '#A98CFF'
  },
  position19: {
    backgroundColor: '#FFAD5B'
  },
  position20: {
    color: '#fff',
    backgroundColor: '#004F4F'
  },
  default: {
    color: colors.deepOrange[800],
    backgroundColor: colors.grey[200]
  }
}

export function GetColorScheme(position: number) {
  const colorScheme: PositionColor =
    positionColors[`position${position}`] ?? positionColors['default']

  return colorScheme
}
