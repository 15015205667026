import { useEffect } from 'react'
import {
  getSecondsAsTimeString,
  getTimeRemainingUntil
} from 'src/placebet/core'
import useCountDownTimer from './useCountDownTimer'

export const CountDownTimer = ({
  expiration,
  warnAt = 60,
  onCountDownCompleted
}: {
  onCountDownCompleted: () => void
  expiration: Date
  warnAt?: number
}) => {
  const {
    state: { timeRemaining, warning, completed },
    reset
  } = useCountDownTimer(
    Math.abs(Math.ceil(getTimeRemainingUntil(expiration))),
    warnAt
  )

  useEffect(() => {
    if (timeRemaining <= 0 && completed) {
      onCountDownCompleted()
    }
  }, [timeRemaining, completed])

  if (!warning) {
    return <></>
  }

  return <>{getSecondsAsTimeString(timeRemaining)}</>
}
