import productApi from '../../api/productApi'
import { VerifyRegistrationResponse } from './models'
import { VERIFY_REGISTRATION } from './queries'

export const appContextApi = productApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyRegistration: builder.query<VerifyRegistrationResponse, {}>({
      query: () => ({
        document: VERIFY_REGISTRATION,
        variables: {}
      }),
      transformResponse: (response: {
        verifyRegistration: VerifyRegistrationResponse
      }) => response.verifyRegistration
    })
  })
})

export const { useVerifyRegistrationQuery } = appContextApi
export default appContextApi
