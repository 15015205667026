import React from 'react'
import { AuthConsumer } from '../AuthProvider'
import { LoadingAnimation  } from '../../../components'


const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback()
      return <LoadingAnimation />
    }}
  </AuthConsumer>
)

export default Callback
