import { Grid, Stack, styled } from '@mui/material'
import {
  LeagueEvent,
  currencyFormatter,
  formatAsCurrency,
  formatShortTime
} from 'src/placebet/core'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import React from 'react'
import InfoHeader from './InfoHeader'
import { TruncateText } from 'src/placebet/components'
import Header from 'src/placebet/products/components/recepits/components/Header'
import { DashedDivider } from 'src/placebet/products/components/recepits/TicketReceipt'
import { ReceiptTypography } from 'src/placebet/products/components/recepits/components/styled'

export const LineDivider = styled('hr')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  border: `0.5px solid #ccc`
}))

const TicketTransactionsReceipt = ({ items }) => {
  const { t }: { t: any } = useTranslation()

  const count = items.length
  const transactionEvent = count > 0 ? items[0].transactionEvent : null
  const grandTotal = items.reduce(
    (accumulator, current) => accumulator + current.amount,
    0
  )

  return (
    <>
      <Stack
        display="flex"
        sx={{
          width: '100%',
          p: 1
        }}
        direction={'column'}
        spacing={0}
      >
        <InfoHeader />

        <DashedDivider />
      </Stack>

      <Grid container spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        <Grid item xs={10}>
          <ReceiptTypography
            variant="caption"
            noWrap
            sx={{ textTransform: 'none' }}
          >
            {t(
              transactionEvent && transactionEvent.transactionType === 'PAYMENT'
                ? 'TICKETS_PAGOS'
                : transactionEvent &&
                  transactionEvent.transactionType === 'CANCELLATION'
                ? 'TICKETS_CANCELADOS'
                : 'TICKETS'
            )}{' '}
            ({count})
          </ReceiptTypography>
        </Grid>

        <Grid item xs={2} textAlign={'right'}>
          <ReceiptTypography
            variant="caption"
            noWrap
            sx={{ textTransform: 'none', fontWeight: 'bold', marginRight: 2 }}
          >
            {formatAsCurrency(grandTotal)}
          </ReceiptTypography>
        </Grid>
      </Grid>
      <LineDivider />

      <Grid container spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        <Grid item xs={3}>
          <ReceiptTypography>{t('HORA')}</ReceiptTypography>
        </Grid>

        <Grid item xs={6}>
          <ReceiptTypography>{t('TICKET')}</ReceiptTypography>
        </Grid>

        <Grid item xs={3} textAlign={'right'}>
          <ReceiptTypography>{t('MONTO')}</ReceiptTypography>
        </Grid>
      </Grid>
      {items.map((item, key) => {
        return (
          <Grid
            container
            spacing={1}
            key={key}
            sx={{ paddingLeft: 1, paddingRight: 1 }}
          >
            <Grid item xs={3}>
              <ReceiptTypography variant="caption" noWrap>
                {formatShortTime(item.date)}
              </ReceiptTypography>
            </Grid>

            <Grid item xs={6}>
              <ReceiptTypography variant="caption" noWrap>
                {item.number}
              </ReceiptTypography>
            </Grid>

            <Grid item xs={3} textAlign={'right'}>
              <ReceiptTypography>
                {formatAsCurrency(item.amount)}
              </ReceiptTypography>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}

export default TicketTransactionsReceipt
