import {
  Box,
  Divider,
  LinearProgress,
  List,
  styled,
  TextField,
  Typography
} from '@mui/material'
import { useMemo } from 'react'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  formatAsCurrency,
  useAppDispatch,
  useAppSelector
} from 'src/placebet/core'
import { KeyboardMapperContext } from 'src/placebet/products/providers/CustomKeyboardProvider/state'
import { HorseRacesWagerCollection, Leg } from '../../../../models'

import WagerPreviewItem from './WagerPreviewItem'
import SalesTotal from '../SalesTotal'
import {
  useRemoveHorseRacesTicketMutation,
  useRemoveHorseRacesWagerMutation,
  useSubmitHorseRacesTicketMutation,
  useSubmitHorseRacesTicketWagerMutation
} from 'src/placebet/products/horseraces/redux/horseRacesProductApi'
import {
  clearSalesTotal,
  setDisabled,
  setInputState,
  setKeyPressed
} from 'src/placebet/products/horseraces/redux/horseRacesProductSlice'

const PoolWagerAmountView = styled(Typography)(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 'bold',
  input: { textAlign: 'right' },
  marginBottom: 1,
  color: theme.colors.success.dark
}))

function WagerPreview() {
  const { t } = useTranslation()
  const {
    state: { mode }
  } = useContext(KeyboardMapperContext)

  const dispatch = useAppDispatch()

  const [submitWager] = useSubmitHorseRacesTicketWagerMutation()
  const [removeTicket] = useRemoveHorseRacesTicketMutation()
  const [removeWager] = useRemoveHorseRacesWagerMutation()
  const [submitTicket] = useSubmitHorseRacesTicketMutation()

  const {
    horseRaces: {
      ticket: currentTicket,
      event: {
        eventId,
        details: { raceId }
      },
      busy: loading,
      pool,
      wagerPreview: {
        key,
        wagerCombinations,
        bet,
        wagerDefaultBet,
        inputState,
        legs,
        modifier,
        wagerRequest
      }
    }
  } = useAppSelector((state) => state)

  useEffect(() => {
    window.addEventListener('mappedKeyPressed', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('mappedKeyPressed', downHandler)
    }
  }, [])

  useEffect(() => {
    if (wagerRequest) {
      submitWager({
        request: { ...wagerRequest, ticketId: currentTicket?.id, eventId }
      })
    }
  }, [wagerRequest])

  useEffect(() => {
    dispatch(setDisabled(mode === 'ModalInput' || loading))
  }, [mode, loading])

  useEffect(() => {
    switch (key?.value) {
      case 'Print':
        {
          if (currentTicket && currentTicket?.wagerCollections.length > 0) {
            submitTicket({ ticketId: currentTicket?.id })
          }
        }
        break

      case 'CE/T':
        {
          removeTicket({ ticketId: currentTicket?.id })
        }
        break

      case 'CE/A':
        {
          const wagers = currentTicket?.wagerCollections
          if (!wagers) {
            return
          }
          const wc = [...wagers].pop()
          if (!wc) {
            return
          }

          const lastWager = [...wc.wagers].pop()
          if (lastWager) {
            removeWager({ ticketId: currentTicket?.id, wagerId: lastWager.id })
          }
        }
        break

      case 'RPT':
        {
          if (currentTicket) {
            const wc = [
              ...currentTicket?.wagerCollections
            ].pop() as HorseRacesWagerCollection
            const lastWager = [...wc.wagers].pop()
            if (lastWager) {
              const { poolId, race, modifier, legs: wagerLegs } = lastWager

              const legs = wagerLegs.map((p) => {
                const raceId = p.raceId
                const runners = p.runners.map((r) => r.runnerId as number)
                return { raceId, runners } as Leg
              })

              const request = {
                ticketId: currentTicket.id,
                typeBet: poolId,
                race: race,
                quick: false,
                bet,
                eventId,
                modifier: modifier,
                legs: legs
              }

              submitWager({ request })
            }
          }
        }
        break

      case 'Quick':
        {
          const request = {
            typeBet: pool?.poolId || 'WIN',
            race: raceId,
            quick: true,
            bet,
            eventId,
            modifier,
            legs: []
          }

          submitWager({ request })
        }
        break

      case 'CCST':
        {
          dispatch(clearSalesTotal())
        }
        break
      default:
        break
    }
  }, [key])

  const downHandler = (event: any) => {
    const payload = event.detail as {
      key: string
      mapped: boolean
    }

    if (mode !== 'Standard') {
      return
    }

    dispatch(setKeyPressed(payload.key))
  }

  const handleBegingEdit = () => {
    dispatch(setInputState('Amount'))
  }

  if (!pool) {
    return <div />
  }

  const displayValue = useMemo(() => {
    var result = Object.keys(legs)
      .map((k) => legs[k].runners.join(','))
      .join('/')

    return modifier && modifier !== 'NONE'
      ? `${pool?.poolId} ${modifier} ${result}`
      : `${pool?.poolId} ${result}`
  }, [legs, modifier, pool])

  return (
    <>
      <SalesTotal />
      <List sx={{ width: '100%', minHeight: '48px' }} disablePadding>
        <Box
          sx={{ pr: 1, pl: 0, height: '4rem', justifyContent: 'center' }}
          display={'flex'}
          flexDirection="column"
        >
          <Box display={'flex'} flexDirection={'row-reverse'}>
            <>
              {inputState === 'Legs' && (
                <PoolWagerAmountView onClick={handleBegingEdit}>
                  {formatAsCurrency(wagerDefaultBet || 1)}
                </PoolWagerAmountView>
              )}
              {inputState === 'Amount' && (
                <TextField
                  id="filled-read-only-input"
                  label={t('MONTO_POR_DEFECTO_APUESTAS')}
                  value={formatAsCurrency(wagerDefaultBet || 1)}
                  sx={{ input: { textAlign: 'right', fontSize: '18px' } }}
                  InputProps={{
                    readOnly: true
                  }}
                  variant="standard"
                />
              )}
            </>
          </Box>
        </Box>
        <WagerPreviewItem
          displayValue={displayValue}
          bet={(bet || 1) * (pool?.combine || 1)}
          inputState={inputState}
          wagerCombinations={wagerCombinations || []}
        />

        {loading && <LinearProgress />}
      </List>

      <Divider />
    </>
  )
}

export default WagerPreview
