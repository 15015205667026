import { AnyTicket } from 'src/placebet/core/models'
import HorseRacesWagerCollection from 'src/placebet/products/horseraces/components/tickets/WagerCollection'
import CockfightsWagerCollection from 'src/placebet/products/cockfights/components/tickets/WagerCollection'

import { Box, List, ListItem } from '@mui/material'
import TicketReceipt from '../TicketReceipt'

const TicketReceipts = ({
  tickets,
  component
}: {
  tickets: AnyTicket[]
  component: React.ReactNode
}) => {
  return (
    <>
      <List sx={{ width: '100%', m: 0 }} disablePadding>
        {tickets.map((ticket, i) => (
          <ListItem key={ticket.number} sx={{ px: 1, py: 2 }}>
            <Box
              display="flex"
              sx={{ p: 0, m: 0, width: '100%' }}
              key={ticket.id}
            >
              {i !== 0 && <hr />}
              <TicketReceipt
                wagerCollectionComponent={component}
                ticket={ticket}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    </>
  )
}

export const HorseRacesTicketReceipt = ({
  tickets
}: {
  tickets: AnyTicket[]
}) => {
  return (
    <TicketReceipts tickets={tickets} component={HorseRacesWagerCollection} />
  )
}

export const CockfightsTicketReceipt = ({
  tickets
}: {
  tickets: AnyTicket[]
}) => {
  return (
    <TicketReceipts tickets={tickets} component={CockfightsWagerCollection} />
  )
}
