import { Grid } from '@mui/material'
import { ReceiptTypography } from 'src/placebet/products/components/recepits/components/styled'

export const HeaderReceipt = () => {
  return (
    <Grid container spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
      <Grid item xs={1}>
        <ReceiptTypography
          variant="caption"
          noWrap
          sx={{ textTransform: 'none' }}
        >
          BL
        </ReceiptTypography>
      </Grid>
      <Grid item xs={1}>
        <ReceiptTypography
          variant="caption"
          noWrap
          sx={{ textTransform: 'none' }}
        >
          PL
        </ReceiptTypography>
      </Grid>
      <Grid item xs={6}>
        <ReceiptTypography
          variant="caption"
          noWrap
          sx={{ textTransform: 'none' }}
        >
          TRABA
        </ReceiptTypography>
      </Grid>
      <Grid item xs={2}>
        <ReceiptTypography
          variant="caption"
          noWrap
          sx={{ textTransform: 'none' }}
        >
          GANA
        </ReceiptTypography>
      </Grid>
      <Grid item xs={2}>
        <ReceiptTypography
          variant="caption"
          noWrap
          sx={{ textTransform: 'none' }}
        >
          TIEMPO
        </ReceiptTypography>
      </Grid>
    </Grid>
  )
}
