import { Avatar, Paper, useTheme } from '@mui/material'
import { CustomTypography } from 'src/placebet/components/Typography'
import { GetColorScheme, PositionColor, positionColors } from './PositionColor'

export function RunnerPosition({
  runnerId,
  entryId,
  scratched = false,
  variant = 'default'
}: {
  runnerId: number
  entryId: string
  scratched?: boolean
  variant?: 'default' | 'small'
}) {
  const theme = useTheme()

  const colorScheme = GetColorScheme(runnerId)
  const color = colorScheme.color ?? theme.palette.text.primary
  const backgroundColor = colorScheme.backgroundColor

  return variant === 'small' ? (
    <Paper
      sx={{
        background: backgroundColor,
        color: color,
        p: 0.25,
        m: 0.25,
        width: 24,
        height: '100%',
        boxShadow: `0 .113rem .5rem ${theme.colors.alpha.black[10]}, 0 .126rem .225rem ${theme.colors.alpha.black[30]}`
      }}
      square
      elevation={0}
    >
      <CustomTypography
        sx={{
          color: color
        }}
        variant="h5"
        align="center"
        scratched={!!scratched}
      >
        <strong>{entryId}</strong>
      </CustomTypography>
    </Paper>
  ) : (
    <Paper
      sx={{
        background: backgroundColor,
        color: color,
        p: 0.5,
        m: 0.5,
        width: 50,
        height: '100%',
        boxShadow: `0 .113rem .5rem ${theme.colors.alpha.black[10]}, 0 .126rem .225rem ${theme.colors.alpha.black[30]}`
      }}
      square
      elevation={0}
    >
      <CustomTypography
        sx={{
          color: color
        }}
        variant="h3"
        align="center"
        scratched={!!scratched}
        gutterBottom
      >
        <strong>{entryId}</strong>
      </CustomTypography>
    </Paper>
  )
}
