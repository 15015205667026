import {
  Divider,
  IconButton,
  Stack,
  Typography as MuiTypography,
  styled
} from '@mui/material'
import { t } from 'i18next'

import {
  formatAsCurrency,
  useAppDispatch,
  useAppSelector
} from 'src/placebet/core'

import ResetIcon from '@mui/icons-material/RestartAlt'
import { clearSalesTotal } from 'src/placebet/products/horseraces/redux/horseRacesProductSlice'

const Typography = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.primary.dark,
  textTransform: 'none'
}))

const SalesTotal = () => {
  const {
    boardWagerRequest: { salesTotal }
  } = useAppSelector((state) => state.horseRaces)

  const dispatch = useAppDispatch()

  return (
    <>
      <Stack direction="row" display="flex" justifyContent="space-between">
        <Typography variant="caption" gutterBottom>
          {t('SALES_TOTAL')}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Typography variant="caption" gutterBottom>
            <strong>{formatAsCurrency(salesTotal || 0)}</strong>
          </Typography>
          <IconButton
            sx={{ p: 0, m: 0, transform: 'translateY(-4px)' }}
            onClick={() => dispatch(clearSalesTotal())}
          >
            <ResetIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
    </>
  )
}

export default SalesTotal
