import { gql } from '@apollo/client'
import { WORKSTATION_TRANSACTION_RECEIPT } from './fragments'

export const VERIFY_REGISTRATION = gql`
  query VerifyRegistration {
    verifyRegistration {
      registered
      workstation {
        id
        name
        agency {
          name
          enterprise {
            name
          }
        }
      }
    }
  }
`
export const GET_UPCOMING_EVENTS = gql`
  query UpcomingEvents($product: ProductEnum!) {
    upcomingEvents(product: $product) {
      id
      name
      date
      league
    }
  }
`
export const GET_TODAY_EVENTS = gql`
  query GetTodayEvents($product: ProductEnum!) {
    calendarEvents(product: $product) {
      league {
        id
        name
        abbreviation
        keyboardCode
        live
      }
      events {
        eventName
        eventDate
        eventStatus
        eventTime
        id
      }
    }
  }
`
export const DRAW = gql`
  ${WORKSTATION_TRANSACTION_RECEIPT}
  mutation Draw($requestInput: WorkstationTransactionRequestInput!) {
    draw(requestInput: $requestInput) {
      ...WorkstationTransactionFragment
    }
  }
`
export const PAY_DRAW = gql`
  ${WORKSTATION_TRANSACTION_RECEIPT}
  mutation PayDraw($requestInput: WorkstationTransactionRequestInput!) {
    payDraw(requestInput: $requestInput) {
      ...WorkstationTransactionFragment
    }
  }
`
export const WORKSTATION_DAILY_SALES = gql`
  query WorkstationTodaySales($product: ProductEnum!, $referenceId: String!, $from: DateTime, $to: DateTime) {
    workstationAccountTransactionsSummary(product: $product, from: $from, to: $to) {
      key {
        currency
        category
        date
        id
        workstation {
          id
          name
          agency {
            name
            enterprise {
              name
            }
            products {
              name
              code
            }
          }
        }
        product
        transactionType
      }
      amount
      count
    }
    workstationTicketTransactionsSummary(product: $product, from: $from, to: $to) {
      key {
        currency
        category
        date
        id
        product
        transactionType
        workstation {
          id
          name
          agency {
            name
            enterprise {
              name
            }
            products {
              name
              code
            }
          }
        }
      }
      amount
      commission
      surcharge
      count
    }
    workstationWalletTransactionsSummary(referenceId: $referenceId, from: $from, to: $to) {
      key {
        id
        currency
        transactionType
        date
      }
      amount
      count
    }
  }
`
export const WORKSTATION_BALANCE = gql`
  query WorkstationBalance(
    $product: ProductEnum!
    $currency: CurrencyType!
    $referenceId: String!
  ) {
    workstationBalance(product: $product) {
      balance
    }
    workstationWalletBalance(currency: $currency, referenceId: $referenceId) {
      balance
    }
  }
`
export const WORKSTATION_LATEST_TICKET_TRANSACTIONS = gql`
  query WorkstationLatestTicketTransactions(
    $product: ProductEnum!
    $skip: Int
    $take: Int
  ) {
    workstationLatestTicketTransactions(
      product: $product
      skip: $skip
      take: $take
    ) {
      items {
        number
        date
        offsetDate
        transaction
        currency
        amount
        transactionEvent {
          number
          transactionType
          surcharge
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`
export const WORKSTATION_LATEST_CASH_TRANSACTIONS = gql`
  query WorkstationLatestCashTransactions(
    $product: ProductEnum!
    $skip: Int
    $take: Int
  ) {
    workstationLatestCashTransactions(
      product: $product
      skip: $skip
      take: $take
    ) {
      items {
        number
        date
        offsetDate
        transaction
        currency
        amount
        transactionEvent {
          transactionType
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`
export const WORKSTATION_LATEST_WALLET_TRANSACTIONS = gql`
  query WorkstationLatestWalletTransactions(
    $workstationId: String
    $skip: Int
    $take: Int
  ) {
    workstationTodayTransactions(
      workstationId: $workstationId
      skip: $skip
      take: $take
    ) {
      items {
        number
        completedDate
        offsetDate
        kind
        currency
        amount
        status
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
    }
  }
`
