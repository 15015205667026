import Barcode from 'react-barcode'
import { Box, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DashedDivider } from '../../TicketReceipt'

export interface Props {
  identifier?: string
  disclaimer?: string
  showBardCode?: boolean
}

const Paragraph = styled(Typography)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  fontSize: theme.typography.pxToRem(13),
  color: '#000000'
}))

const Footer = ({ identifier, disclaimer, showBardCode }: Props) => {
  const { t } = useTranslation()

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <DashedDivider />
      {!!disclaimer && (
        <Paragraph paragraph variant="body2">
          {t(disclaimer)}
        </Paragraph>
      )}

      {showBardCode && (
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent="center"
          marginTop={-0.5}
        >
          <Barcode
            format="CODE128"
            value={identifier || '0'}
            height={36}
            width={1.5}
            fontSize={12}
          />
        </Box>
      )}
    </Box>
  )
}

export default Footer
