import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import { getRtkApolloClient, ProductEnum } from 'src/placebet/core'
import {
  CurrencyTypeEnum,
  QueryResults,
  WalletTransaction,
  WorkstationTransaction
} from '../../models'
import {
  CashTransactionEvent,
  CashTransactionsSummary,
  LeagueEventsGroup,
  TicketTransactionEvent,
  TicketTransactionsSummary,
  UpcomingEvent,
  WalletTransactionsSummary,
  WorkstationTransactionItem,
  WorkstationWalletTransactions
} from './models'
import {
  DRAW,
  GET_TODAY_EVENTS,
  GET_UPCOMING_EVENTS,
  PAY_DRAW,
  WORKSTATION_BALANCE,
  WORKSTATION_DAILY_SALES,
  WORKSTATION_LATEST_CASH_TRANSACTIONS,
  WORKSTATION_LATEST_TICKET_TRANSACTIONS,
  WORKSTATION_LATEST_WALLET_TRANSACTIONS
} from './queries'

interface TodaySalesData {
  workstationAccountTransactionsSummary: CashTransactionsSummary[]
  workstationTicketTransactionsSummary: TicketTransactionsSummary[]
  workstationWalletTransactionsSummary: WalletTransactionsSummary[]
}

interface WorkstationSalesSummary {
  account: CashTransactionsSummary[]
  tickets: TicketTransactionsSummary[]
  wallet: WalletTransactionsSummary[]
}

const client = getRtkApolloClient()

export const productApi = createApi({
  reducerPath: 'productApi',
  baseQuery: graphqlRequestBaseQuery({
    client
  }),
  endpoints: (builder) => ({
    getNextEvents: builder.query<UpcomingEvent[], { product: ProductEnum }>({
      query: ({ product }) => ({
        document: GET_UPCOMING_EVENTS,
        variables: {
          product
        }
      }),
      transformResponse: (response: { upcomingEvents: UpcomingEvent[] }) =>
        response.upcomingEvents
    }),
    getTodayCalendar: builder.query<
      LeagueEventsGroup[],
      { product: ProductEnum }
    >({
      query: ({ product }) => ({
        document: GET_TODAY_EVENTS,
        variables: {
          product
        }
      }),
      transformResponse: (response: { calendarEvents: LeagueEventsGroup[] }) =>
        response.calendarEvents
    }),
    latestTicketTransactions: builder.query<
      QueryResults<WorkstationTransactionItem<TicketTransactionEvent>>,
      { product: ProductEnum; skip: number; take: number }
    >({
      query: ({ product, skip, take }) => ({
        document: WORKSTATION_LATEST_TICKET_TRANSACTIONS,
        variables: {
          product,
          skip,
          take
        }
      }),
      transformResponse: (response: {
        workstationLatestTicketTransactions: QueryResults<
          WorkstationTransactionItem<TicketTransactionEvent>
        >
      }) => response.workstationLatestTicketTransactions
    }),
    latestCashTransactions: builder.query<
      QueryResults<WorkstationTransactionItem<CashTransactionEvent>>,
      { product: ProductEnum; skip: number; take: number }
    >({
      query: ({ product, skip, take }) => ({
        document: WORKSTATION_LATEST_CASH_TRANSACTIONS,
        variables: {
          product,
          skip,
          take
        }
      }),
      transformResponse: (response: {
        workstationLatestCashTransactions: QueryResults<
          WorkstationTransactionItem<CashTransactionEvent>
        >
      }) => response.workstationLatestCashTransactions
    }),
    latestWalletTransactions: builder.query<
      QueryResults<WalletTransaction>,
      { workstationId: string; skip: number; take: number }
    >({
      query: ({ workstationId, skip, take }) => ({
        document: WORKSTATION_LATEST_WALLET_TRANSACTIONS,
        variables: {
          workstationId,
          skip,
          take
        }
      }),
      transformResponse: (response: {
        workstationTodayTransactions: QueryResults<WalletTransaction>
      }) => response.workstationTodayTransactions
    }),
    dailySales: builder.query<
      WorkstationSalesSummary,
      { product: ProductEnum; referenceId: string, from: string, to: string}
    >({
      query: ({ product, referenceId, from, to }) => ({
        document: WORKSTATION_DAILY_SALES,
        variables: {
          product,
          referenceId,
          from,
          to
        }
      }),
      transformResponse: (response: TodaySalesData) => {
        return {
          account: response.workstationAccountTransactionsSummary || [],
          tickets: response.workstationTicketTransactionsSummary || [],
          wallet: response.workstationWalletTransactionsSummary || []
        }
      }
    }),
    workstationBalance: builder.query<
      number,
      { product: ProductEnum; currency: CurrencyTypeEnum; referenceId: string }
    >({
      query: ({ product, currency, referenceId }) => ({
        document: WORKSTATION_BALANCE,
        variables: {
          product,
          currency,
          referenceId
        }
      }),
      transformResponse: (response: {
        workstationBalance: { balance: number }
        workstationWalletBalance: { balance: number }
      }) => {
        const balance =
          (response.workstationBalance?.balance || 0) +
          (response.workstationWalletBalance?.balance || 0)

        return balance
      }
    }),
    draw: builder.query<
      WorkstationTransaction,
      { product: ProductEnum; amount: number; currency: CurrencyTypeEnum }
    >({
      query: ({ product, amount, currency }) => ({
        document: DRAW,
        variables: {
          requestInput: {
            product,
            amount,
            currency
          }
        }
      }),
      transformResponse: (response: { draw: WorkstationTransaction }) =>
        response.draw
    }),
    payDraw: builder.query<
      WorkstationTransaction,
      { product: ProductEnum; amount: number; currency: CurrencyTypeEnum }
    >({
      query: ({ product, amount, currency }) => ({
        document: PAY_DRAW,
        variables: {
          requestInput: {
            product,
            amount,
            currency
          }
        }
      }),
      transformResponse: (response: { payDraw: WorkstationTransaction }) =>
        response.payDraw
    })
  })
})

export const {
  useDailySalesQuery,
  useLazyDailySalesQuery,
  useGetNextEventsQuery,
  useGetTodayCalendarQuery,
  useLazyGetNextEventsQuery,
  useLazyDrawQuery,
  useLazyPayDrawQuery,
  useLazyWorkstationBalanceQuery,
  useLazyLatestTicketTransactionsQuery,
  useLazyLatestCashTransactionsQuery,
  useLazyLatestWalletTransactionsQuery
} = productApi
export default productApi
