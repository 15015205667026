import { cloneDeep } from 'lodash'
import { ReactElement, ReactNode, useContext, useEffect } from 'react'
import {
  customFormatDate,
  useAppSelector,
  WhereGraphql
} from 'src/placebet/core'
import { generateFilterGraphql } from 'src/placebet/core/services/apiSearchHelpers'
import { useActivitiesHistoryDetail } from '../hooks'
import {
  ActivityHistoryDetailContext,
  setActivitiesHistoryDetail,
  setCount,
  setLoading
} from '../state'

export const QueryActivitiesHistoryDetail = ({
  children
}: {
  children: ReactElement | ReactNode
}) => {
  const { getActivitiesHistoryDetail, results, loading } =
    useActivitiesHistoryDetail()

  const { workstation } = useAppSelector((state) => state.appContext)

  const {
    dispatch,
    state: { filters }
  } = useContext(ActivityHistoryDetailContext)

  useEffect(() => {
    dispatch(setLoading(loading))
  }, [loading])

  useEffect(() => {
    if (workstation && filters) {
      const cloneDate = cloneDeep(filters.date)
      cloneDate.setDate(cloneDate.getDate() + 1)
      let customFilters = {
        and: [
          // {
          //   currencyId: {
          //     ...generateFilterGraphql(workstation.defaultCurrency.id, 'eq')
          //   }
          // },
          // {
          //   stationId: {
          //     ...generateFilterGraphql(workstation.station.id, 'eq')
          //   }
          // },
          // {
          //   locationId: {
          //     ...generateFilterGraphql(workstation.location.id, 'eq')
          //   }
          // },
          {
            activityType: {
              ...generateFilterGraphql(filters.activityType, 'eq')
            }
          },
          {
            date: {
              ...generateFilterGraphql(
                customFormatDate(filters.date, 'YYYY-MM-DD'),
                'eq'
              )
            }
          }
        ]
      }
      fetchData(customFilters)
    }
  }, [workstation, filters])

  const activities = results.results
  useEffect(() => {
    if (activities) {
      const activitiesWithIds = activities.map((item, index) => {
        return {
          ...item,
          id: index
        }
      })
      dispatch(setActivitiesHistoryDetail(activitiesWithIds))
    }
    if (results.metadata.count) {
      dispatch(setCount(results.metadata.count))
    }
  }, [activities])

  const fetchData = (where: WhereGraphql) => {
    getActivitiesHistoryDetail({
      where: where,
      skip: filters.skip,
      take: 20
    })
  }

  return <>{children}</>
}
