import { Actions } from './actions'
import { KeyboardMapperState } from '.'

export function reducer(
  state: KeyboardMapperState,
  action: Actions
): KeyboardMapperState {
  switch (action.type) {
    case 'KEY_PRESSED': {
      const { mode, bufferedValue } = state

      const updated = {
        ...state,
        keyPressed: { key: action.payload.key, nonce: Date.now() }
      }

      if (mode === 'ModalInput' || mode === 'Input') {
        switch (action.payload.key) {
          case 'Enter': {
            return {
              ...updated,
              value: bufferedValue,
              mode: 'Standard'
            }
          }

          case 'Escape': {
            return {
              ...updated,
              mode: 'Standard',
              modalCommand: '',
              bufferedValue: '',
              value: ''
            }
          }

          case 'Backspace': {
            const newValue =
              bufferedValue.length > 0 ? bufferedValue.slice(0, -1) : ''
            return {
              ...updated,
              bufferedValue: newValue
            }
          }

          default: {
            if (!!action.payload.mapped) {
              return {
                ...updated,
                value: action.payload.key,
                mapped: action.payload.mapped,
                bufferedValue: '',
                modalCommand: '',
                mode: 'Standard'
              }
            } else {
              return {
                ...updated,
                bufferedValue: `${bufferedValue ?? ''}` + action.payload.key
              }
            }
          }
        }
      } else {
        return {
          ...updated,
          value: action.payload.key,
          mapped: action.payload.mapped,
          mode: 'Standard',
          modalCommand: ''
        }
      }
    }

    case 'SET_KEYBOARD_MODE': {
      if (action.payload.mode === state.mode) {
        return {
          ...state
        }
      }

      return {
        ...state,
        mode: action.payload.mode,
        modalCommand: action.payload.command || '',
        bufferedValue: '',
        value: ''
      }
    }

    case 'PASTE_VALUE': {
      return {
        ...state,
        bufferedValue: action.payload
      }
    }

    case 'TRIGGER_MODAL_COMMAND': {
      return {
        ...state,
        mode: 'ModalInput',
        modalCommand: action.payload,
        keyPressed: { key: action.payload, nonce: Date.now() },
        bufferedValue: '',
        value: ''
      }
    }

    case 'BEGIN_TOOLBAR_ACTION': {
      return {
        ...state,
        bypass: true,
        toolbarAction: action.payload
      }
    }

    case 'COMPLETE_TOOLBAR_ACTION': {
      return {
        ...state,
        bypass: false,
        toolbarAction: undefined
      }
    }

    case 'SUBMIT_MODAL_COMMAND': {
      return {
        ...state,
        keyPressed: { key: 'Enter', nonce: Date.now() },
        mode: 'Standard',
        modalCommand: action.payload.modalCommand,
        bufferedValue: '',
        value: action.payload.value
      }
    }

    case 'DISABLE_KEYBOARD_MAPPING': {
      return {
        ...state,
        bypass: true
      }
    }

    case 'ENABLE_KEYBOARD_MAPPING': {
      return {
        ...state,
        bypass: false
      }
    }

    case 'NAVIGATE_TO_ACTION': {
      return {
        ...state,
        toolbarAction: action.payload
      }
    }

    default:
      throw new Error()
  }
}
