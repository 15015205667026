import React, { useContext, useEffect, useMemo } from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

import { useTranslation } from 'react-i18next'
import { WagerModifier } from '../../../../../models'
import { Box, Radio, styled } from '@mui/material'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from 'src/placebet/core'
import { setWagerModifier } from 'src/placebet/products/horseraces/redux/horseRacesProductSlice'

const RootWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  height: theme.header.height
}))

export default function PoolModifiers() {
  const { t } = useTranslation()
  const {
    horseRaces: {
      pool,
      boardWagerRequest: { modifier = 'NONE' }
    }
  } = useAppSelector((state) => state)

  const dispatch = useAppDispatch()

  useEffect(() => {
    window.addEventListener('mappedKeyPressed', downHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('mappedKeyPressed', downHandler)
    }
  }, [])

  const downHandler = (event: any) => {
    const payload = event.detail as {
      key: string
      mapped: boolean
      isToggle?: boolean
    }

    const option = payload.key as WagerModifier
    const allowedList = allowedModifiers.map((m) => m.key)
    const allowed = [...allowedList, 'NONE'].indexOf(option) !== -1
    if (!allowed) {
      return
    }

    if ((modifier as WagerModifier) !== option) {
      dispatch(setWagerModifier(option))
    } else if ((modifier as WagerModifier) === option) {
      dispatch(setWagerModifier('NONE'))
    }
  }

  const allowedModifiers = useMemo(() => {
    const allowed: WagerModifier[] = [
      'BOX',
      'KEY_BOX',
      'KEY_WHEEL',
      'LEADING',
      'POWER_BOX',
      'WHEEL'
    ]

    if (!pool) {
      return []
    }

    const allowedInPool = allowed
      .map((wm) => {
        const propName = _.camelCase(wm)
        return {
          allowed: !!pool[propName],
          propName: propName,
          key: wm
        }
      })
      .filter((ro) => ro.allowed)

    return allowedInPool
  }, [dispatch, modifier, pool])

  const poolModifiersList = useMemo(() => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setWagerModifier(event.target.value as WagerModifier))
    }

    let options: JSX.Element[] = []

    if (allowedModifiers.length > 0) {
      options.push(
        <FormControlLabel
          key={`radio-button-modifier-none`}
          control={
            <Radio
              checked={modifier === 'NONE'}
              onChange={handleChange}
              name="radio-button-modifier"
              value={'NONE'}
              size="small"
            />
          }
          label={t('NONE') as string}
        />
      )
    }

    allowedModifiers.forEach((ro, i) => {
      options.push(
        <FormControlLabel
          key={`radio-button-modifier-${i}`}
          control={
            <Radio
              checked={modifier === ro.key}
              onChange={handleChange}
              name="radio-button-modifier"
              value={ro.key}
              size="small"
            />
          }
          label={t(ro.key) as string}
        />
      )
    })

    return options
  }, [dispatch, modifier, pool, t])

  return !pool ? null : <FormGroup row>{poolModifiersList}</FormGroup>
}
