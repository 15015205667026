import { useContext, useMemo } from 'react'
import {
  IconButton,
  alpha,
  Tooltip,
  styled,
  useTheme,
  Stack,
  Typography,
  BadgeProps,
  Badge
} from '@mui/material'
import { KeyboardMapperContext } from 'src/placebet/products/providers/CustomKeyboardProvider/state'
import { matchPath } from 'react-router-dom'
import { useLocation } from 'react-router'

import { Link as RouterLink } from 'react-router-dom'
import {
  DictionaryOf,
  normalizeProductRouteParam,
  useAppSelector
} from 'src/placebet/core'

function NavigationButton({
  title,
  path,
  mappedKey: key,
  icon,
  disabledAt
}: {
  title: string
  path: string
  mappedKey?: string
  icon: React.ReactNode
  disabledAt?: string
}) {
  const theme = useTheme()
  const {
    state: {
      customKeyboard: { keys }
    },
    dispatch
  } = useContext(KeyboardMapperContext)

  const location = useLocation()

  const { product } = useAppSelector((state) => state.appContext)

  const disabled = useMemo(() => {
    if (!disabledAt) return false

    const match = matchPath(
      {
        path: '/app/:product/:path',
        end: true
      },
      location.pathname
    )
    const result = match?.params as {
      product?: string
      path?: string
    }

    return result?.path === disabledAt
  }, [location])

  const fnKey = useMemo(() => {
    if (!key) return

    let reversedMap: DictionaryOf<string> = {}
    Object.keys(keys).forEach((k) => {
      const key = keys[k] as string
      reversedMap = { ...reversedMap, [key]: k }
    })

    return reversedMap[key]
  }, [keys])

  const iconWrapper = useMemo(() => {
    const ImageWrapper = styled(icon as any)({ width: '30px' })

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
      '& .MuiBadge-badge': {
        right: -4,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px'
      }
    }))

    return fnKey ? (
      <StyledBadge badgeContent={fnKey} color="secondary">
        <ImageWrapper />
      </StyledBadge>
    ) : (
      <ImageWrapper />
    )
  }, [fnKey])

  const color = theme.colors.info.main

  return (
    <>
      <Tooltip arrow title={title}>
        <Stack
          spacing={0.5}
          sx={{
            textOverflow: 'ellipsis'
          }}
        >
          <IconButton
            disabled={disabled}
            color="primary"
            to={`/app/${normalizeProductRouteParam(product)}/${path}`}
            component={RouterLink}
            sx={{
              width: theme.spacing(4),
              height: theme.spacing(4),
              borderRadius: theme.general.borderRadiusLg,
              mx: 1,
              background: alpha(color, 0.1),
              transition: `${theme.transitions.create(['background'])}`,
              color: color,
              '&:hover': {
                background: alpha(color, 0.2)
              }
            }}
          >
            {iconWrapper}
          </IconButton>
          <Typography
            display={'flex'}
            sx={{
              justifyContent: 'center',
              fontSize: '10px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis'
            }}
          >
            {title}
          </Typography>
        </Stack>
      </Tooltip>
    </>
  )
}

export default NavigationButton
