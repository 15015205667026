import { gql } from '@apollo/client'

export const WORKSTATION_TRANSACTION_RECEIPT = gql`
  fragment WorkstationTransactionFragment on WorkstationTransaction {
    id
    number
    amount
    date
    product
    transaction
    # transactionEvent {
    #   id
    #   __typename
    # }
    user {
      name
      __typename
    }
    workstation {
      name
      agency {
        name
        enterprise {
          name
          __typename
        }
        products {
          name
          code
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`
export const WORKSTATION_TICKET_TRANSACTION_RECEIPT = gql`
  fragment WorkstationTransactionFragment on WorkstationTicketTransaction {
    id
    number
    amount
    date
    currency
    product
    transaction
    transactionEvent {
      transactionType
      number
      surcharge
    }
    # user {
    #   name
    #   __typename
    # }
    workstation {
      name
      agency {
        name
        enterprise {
          name
          __typename
        }
        products {
          name
          code
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`
