import { CardContent, styled, Tabs, Tab } from '@mui/material'
import { ChangeEvent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityType } from '../models'
import { ActivityHistoryDetailContext, setFilters } from '../state'

export const ActivityTypeFilter = () => {
  const { t }: { t: any } = useTranslation()

  const {
    state: { filters },
    dispatch
  } = useContext(ActivityHistoryDetailContext)

  const handleTabsChange = (
    _event: ChangeEvent<{}>,
    value: ActivityType
  ): void => {
    dispatch(
      setFilters({
        ...filters,
        activityType: value
      })
    )
  }

  const tabs = [
    { value: 'SUBMITTICKET', label: t('SUBMITTICKET') },
    { value: 'PAYTICKET', label: t('PAYTICKET') },
    { value: 'VOIDTICKET', label: t('VOIDTICKET') },
    { value: 'DRAW', label: t('DRAW') },
    { value: 'PAYDRAW', label: t('PAYDRAW') }
  ]

  return (
    <Tabs
      onChange={handleTabsChange}
      value={filters.activityType}
      variant="scrollable"
      scrollButtons="auto"
      textColor="primary"
      indicatorColor="primary"
    >
      {tabs.map((tab) => (
        <Tab key={tab.value} label={tab.label} value={tab.value} />
      ))}
    </Tabs>
  )
}
