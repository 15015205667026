import { string } from 'prop-types'
import { ProductEnum } from '../services'
import {
  CurrencyTypeEnum,
  League,
  ProductCode,
  Profile,
  ProfileAccounts
} from './metadata'

export enum RequestStateEnum {
  None = 'None',
  InProgress = 'InProgress',
  Success = 'Success',
  Failed = 'Failed',
  Unauthorized = 'Unauthorized'
}

export type RequestState =
  | 'None'
  | 'InProgress'
  | 'Success'
  | 'Failed'
  | 'Forbidden'
  | 'Unauthorized'
  | 'Unavailable'

export interface SearchRequest {
  filter?: string
  orderBy?: string
  page?: number
  pageSize?: number
  sortDirection?: 'Ascending' | 'Descending'
  columns?: Array<string>
  required?: any
  boundary?: string
}

export interface UpdateRequest {
  [key: string]: string | number | Entity | undefined | any
}

export interface QueryResults<TResult> {
  totalCount: number
  pageInfo: {
    hasNextPage: boolean
    hasPreviousPage: boolean
  }
  items: TResult[]
}

export interface ResultsList<T, TM> {
  results: T[]
  metadata: TM
}

export interface Filters {
  [key: string]: Filter[]
}

export interface KeyValuePair {
  [key: string]: string | string[]
}

export interface Metadata {
  count?: number
  page?: number
  pageSize?: number
}

export interface SearchMetadata extends Metadata {
  columns?: EventColumn[]
  filters?: Filters
}

export interface TicketsMetadata extends Metadata {
  date?: Date
  lifeTimeInSeconds: number
  alertBeforeExpiration: number
  total: number
}

export interface TicketPlayMetadata {
  selectedTicketId: string
  key: string
  bet: number
}

export interface Entity {
  id: string
  value: string
  active?: true
}

export interface EntityResponse {
  id: string
  response: any
}

export interface EntityActionResponse {
  id?: string
  action?: 'Created' | 'Saved' | 'Deleted'
}

export interface Filter {
  [key: string]: FilterColumn | undefined
}

export interface Restrictions {
  [key: string]: string | number | Date
}

export type MatchType = 'Exact' | 'Contains' | 'StartsWith' | 'EndsWith'

export interface FilterColumn {
  group?: string
  matchType: MatchType
  value: string | number | Date | boolean | undefined
}

export interface MatchupSearchRequest {
  filter?: Filter
  orderBy?: string
  page?: number
  pageSize?: number
  sortDirection?: 'Ascending' | 'Descending'
  restrictions?: Restrictions
}

export interface DbEntitity {
  id: number
  name: string
}
export interface TypeBet extends DbEntitity {
  acronym: string
}

export interface Column {
  identifier: string
  takeDown: boolean
  name?: string
  updateTime?: Date
  price?: number
  displayValue: string
}
export interface Columns {
  [key: string]: Column
}

export interface ColumnSegment {
  identifier: string
  price: number
  name?: string
  displayValue: string
  exclusions: string[]
}

export interface EventEntity {
  id: string
  name: string
}

export interface TreeNode {
  id: number | string
  acronym: string
  name: string
  children: TreeNode[]
}

export type EventStatus =
  | 'Open'
  | 'Closed'
  | 'Post'
  | 'Final'
  | 'Cancelled'
  | 'Locked'
  | 'UnCancel'
  | 'UnOfficial'
  | 'Begin'

export interface LeagueEvent<TEvent, TResult> {
  [key: string]:
    | string
    | number
    | Date
    | boolean
    | TEvent
    | TResult
    | EventStatus
    | League
  id: string
  eventId: string
  eventName: string
  eventDate: Date
  eventTime: Date
  league: League
  productId: number
  sportGroupId: number
  sportId: number
  takeDown: boolean
  eventStatus: EventStatus
  details: TEvent
  results?: TResult
  checksum: string
  updated?: Date
}

export interface Node {
  id: string
  name: string
  route: string
  children: Node[]
  logo?: () => JSX.Element
  disabled?: boolean
  metadata?: {
    [key: string]: string | number | Date
  }
}

export interface TodayEvent {
  id: string
  eventId: string
  eventName: string
  eventDate: Date
  eventTime: Date
  eventStatus: 'Open' | 'Closed' | 'Final'
  league: League
}

export interface EntityModel {
  id: number
  name?: string | number | any
  metadata?: any
}
export interface Organization {
  id: number
  logo?: string
  name: string
  abbreviation: string
}

export interface LeagueModel extends Organization {
  default: boolean
  sportId: number
  periods: Period[]
}

export interface LeagueGroupModel {
  id: number
  name: string
  leagues: LeagueModel[]
}

export interface CategoryGroupModel {
  name: string
  groups: LeagueGroupModel[]
}

export interface ProductModel {
  id: number
  name: string
  lineMasterId: number
  image: string
  ticketTypes: EntityModel[]
}

export interface ProductConfiguration {
  boardUrl: string
  metadata?: any
}

export interface EventColumn {
  key: string
  name: SupportedColumns
  acronym: string
}

export interface EventTeamColumn {
  id: string
  displayValue: string
  spread: number
  price: number
  str: string
  lastUpdated: Date
  locked: boolean
  children: EventTeamColumn[]
}

export type SupportedColumns = 'ML' | 'RL' | 'Total' | 'Solo'

export interface PlayColumn extends EventTeamColumn {
  name: SupportedColumns
  acronym: string
  substitutePrice?: number
  substitutePriceDisplay?: string
}

export interface EventTeamColumns {
  [key: string]: EventTeamColumn
}

export interface EventTeam extends Organization {
  str: string
  columns: EventTeamColumns
  nickname: string
}

export interface EventPeriod {
  id: number
  locked: boolean
  typeScoreId: number
  typeScoreName: string
  isYesOrNot: boolean
  isBloqueAndCartelera: boolean
  useBeltColor: boolean
  name: string
  home: EventTeam
  away: EventTeam
  tie: EventTeam
  leagueName?: string
  gameScheduleId: number
  associatedGameScheduleId?: number
}

export type SupportedPeriodGroupsEnum =
  | 'AssociatedPeriods'
  | 'Quadrant'
  | 'Territory'

export interface EventPeriodGroup {
  key: SupportedPeriodGroupsEnum
  productId: number
  label: string
  periods: EventPeriod[]
}

export interface EventMatch {
  id: number
  name: string
  date: Date
  periods: EventPeriod[]
  periodGroups: EventPeriodGroup[]
}

export interface EventLeague extends Organization {
  seasonId: number
  sportId: number
  columns: EventColumn[]
  events: EventMatch[]
  primary: boolean
  periods: EntityModel[]
}

export interface Period {
  id: number
  productId: number
  leagueId: number
  languageId: number
  name: string
  acronym: string
}

export interface PlayResponse {
  exclusions: string[]
  pointOffers: PointOfferItem[]
}

export interface PlayActionResponse extends PlayResponse {
  gameScheduleId: number
  columnId: string
}

export interface PlayTeam extends EntityModel {
  side: Side
}

export interface Play {
  id: string
  gameScheduleId: number
  product: EntityModel
  typeScoreId: number
  ticketType: EntityModel
  typeBetId: number
  team: PlayTeam
  league: EntityModel
  seasonId: number
  sportId: number
  period: EntityModel
  matchup: EntityModel
  column: PlayColumn
  points?: number
  pointOffers?: PointOfferItem[]
  disabled: boolean
  display: string
}

export interface PlayRequest {
  productId: number
  ticketTypeId: number
  bet?: number
}

export interface MatchupPlayRequest extends PlayRequest {
  teamStr: string
  str: string
  gameScheduleId: number
}

export interface PointOfferItem {
  point: number
  price: number
  priceOffer: number
  displayValue: string
}

export interface Exclusions {
  [key: string]: string[]
}
export interface buyPointsListRequest {
  productId: number
  leagueId: number
  seasonId: number
  sportId: number
  TypeScoreId: number
  periodId: number
  typeBetId: number
  spread: number
}

export type Side = 'None' | 'Home' | 'Away' | 'Tie'

export interface NumberDictionary {
  [key: string]: number
}

export interface GlobalSettings {
  suggestedRiskAmountForTicket: number[]
}

export type NaturalLanguajeEntryNotation =
  | 'Unknown'
  | 'NumericEntry'
  | 'AlphanumericEntry'

export interface EnterpriseSettings {
  maxPlaysPerTicketByProduct: NumberDictionary
  removeFractionsAtCalculation: boolean
  formatDecimalSpreadAsFraction: boolean
  highlightTimeSpan: number
  highlightTimeSpanBeforeMatch: number
  showTeamIdInTicket: boolean
  ticketExpirationInSeconds: number
  secondsForAlertBeforeTicketExpiration: number
  secondsBeforeDisableAddPlays: number
  playEntryMethod: NaturalLanguajeEntryNotation
  allowMixTicket: boolean
}

export interface AppData {
  products: ProductModel[]
  globalSettings: GlobalSettings
  enterpriseSettings: EnterpriseSettings
  quadrantPrices: QuadrantPrices
}

export interface BroadcastContent<TPayload> {
  timeStamp?: Date
  processTime?: number
  content: TPayload
}

export interface BoardBroadcastContent<TEvent> {
  key: string
  timeStamp?: Date
  processTime?: number
  content: TEvent
}

export interface SyncEvent {
  updated: Date
  entityName: string
}

export interface LeagueFilter {
  id: number
  selected?: boolean
}

export interface Preferences {
  leagues: LeagueFilter[]
}

export interface CalculationResult {
  ticketId: string
  result: number
}

export interface RequestError {
  key: string | number
  message: string
}

export interface QuadrantPriceListItem {
  price: number
  str: string
  priceDisplay: string
}

export interface QuadrantPrices {
  away: QuadrantPriceListItem[]
  home: QuadrantPriceListItem[]
}

export interface BillboardExpanded {
  gameScheduleId: number
  group: string
}

export interface TicketSubmisionResult {
  ticketNumberId: number
  confirmationCode: string
  salesTime: Date
  message: string
  ticketId: string
  successful: boolean
}

export interface LookupDictionary {
  [key: string]: string
}

export interface GraphqlExpression {
  [key: string]: string | number | Date | boolean | object
}
export interface WhereGraphql {
  [key: string]: GraphqlExpression | GraphqlExpression[]
}
export interface SearchGraphql {
  where?: WhereGraphql | WhereGraphql[]
  take?: number
  skip?: number
}

export type SearchGraphqlType = 'eq' | 'or' | 'nin' | 'contains' | 'gte' | 'lte'

export interface Dictionary {
  [key: string]: string | number | Date | Boolean | undefined | object[]
}

export interface DictionaryOf<T> {
  [key: string]: T
}

export interface IdValuePair<T> {
  id: T
  value: string
}
export interface IdNamePair<T> {
  id: T
  name: string
}

export interface Location {
  id: number
  name?: string
  typeLocationId: number | string
  address?: string
  phone?: string
  countryId?: number | string
  provinceId?: number | string
  municipalityId?: number | string
  sectorId?: number | string
  restrictedByGeography?: boolean
  active: boolean
}

export interface Currency {
  entityId: number
  grupal: string
}

export interface UserWorkstation {
  enterprise: IdValuePair<number>
  location: IdValuePair<number>
  locationClientCode: IdValuePair<string>
  station: IdValuePair<number>
  workstation: IdValuePair<number>
  defaultCurrency: IdValuePair<number>
}

export interface DailySales {
  product: ProductEnum
  salesTotal?: number
}

export interface WorkstationBalances extends DailySales {
  pendingDraw?: number
}

export interface WorkStationMetadata {
  name: string
  agency: {
    name: string
    enterprise: {
      name: string
    }
    products: ProductCode[]
  }
}

export interface AccountMetadata {
  name: string
  product: ProductEnum
  number: string
  provider: {
    name: string
  }
}

export interface EventMetadata {
  eventId: string
  eventName: string
  league: {
    name: string
  }
}

export type TransactionTypeEnum = 'NONE' | 'DEBIT' | 'CREDIT'

export interface WorkstationTransaction {
  id: string
  number: string
  product: ProductEnum
  date: string
  workstation: WorkStationMetadata
  user: { name: string }
  currency: CurrencyTypeEnum
  amount: number | null
  transaction: TransactionTypeEnum
  transactionEvent: any
}

export type CashOperationType = 'NONE' | 'PAY' | 'PAYDRAW'

export type AccountStatusEnum =
  | 'UNKNOWN'
  | 'ACTIVE'
  | 'CLOSED'
  | 'DISABLED'
  | 'CREATED'

export type AccountPermissionsEnum =
  | 'None'
  | 'View'
  | 'Deposit'
  | 'Witdrawal'
  | 'Full'
export interface AccountAccess {
  userId: string
  permissions: AccountPermissionsEnum
  dateAssigned: Date
  assignedBy: string
}

export type TransactionKind = 'NONE' | 'DEPOSIT' | 'WITHDRAWAL' | 'CASHIN'
export type TransactionStatusEnum =
  | 'NONE'
  | 'OPEN'
  | 'CLOSED'
  | 'FAILED'
  | 'CANCELLED'

export interface WalletTransaction {
  id: string
  number: string
  account: AccountMetadata
  currency: CurrencyTypeEnum
  amount: number
  balance: number | null
  kind: TransactionKind
  status: TransactionStatusEnum
  user: {
    name: string
  }
  referenceId: string
  workstation: WorkStationMetadata
  createdDate: Date
  completedDate: Date
  error: string
}

export interface WalletAccount {
  id: string
  number: string
  name: string
  description?: string
  currency: CurrencyTypeEnum
  kind: TransactionKind
  createdDate: Date
  completedDate: Date
  status: AccountStatusEnum
  provider: {
    id: string
    name: string
    logo: string
    product: ProductEnum
  }
  balance?: number
  active: boolean
}

export interface WalletProduct {
  id: number
  name: string
  logo: string
  url: string
  signInUrl: string
  description: string
  disabled: boolean
  accounts: WalletAccount[]
}

export interface WalletRequestLinkAccount {
  name: string
  description: string
  currency: CurrencyTypeEnum
  providerId: string
  number: string
  secret: string
}
