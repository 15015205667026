import React from 'react'

import { styled, Typography } from '@mui/material'

const StyledTypography = styled(Typography)(({ theme }) => ({
  textDecoration: 'line-through'
}))

export const CustomTypography = ({
  scratched,
  children,
  ...otherProps
}: {
  scratched?: boolean
  children: React.ReactNode
} & Record<string, any>) => {
  return !!scratched ? (
    <StyledTypography {...otherProps}>{children}</StyledTypography>
  ) : (
    <Typography {...otherProps}>{children}</Typography>
  )
}
