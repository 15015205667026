import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers
} from '@reduxjs/toolkit'
import cockfightsWagerPreviewSlice from 'src/placebet/products/cockfights/components/tickets/TicketsSidebar/WagerPreview/wagerPreviewSlice'
import cockfigthsAppSlice from 'src/placebet/products/cockfights/redux/cockfightsProductSlice'
import horseRacesAppSlice from 'src/placebet/products/horseraces/redux/horseRacesProductSlice'

import productApi from 'src/placebet/core/api/productApi/productApi'
import productSlice from 'src/placebet/core/api/productApi/productSlice'

import ticketManagementSlice from 'src/placebet/products/components/TicketManagementCore/redux/ticketManagementSlice'
import walletAppSlice from 'src/placebet/pages/Wallet/redux/walletSlice'
import appContextSlice from '../appContext/appContextSlice'

export const store = configureStore({
  reducer: combineReducers({
    [productApi.reducerPath]: productApi.reducer,
    [productSlice.name]: productSlice.reducer,
    [appContextSlice.name]: appContextSlice.reducer,
    [ticketManagementSlice.name]: ticketManagementSlice.reducer,
    [cockfigthsAppSlice.name]: cockfigthsAppSlice.reducer,
    [horseRacesAppSlice.name]: horseRacesAppSlice.reducer,
    [cockfightsWagerPreviewSlice.name]: cockfightsWagerPreviewSlice.reducer,
    [walletAppSlice.name]: walletAppSlice.reducer
  })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
