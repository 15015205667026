import { styled, TableCell, Typography } from '@mui/material'

export const CustomTableContainer = styled(TableCell)(({ theme }) => ({
  width: '100%',
  backgroundColor: 'white',
  borderBottom: 'none'
}))

export const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  color: 'black'
}))

export const CustomTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  color: 'black',
  padding: '0px',
  lineHeight: '1.2em',
  fontWeight: 'normal',
  textTransform: 'inherit'
}))

export const ReceiptTypography = styled(Typography)(({ theme }) => ({
  color: '#000000',
  fontFamily: 'Arial'
}))
