import { AnyTicket } from 'src/placebet/core/models'

export function transformTicket(ticket: any): AnyTicket {
  if (!ticket) {
    return ticket
  }

  const { product } = ticket
  switch (product) {
    case 'HORSE_RACES': {
      const { horseRacesWagerCollections: wagerCollections, ...rest } = ticket

      return {
        ...rest,
        wagerCollections,
        metadata: {
          selected: [],
          exclusions: []
        }
      } as AnyTicket
    }

    case 'COCKFIGHTS': {
      const { cockfightsWagerCollections: wagerCollections, ...rest } = ticket

      return {
        ...rest,
        wagerCollections,
        metadata: {
          selected: [],
          exclusions: []
        }
      } as AnyTicket
    }

    default:
      return ticket
  }
}
