import { gql } from '@apollo/client'
import { LeagueEvent, toPascalCase } from 'src/placebet/core'
import {
  AnyTicket,
  DictionaryOf,
  WorkstationTransaction
} from 'src/placebet/core/models'
import { transformTicket } from 'src/placebet/core/services/transformTicket'
import {
  CockfightsTicket,
  CockfightsWagerUpdateRequest,
  EventDetails,
  EventResults,
  EVENT_DETAILS,
  EVENT_RESULTS,
  LEAGUE_EVENT,
  TICKET_FIELDS,
  TICKET_FIELDS_WITH_PAYOFF
} from '../models'

import productApi from '../../../core/api/productApi/productApi'
import {
  WORKSTATION_TICKET_TRANSACTION_RECEIPT,
  WORKSTATION_TRANSACTION_RECEIPT
} from 'src/placebet/core/api/productApi/fragments'

const LEAGUE_EVENTS_QUERY = gql`
  ${LEAGUE_EVENT}
  ${EVENT_DETAILS}
  ${EVENT_RESULTS}
  ${TICKET_FIELDS}
  query CockfightsLeagueEvents(
    $name: String!
    $whereTicket: TicketFilterInput
  ) {
    cockfightsLeagueEvents(name: $name) {
      ...CockfightsEvent
      details {
        ...EventDetails
      }
      results {
        ...EventResults
      }
    }
    tickets(where: $whereTicket) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        ...TicketFields
      }
    }
  }
`
const LEAGUE_EVENT_QUERY = gql`
  ${LEAGUE_EVENT}
  ${EVENT_DETAILS}
  ${EVENT_RESULTS}
  ${TICKET_FIELDS}
  query CockfightsLeagueEvents(
    $name: String!
    $match: String!
    $whereTicket: TicketFilterInput
  ) {
    cockfightsLeagueEvent(name: $name, match: $match) {
      ...CockfightsEvent
      details {
        ...EventDetails
      }
      results {
        ...EventResults
      }
    }
    tickets(where: $whereTicket) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        ...TicketFields
      }
    }
  }
`
const TICKETS_QUERY = gql`
  ${TICKET_FIELDS}
  query Tickets($whereTicket: TicketFilterInput) {
    tickets(where: $whereTicket) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        ...TicketFields
      }
    }
  }
`
const TICKET_QUERY = gql`
  ${TICKET_FIELDS_WITH_PAYOFF}
  query GetTicket($number: String!) {
    ticket(number: $number) {
      ...TicketFieldsWithPayoff
      __typename
    }
  }
`

export interface LegueEventsData {
  cockfightsLeagueEvents: LeagueEvent<EventDetails, EventResults>[]
  tickets: {
    items: AnyTicket[]
  }
}

export interface LegueEventData {
  cockfightsLeagueEvent: LeagueEvent<EventDetails, EventResults>
  tickets: {
    items: AnyTicket[]
  }
}

export interface TicketsData {
  tickets: {
    items: CockfightsTicket[]
  }
}

export interface TicketResponseData {
  [key: string]: CockfightsTicket
}

export interface LegueEventsResponse {
  events: LeagueEvent<EventDetails, EventResults>[]
  tickets: DictionaryOf<AnyTicket>
}

export interface LegueEventResponse {
  event: LeagueEvent<EventDetails, EventResults>
  tickets: DictionaryOf<AnyTicket>
}

const cockfightsApi = productApi.injectEndpoints({
  endpoints: (builder) => ({
    getCockfightsLeagueEvents: builder.query<
      LegueEventsResponse,
      { name: string }
    >({
      query: ({ name }) => ({
        document: LEAGUE_EVENTS_QUERY,
        variables: {
          name
        }
      }),
      transformResponse: (response: LegueEventsData) => {
        let tickets: DictionaryOf<AnyTicket> = {}

        const list = response.tickets.items || []

        list.forEach((t) => {
          const ticket = transformTicket(t)
          tickets[ticket.grouping] = ticket
        })

        return {
          events: response.cockfightsLeagueEvents || [],
          tickets
        }
      }
    }),
    getCockfightsLeagueEvent: builder.query<
      LegueEventResponse,
      { name: string; match: string }
    >({
      query: ({ name, match }) => ({
        document: LEAGUE_EVENT_QUERY,
        variables: {
          name,
          match
        }
      }),
      transformResponse: (response: LegueEventData) => {
        let tickets: DictionaryOf<AnyTicket> = {}

        const list = response.tickets.items || []

        list.forEach((t) => {
          const ticket = transformTicket(t)
          tickets[ticket.grouping] = ticket
        })

        return {
          event: response.cockfightsLeagueEvent,
          tickets
        }
      }
    }),
    getCockfightsTicket: builder.query<AnyTicket, { number: string }>({
      query: ({ number }) => ({
        document: TICKET_QUERY,
        variables: {
          number
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        return transformTicket(response.ticket)
      }
    }),
    getCockfightsTickets: builder.query<
      DictionaryOf<AnyTicket>,
      { whereTicket?: any }
    >({
      query: ({ whereTicket }) => ({
        document: TICKETS_QUERY,
        variables: {
          whereTicket
        }
      }),
      transformResponse: (response: TicketsData) => {
        let tickets: DictionaryOf<AnyTicket> = {}

        const list = response.tickets.items || []

        list.forEach((t) => {
          const ticket = transformTicket(t)
          tickets[ticket.grouping] = ticket
        })
        return tickets
      }
    }),
    submitCockfightsTicketWager: builder.mutation<AnyTicket, { request: any }>({
      query: ({ request }) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation SubmitWager($input: CockfightsWagerRequestInput!) {
            submitCockfightsWager(input: $input) {
              ...TicketFields
            }
          }
        `,
        variables: {
          input: {
            ...request
          }
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(response[`submitCockfightsWager`])
        return updated
      }
    }),
    updateCockfightsTicketWagerAmount: builder.mutation<
      AnyTicket,
      CockfightsWagerUpdateRequest
    >({
      query: (request) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation SubmitWager($input: CockfightsWagerUpdateRequestInput!) {
            updateCockfightsTicketWagerAmount(input: $input) {
              ...TicketFields
            }
          }
        `,
        variables: {
          input: {
            ...request
          }
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(
          response[`updateCockfightsTicketWagerAmount`]
        )
        return updated
      }
    }),
    removeCockfightsTicket: builder.mutation<
      { success: boolean; ticketId: string },
      { ticketId: string }
    >({
      query: ({ ticketId }) => ({
        document: gql`
          mutation RemoveTicket($ticketId: String!) {
            removeCockfightsTicket(ticketId: $ticketId) {
              entityId
              success
            }
          }
        `,
        variables: {
          ticketId
        }
      }),
      transformResponse: (response: {
        removeCockfightsTicket: { success: boolean; entityId: string }
      }) => {
        return {
          success: response.removeCockfightsTicket.success,
          ticketId: response.removeCockfightsTicket.entityId
        }
      }
    }),
    removeCockfightsWager: builder.mutation<
      AnyTicket,
      { ticketId: string; wagerId: string }
    >({
      query: ({ ticketId, wagerId }) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation RemoveTicketWager($ticketId: String!, $wagerId: String!) {
            removeCockfightsTicketWager(
              ticketId: $ticketId
              wagerId: $wagerId
            ) {
              ...TicketFields
            }
          }
        `,
        variables: {
          ticketId,
          wagerId
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(response[`removeCockfightsTicketWager`])
        return updated
      }
    }),
    removeCockfightsWagerCollection: builder.mutation<
      AnyTicket,
      { ticketId: string; wagerCollectionId: string }
    >({
      query: ({ ticketId, wagerCollectionId }) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation RemoveTicketWagerCollection(
            $ticketId: String!
            $wagerCollectionId: String!
          ) {
            removeCockfightsTicketWagerCollection(
              ticketId: $ticketId
              wagerCollectionId: $wagerCollectionId
            ) {
              ...TicketFields
            }
          }
        `,
        variables: {
          ticketId,
          wagerCollectionId
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(
          response[`removeCockfightsTicketWagerCollection`]
        )
        return updated
      }
    }),
    submitCockfightsTicket: builder.mutation<AnyTicket, { ticketId: string }>({
      query: ({ ticketId }) => ({
        document: gql`
          ${TICKET_FIELDS}
          mutation SubmitTicket($ticketId: String!) {
            submitCockfightsTicket(ticketId: $ticketId) {
              ...TicketFields
            }
          }
        `,
        variables: {
          ticketId
        }
      }),
      transformResponse: (response: TicketResponseData) => {
        const updated = transformTicket(response[`submitCockfightsTicket`])
        return updated
      }
    }),
    cancelCockfightsTicket: builder.mutation<
      WorkstationTransaction,
      { ticketNumber: string; allowProviderTicket: boolean }
    >({
      query: ({ ticketNumber, allowProviderTicket }) => ({
        document: gql`
          ${WORKSTATION_TICKET_TRANSACTION_RECEIPT}
          mutation CancelTicket(
            $ticketNumber: String!
            $allowProviderTicket: Boolean
          ) {
            cancelCockfightsTicket(
              ticketNumber: $ticketNumber
              allowProviderTicket: $allowProviderTicket
            ) {
              ...WorkstationTransactionFragment
            }
          }
        `,
        variables: {
          ticketNumber,
          allowProviderTicket
        }
      }),
      transformResponse: (response: {
        cancelCockfightsTicket: WorkstationTransaction
      }) => {
        return response.cancelCockfightsTicket
      }
    }),
    payCockfightsTicket: builder.mutation<
      WorkstationTransaction,
      { ticketNumber: string; allowProviderTicket: boolean }
    >({
      query: ({ ticketNumber, allowProviderTicket }) => ({
        document: gql`
          ${WORKSTATION_TICKET_TRANSACTION_RECEIPT}
          mutation PayTicket(
            $ticketNumber: String!
            $allowProviderTicket: Boolean
          ) {
            payCockfightsTicket(
              ticketNumber: $ticketNumber
              allowProviderTicket: $allowProviderTicket
            ) {
              ...WorkstationTransactionFragment
            }
          }
        `,
        variables: {
          ticketNumber,
          allowProviderTicket
        }
      }),
      transformResponse: (response: {
        payCockfightsTicket: WorkstationTransaction
      }) => {
        return response.payCockfightsTicket
      }
    })
  })
})

export const {
  useLazyGetCockfightsLeagueEventsQuery,
  useGetCockfightsLeagueEventsQuery,
  useLazyGetCockfightsLeagueEventQuery,
  useLazyGetCockfightsTicketsQuery,
  useLazyGetCockfightsTicketQuery,
  useSubmitCockfightsTicketWagerMutation,
  useRemoveCockfightsTicketMutation,
  useRemoveCockfightsWagerMutation,
  useRemoveCockfightsWagerCollectionMutation,
  useSubmitCockfightsTicketMutation,
  useCancelCockfightsTicketMutation,
  usePayCockfightsTicketMutation,
  useUpdateCockfightsTicketWagerAmountMutation
} = cockfightsApi

export default cockfightsApi
