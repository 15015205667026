import {
  Card,
  Box,
  Typography,
  Divider,
  LinearProgress,
  styled,
  useTheme,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  TextField,
  Button,
  Stack
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  AttachMoneyTwoTone as MoneyIcon,
  GetApp as DepositIcon
} from '@mui/icons-material'
import { useMemo, useState } from 'react'

import { ProfileAccounts } from 'src/placebet/core/models'
import { DepositRequest } from 'src/placebet/core/api/walletApi/models'
import { useAppSelector } from 'src/placebet/core'

const LinearProgressWrapper = styled(LinearProgress)(
  ({ theme }) => `
          flex-grow: 1;
          height: 8px;
          width: 80%;
          margin-top: ${theme.spacing(0.5)}; 
          
          &.MuiLinearProgress-root {
            background-color: ${theme.colors.alpha.black[10]};
          }
          
          .MuiLinearProgress-bar {
            border-radius: ${theme.general.borderRadiusXl};
          }
  `
)

function UserAccounts({
  profile,
  onSubmit
}: {
  profile: ProfileAccounts
  onSubmit: (depositRequest: DepositRequest) => void
}) {
  const { t }: { t: any } = useTranslation()
  const theme = useTheme()
  const [value, setValue] = useState<string>('')

  const {
    workstation: { id: workstationId }
  } = useAppSelector((state) => state.appContext)

  const account = useMemo(
    () =>
      profile && profile.accounts.length >= 0
        ? profile?.accounts[0]
        : ({} as any),
    [profile.accounts]
  )

  const {
    id,
    familyName,
    givenName,
    middleName,
    email,
    phoneNumber,
    accounts
  } = profile

  const Section = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2)
  }))

  const handleSubmitCommand = (event: any) => {
    if (event.keyCode === 13) {
      if (
        !event.target.value ||
        (event.target.value as string).trim().length === 0
      ) {
        event.preventDefault()
        return
      }

      onSubmit({
        amount: Number.parseFloat(value),
        accountId: account.id,
        referenceId: workstationId
      })

      setValue('')
      event.preventDefault()
    }
  }

  const handleCommandChange = (event: any) => {
    const value = event.target.value as string
    const cleanValue = value.replace(/'/g, '-')
    setValue(cleanValue)
  }

  const handleSubmit = () => {
    onSubmit({
      amount: Number.parseFloat(value),
      accountId: account.id,
      referenceId: workstationId
    })
  }
  const fullName = [givenName, middleName, familyName]
    .filter((v) => v)
    .join(' ')

  return (
    <Card square elevation={0}>
      <Section>
        <Typography variant="h3">{fullName}</Typography>
        <Typography variant="subtitle2" gutterBottom>
          {t(email)}
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          {t(phoneNumber)}
        </Typography>
      </Section>
      <Divider variant="middle" />
      <Section>
        <FormControl>
          <FormLabel sx={{ mb: 2 }} id="demo-radio-buttons-group-label">
            <b>{t('PRODUCTOS')}</b>
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={account?.id}
            name="radio-buttons-group"
          >
            {accounts.map((account) => (
              <FormControlLabel
                key={account.id}
                value={account.id}
                control={<Radio />}
                label={account?.name}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Section>
      <Divider variant="middle" />

      <Stack direction={'row'} sx={{ mx: 2, my: 0.5 }} spacing={0.25}>
        <TextField
          autoFocus
          InputProps={{
            style: {
              borderRadius: 0
            },
            startAdornment: (
              <InputAdornment position="start">
                <MoneyIcon />
              </InputAdornment>
            )
          }}
          onKeyDown={handleSubmitCommand}
          onKeyPress={handleSubmitCommand}
          onChange={handleCommandChange}
          placeholder={t('MONTO')}
          value={value}
          size="small"
          fullWidth
          margin="none"
          variant="outlined"
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ p: 0, borderRadius: 0 }}
          aria-label={t('DEPOSITAR')}
          onClick={handleSubmit}
        >
          <DepositIcon />
        </Button>
      </Stack>
    </Card>
  )
}

export default UserAccounts
