import { Suspense, lazy } from 'react'

import SuspenseLoader from 'src/components/SuspenseLoader'

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

const Accounts = Loader(lazy(() => import('../Accounts')))
const Products = Loader(lazy(() => import('../Products')))

const walletRoutes = [
  {
    path: '',
    element: <Accounts />
  },
  {
    path: 'accounts',
    element: <Accounts />
  },
  {
    path: 'products',
    element: <Products />
  }
]

export default walletRoutes
