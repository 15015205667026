import { AnyTicket } from 'src/placebet/core/models'

export function ticketUpdate(state, payload: AnyTicket) {
  const { tickets, grouping } = state
  if (payload.wagerCollections.length > 0) {
    state.tickets = { ...tickets, [payload.grouping]: payload }
    state.ticket = payload
  } else {
    // remove the ticket if there are no collections
    const { [grouping]: current, ...others } = tickets
    if (current) {
      state.tickets = { ...others }
      state.ticket = undefined
    }
  }
  state.busy = false
}
