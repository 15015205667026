import { IconButton, alpha, Tooltip, styled, useTheme } from '@mui/material'

import CartIcon from '@mui/icons-material/ShoppingCart'
import { useTranslation } from 'react-i18next'
import { toggleRightSidebar } from 'src/placebet/core/appContext/appContextSlice'
import { useAppDispatch } from 'src/placebet/core'

const ImageWrapper = styled(CartIcon)(
  () => `
        width: 30px;
`
)

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
`
)

function TicketCartButton() {
  const theme = useTheme()
  const { t: translation } = useTranslation()

  const dispatch = useAppDispatch()

  return (
    <>
      <Tooltip arrow title={translation('CART')}>
        <IconButtonWrapper
          color="secondary"
          onClick={() => dispatch(toggleRightSidebar())}
          sx={{
            display: { lg: 'none', xs: 'inline-block' },
            mx: 1,
            background: alpha(theme.colors.error.main, 0.1),
            transition: `${theme.transitions.create(['background'])}`,
            color: theme.colors.error.main,

            '&:hover': {
              background: alpha(theme.colors.error.main, 0.2)
            }
          }}
        >
          <ImageWrapper />
        </IconButtonWrapper>
      </Tooltip>
    </>
  )
}

export default TicketCartButton
