import { gql } from '@apollo/client'
import {
  ProductWagerPayoff,
  ProductWagerPayoffDetail
} from 'src/placebet/core/models'

import { LeagueEvent } from '../../../core/models/core'

export interface HorseRaceLeagueEvent {
  id: number
  name: number
  date: Date
  eventDetail: LeagueEvent<EventDetails, EventResults>
  raceNumber: number
}

export interface Pool {
  [key: string]: string | boolean | number | PoolId | undefined
  poolId: PoolId
  poolNumber: number
  poolName: string
  poolRaces: number
  legs: number
  singleBetMinimum: number
  multipleBetMinimum: number
  wagerMinimum: number
  maximum: number
  wbMaximum: number
  raceList: string
  exchange?: number

  unordered?: boolean
  combine?: number
  quick?: boolean
  string?: boolean
  wheel?: boolean
  box?: boolean
  powerBox?: boolean
  leading?: boolean
  keyWheel?: boolean
  keyBox?: boolean
  parlay?: boolean
  probables?: boolean
  multiple?: boolean
  auto?: boolean
  segmented?: boolean
  straight?: boolean
}

export interface Entry {
  [key: string]: string | number | boolean
  id: string
  entryId: string
  name: string
  jockey: string
  weight: string
  trainer: string
  owner: string
  position: number
  medication: string
  scratch: boolean
}

export interface Runner {
  runnerId: number
  entries: Entry[]
  odds: string
  scratch: boolean
}

export interface Race {
  pools: Pool[]
  runners: Runner[]
  raceId: number
  raceStatus: number
  trackType: number
  current: boolean
  postTime: Date
  numberOfRunners: number
  program: boolean
  odds: boolean
  raceType: string
  surface: string
  distance: string
  purse: number
  age: string
  live: string
  poolList: string
  sex: string
  claim?: number
}

export interface EventResults {
  eventId?: string
  eventName?: string
  raceId?: number
  runId?: string
  groupId?: string
  currencyId?: string
  raceResults?: RaceResultDetails
  poolPrices?: PoolPrice[]
}

export interface RaceResultDetails {
  positions?: Position[]
  winners?: string
  scratches?: string
}

export interface Position {
  finishers?: Finisher[]
  positionId?: number
}

export interface Finisher {
  runnerId?: number
  entryId?: string
  name?: string
  jockey?: string
}

export interface PoolPrice {
  prices?: Price[]
  poolId?: string
  poolName?: string
  refund?: boolean
  carryOver?: number
}

export interface Price {
  finish?: string
  exchange?: boolean
  hold?: boolean
  winnings?: number
  priceAmount?: number
  wager?: number
  required?: string
  payoff?: number
}

export interface EventDetails extends Race {}

// Ticket

export interface ManualWagerRequestInput {
  id?: string
  expression?: string
  ticketId?: string
  quick?: boolean
}

export interface ProductWagerRequestInput {
  ticketId?: string
  eventId: string
  typeBet: PoolId
  race: number
  legs: Leg[]
  bet?: Number
  modifier?: WagerModifier
  quick?: boolean
}

export interface GenericIdValuePair<TKey> {
  id: TKey
  value: string
}

export interface Leg {
  raceId: number
  runners: number[]
}

export interface Legs {
  [key: string]: Leg
}

export interface RunnersLookup {
  [key: number]: Runner[]
}

export type InputState = 'Legs' | 'Amount'

export interface Action {
  display?: string
  value: string
}

export interface HoreRaceAction extends Action {
  key: string
  checked: boolean
}

export interface ProviderError {
  number: string
  message: string
}

export interface PayoffDetail extends ProductWagerPayoffDetail {}

export interface WagerPayoff extends ProductWagerPayoff {}

//
export interface BeginHorseRacePlayRequest {
  eventId: string
  raceId: string
  poolId: string
  runnerId: number
}

export interface BeginHorseRacePlayResponse {
  wageTrasactionId: string
}

export interface HorseRacePlayAddRunnerRequest {
  wageTrasactionId: string
  runnerId: number
}

export interface HorseRacePlayWageRequest {
  wageTrasactionId: string
  wage: number
}

export const EVENT_DETAIL_FIELDS = gql`
  fragment EventDetailsFields on Race {
    runners {
      runnerId
      odds
      scratch
      entries {
        id
        entryId
        jockey
        medication
        name
        owner
        position
        trainer
        weight
        scratch
      }
    }
    raceId
    raceStatus
    trackType
    current
    postTime
    numberOfRunners
    program
    odds
    raceType
    surface
    distance
    purse
    age
    live
    poolList
    sex
    claim
    pools {
      box
      exchange
      keyWheel
      leading
      legs
      maximum
      singleBetMinimum
      multipleBetMinimum
      wagerMinimum
      poolId
      poolName
      poolNumber
      poolRaces
      quick
      raceList
      combine
    }
  }
`

export const supportedPoolIds = [
  'WIN',
  'PLC',
  'SHW',
  'WIN/PLC/SHW',
  'WS',
  'PS',
  'WPS',
  'EXA',
  'QU',
  'TT',
  'TS',
  'DD',
  'TRI',
  'SPR',
  'PEN',
  'HEX',
  'OMN',
  'BQ',
  'BP',
  'DE',
  'QD',
  'PK3',
  'PK4',
  'PK5',
  'PK6',
  'PK7',
  'PK8',
  'PK9',
  'PK10',
  'PK11',
  'PK12'
] as const

export type PoolId = typeof supportedPoolIds[number] | undefined

export const positionsMap = [
  '1RO',
  '2DO',
  '3RO',
  '4TO',
  '5TO',
  '6TO',
  '7MO',
  '8VO',
  '9NO',
  '10MO'
] as const

export type FinishAt = typeof positionsMap[number] | 'N/A'

export type WagerModifier =
  | 'NONE'
  | 'WHEEL'
  | 'BOX'
  | 'POWER_BOX'
  | 'KEY_BOX'
  | 'KEY_WHEEL'
  | 'LEADING'
  | 'UNKNOWN'

export interface AllowedWager {
  [key: string]: number
}

//export const mergedWinPlaceShow: PoolId[] = ['WIN', 'PLC', 'SHW']
export const mergedWinPlaceShow: PoolId[] = []

export interface RaceTrackI {
  typeBetName: string
  tickets: number
  betsQuantity: number
  amount: number
  fee: number
}
