import { ActionType, createAction } from 'typesafe-actions'
import { KeyboardMode, ToolbarAction } from './context'

// Keyboard Actions
export const keyPressed = createAction('KEY_PRESSED')<{
  key: string
  mapped: boolean
  nonce?: number
}>()

export const setKeyboardMode = createAction('SET_KEYBOARD_MODE')<{
  mode: KeyboardMode
  command?: string
}>()

export const triggerModalCommand = createAction(
  'TRIGGER_MODAL_COMMAND'
)<string>()

export const beginToolbarAction = createAction(
  'BEGIN_TOOLBAR_ACTION'
)<ToolbarAction>()
export const completeToolbarAction = createAction('COMPLETE_TOOLBAR_ACTION')()

export const submitModalCommand = createAction('SUBMIT_MODAL_COMMAND')<{
  modalCommand: string
  value: string
}>()

export const disableCustomKeyboardMapping = createAction(
  'DISABLE_KEYBOARD_MAPPING'
)()

export const enableCustomKeyboardMapping = createAction(
  'ENABLE_KEYBOARD_MAPPING'
)()

export const pasteValue = createAction('PASTE_VALUE')<string>()

export const navigateToAction =
  createAction('NAVIGATE_TO_ACTION')<ToolbarAction>()

export type Actions =
  | ActionType<typeof keyPressed>
  | ActionType<typeof setKeyboardMode>
  | ActionType<typeof pasteValue>
  | ActionType<typeof triggerModalCommand>
  | ActionType<typeof disableCustomKeyboardMapping>
  | ActionType<typeof enableCustomKeyboardMapping>
  | ActionType<typeof submitModalCommand>
  | ActionType<typeof beginToolbarAction>
  | ActionType<typeof completeToolbarAction>
  | ActionType<typeof navigateToAction>
