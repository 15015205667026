import AppLayout from '../components/AppLayout'

import hipismoRoutes from '../products/horseraces/routes'
import cockfightsRoutes from '../products/cockfights/routes'
import { normalizeProductRouteParam } from '../core'

const placebetRoutes = [
  {
    path: `${normalizeProductRouteParam('HORSE_RACES')}`,
    element: <AppLayout product="HORSE_RACES" />,
    children: hipismoRoutes
  },
  {
    path: `${normalizeProductRouteParam('COCKFIGHTS')}`,
    element: <AppLayout product="COCKFIGHTS" />,
    children: cockfightsRoutes
  }
]

export default placebetRoutes
