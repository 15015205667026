import { ProductEnum } from '../services'
import { WalletAccount } from './core'

export const currencies = ['DOP', 'USD'] as const

export type CurrencyTypeEnum = typeof currencies[number]

export type WorkstationTypeEnum = 'None' | 'Terminal' | 'Mobile' | 'Kiosk'

export interface Profile {
  id: string
  emailVerified: string
  name: string
  familyName: string
  givenName: string
  middleName: string
  nickname: string
  preferredUsername: string
  picture: string
  gender: string
  birthdate: string
  zoneInfo: string
  locale: string
  updatedAt: string
  phoneNumber: string
  phoneNumberVerified: string
  email: string
}

export interface ProfileAccounts extends Profile {
  accounts: WalletAccount[]
}

export interface Enterprise {
  id: string
  name: string
  domain: string
  subdomain: string
  logo: string
  slogan: string
  contacts: Contact[]
  addresses: EntityAddress[]
  active: boolean
}

export type ContactTypeEnum =
  | 'Phone'
  | 'Email'
  | 'Tweeter'
  | 'Instagram'
  | 'Facebook'

export interface Contact {
  type: ContactTypeEnum
  main: boolean
  name: string
  value: string
}

export interface EntityAddress {
  address: string
  city: string
  state: string
  zip: number
  main: boolean
}

export interface Agency {
  id: string
  name: string
  enterprise: Enterprise
  address: EntityAddress
  products: ProductCode[]
}

export interface ProductCode {
  name: ProductEnum
  code: string
  providerCode: string
}

export interface League {
  id: string
  name: string
  abbreviation: string
  key: string
  product: ProductEnum
  sport: string
  keyboardCode: string
  order: number | null
  active: boolean
  live: boolean
}

export interface Enterprise {
  name: string
}

export interface Workstation {
  id: string
  type: WorkstationTypeEnum
  name: string
  agency: Agency
  device: string
  accessList: string[]
}

export interface Device {
  id: string
  name: string
  uniqueId: string
  ipAddress: string
  metadata: DeviceMetadata
  active: boolean
}

export interface DeviceMetadata {
  browserName: string
  browserVersion: string
  device: string
  incognito: boolean
  osVersion: string
  ipLocation: IpLocation
}

export interface IpLocation {
  city: IpLocationSegment
  continent: IpLocationSegment
  country: IpLocationSegment
}

export interface IpLocationSegment {
  code: string
  name: string
}
