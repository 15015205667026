import { Box, Stack, styled } from '@mui/material'

import { useTranslation } from 'react-i18next'
import {
  currentUtcDate,
  customFormatLocalDate,
  formatAsCurrency,
  formatShortDate,
  WalletTransaction
} from 'src/placebet/core'
import Header from '../components/Header'
import TicketListItem from '../../TicketsSidebarCore/TicketListItem'
import { ReceiptTypography } from '../components/styled'
import Footer from '../components/Footer'
import {
  SalesCategoryItem,
  WorkstationSalesByCategory
} from 'src/placebet/core/api/productApi/models'

export const DashedDivider = styled('hr')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  border: `1px dashed #ccc`
}))

interface DepositReceiptProps extends WalletTransaction {
  showBardCode?: boolean
}

const SalesSummaryReceipt = ({
  product,
  date,
  currency,
  workstation,
  categories
}: WorkstationSalesByCategory) => {
  const { t } = useTranslation()

  return (
    <Stack
      display="flex"
      sx={{
        width: '100%',
        p: 1
      }}
      direction={'column'}
      spacing={0}
    >
      <Header
        product={product}
        workstation={workstation}
        date={customFormatLocalDate(date, 'DD/MM/YYYY')}
      />

      <DashedDivider />
      <Stack
        display={'flex'}
        direction={'column'}
        spacing={1.5}
        sx={{ mt: 2, width: '100%', p: 0.2 }}
      >
        {Object.keys(categories).map((key) => (
          <Box key={key}>
            <ReceiptTypography>{t(key)}</ReceiptTypography>
            <hr />
            <Stack
              display={'flex'}
              spacing={0.5}
              direction={'column'}
              sx={{ width: '100%', p: 0 }}
            >
              {categories[key].map((item: SalesCategoryItem) => (
                <TicketListItem
                  key={item.title}
                  fontSize={13}
                  typographyColor={'black'}
                  variant="h5"
                  primary={
                    <Stack spacing={1} direction={'row'}>
                      <ReceiptTypography>{t(item.title)}</ReceiptTypography>
                      {item.count && item.count > 0 ? (
                        <ReceiptTypography>({item.count})</ReceiptTypography>
                      ) : (
                        ''
                      )}
                    </Stack>
                  }
                  secondary={
                    <ReceiptTypography>
                      {formatAsCurrency(item.amount || 0, 2)}
                    </ReceiptTypography>
                  }
                />
              ))}
            </Stack>
          </Box>
        ))}
      </Stack>

      <DashedDivider />
      <ReceiptTypography>{currentUtcDate()}</ReceiptTypography>
    </Stack>
  )
}

export default SalesSummaryReceipt
