import { Stack, styled } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { formatAsCurrency, WalletTransaction } from 'src/placebet/core'
import Header from '../components/Header'
import TicketListItem from '../../TicketsSidebarCore/TicketListItem'
import { ReceiptTypography } from '../components/styled'
import Footer from '../components/Footer'

export const DashedDivider = styled('hr')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  border: `1px dashed #ccc`
}))

interface WithdrawalReceiptProps extends WalletTransaction {
  showBardCode?: boolean
}

const WithdrawalReceipt = ({
  showBardCode,
  ...transaction
}: WithdrawalReceiptProps) => {
  const { t } = useTranslation()

  const {
    id,
    number,
    currency,
    amount,
    balance,
    completedDate,
    account: {
      number: accountNumber,
      provider: { name: providerName },
      product
    },
    workstation
  } = transaction

  return (
    <Stack
      display="flex"
      sx={{
        width: '100%',
        p: 1
      }}
      direction={'column'}
      spacing={0}
    >
      <Header
        id={number}
        product={product}
        date={completedDate}
        workstation={workstation}
      />

      <DashedDivider />
      <Stack
        display={'flex'}
        direction={'column'}
        spacing={0.5}
        sx={{ width: '100%', p: 0.2 }}
      >
        <TicketListItem
          fontSize={13}
          typographyColor={'black'}
          variant="h5"
          primary={
            <Stack spacing={1} direction={'row'}>
              <ReceiptTypography>{providerName}</ReceiptTypography>
            </Stack>
          }
        />
        <TicketListItem
          fontSize={13}
          typographyColor={'black'}
          variant="h5"
          primary={
            <Stack spacing={1} direction={'row'}>
              <ReceiptTypography>{accountNumber}</ReceiptTypography>
            </Stack>
          }
        />
        <TicketListItem
          fontSize={13}
          typographyColor={'black'}
          variant="h5"
          primary={
            <Stack spacing={1} direction={'row'}>
              <ReceiptTypography>{t('RETIRADO')}</ReceiptTypography>
              <ReceiptTypography>{`(${currency || 'DOP'})`}</ReceiptTypography>
            </Stack>
          }
          secondary={formatAsCurrency(amount || 0, 2)}
        />
        <TicketListItem
          fontSize={13}
          typographyColor={'black'}
          variant="h5"
          primary={
            <Stack spacing={1} direction={'row'}>
              <ReceiptTypography>{t('BALANCE')}</ReceiptTypography>
              <ReceiptTypography>{`(${currency || 'DOP'})`}</ReceiptTypography>
            </Stack>
          }
          secondary={formatAsCurrency(balance || 0, 2)}
        />
      </Stack>

      <Footer
        showBardCode={showBardCode}
        identifier={number}
        disclaimer={t('DISCLAIMER_WITHDRAWAL')}
      />
    </Stack>
  )
}

export default WithdrawalReceipt
