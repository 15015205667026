import { Box, Divider, Stack } from '@mui/material'
import TicketCartButton from './Cart'
import ActionButton from './ActionButton'

import PaidIcon from '@mui/icons-material/EmojiEventsTwoTone'
import VoidIcon from '@mui/icons-material/MoneyOffTwoTone'
import DrawIcon from '@mui/icons-material/CreditScoreTwoTone'
import PayDrawIcon from '@mui/icons-material/CreditCardOffTwoTone'
import TicketsIcon from '@mui/icons-material/ConfirmationNumberTwoTone'
import SalesSummaryReportIcon from '@mui/icons-material/Assessment'
import UpcomingIcon from '@mui/icons-material/CalendarTodayTwoTone'
import DepositIcon from '@mui/icons-material/GetApp'
import WithdrawIcon from '@mui/icons-material/FileUpload'

import NavigationButton from './NavigationButton'
import { t } from 'i18next'
import { isMobile, useUserClaims } from 'src/placebet/core'
import HeaderUserbox, { UserMenuOption } from '../Userbox'

import SettingIcon from '@mui/icons-material/Settings'

function HeaderButtons() {
  const user = useUserClaims()

  const options: UserMenuOption[] = [
    {
      id: 'my_account',
      label: 'MI_CUENTA',
      icon: SettingIcon
      //handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    },
    {
      id: 'preferences',
      label: 'PREFERENCIAS',
      icon: SettingIcon
      //handleClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
    }
  ]

  return (
    <Stack direction={'row'} display={'contents'} spacing={1} sx={{ mr: 2 }}>
      {/* <HeaderNotifications />
      <LanguageSwitcher /> */}
      <NavigationButton
        path=""
        title={t('PROXIMOS')}
        mappedKey="NextEvents"
        icon={UpcomingIcon}
      />
      <NavigationButton
        path="tickets"
        title={t('TICKETS')}
        mappedKey="Tickets"
        icon={TicketsIcon}
      />
      {!user.isCustomer && (
        <>
          <Divider orientation="vertical" flexItem />
          <NavigationButton
            path="reports/sales-resume"
            title={t('SALES')}
            mappedKey="SalesSummary"
            icon={SalesSummaryReportIcon}
          />
          <Divider orientation="vertical" flexItem />
          <ActionButton action="Draw" icon={DrawIcon} title={t('DRAW')} />
          <ActionButton
            action="PayDraw"
            icon={PayDrawIcon}
            title={t('PAYDRAW')}
          />
          <Divider orientation="vertical" flexItem />
          <ActionButton
            action="Pay"
            icon={PaidIcon}
            title={t('PAY')}
            disabledAt="tickets"
          />
          <ActionButton
            action="Void"
            icon={VoidIcon}
            title={t('VOID')}
            disabledAt="tickets"
          />
          <Divider orientation="vertical" flexItem />
          <ActionButton
            action="Deposit"
            variant="Command"
            icon={DepositIcon}
            title={t('DEPOSIT')}
          />
          <ActionButton
            action="Withdraw"
            icon={WithdrawIcon}
            title={t('WITHDRAW')}
          />
        </>
      )}

      {isMobile() ? (
        <>
          <HeaderUserbox options={options} />
          <Divider orientation="vertical" flexItem />
          <TicketCartButton />
        </>
      ) : (
        <>
          <TicketCartButton />
          <Divider orientation="vertical" flexItem />
          <HeaderUserbox options={options} />
        </>
      )}
    </Stack>
  )
}

export default HeaderButtons
