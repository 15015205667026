export type CoreServices = 'idp-service' | 'core-services'

export type ProductEnum =
  | 'SPORTS'
  | 'COCKFIGHTS'
  | 'HORSE_RACES'
  | 'NONE'
  | 'ALL'

type EnvSettingsType =
  | 'REACT_APP_PB_AUTH_URL'
  | 'REACT_APP_PB_AUTH_SCOPE'
  | 'REACT_APP_PB_AUTH_CLIENT_ID'
  | 'REACT_APP_PB_API_TIMEOUT'
  | 'REACT_APP_PB_CDN_BASE_URL'
  | 'REACT_APP_PB_FINGERPRINT_PRO_KEY'
  | 'REACT_APP_PB_DEV_FINGERPRINT'
  | 'REACT_APP_PB_GRAPH_BASE_URL'
  | 'REACT_APP_PB_HORSE_RACES_BOARD_URL'
  | 'REACT_APP_PB_COCKFIGHTS_BOARD_URL'
  | 'REACT_APP_PB_AUTH_AUTOMATIC_SILENT_RENEW'
  | 'REACT_APP_PB_AUTH_SILENT_REQUEST_TIMEOUT'
  | 'REACT_APP_PB_AUTH_REDIRECT_URI'
  | 'REACT_APP_PB_AUTH_SILENT_REDIRECT_URI'
  | 'REACT_APP_PB_AUTH_POST_LOGOUT_REDIRECT_URI'

export const getEnvSettings = () => {
  const envSettings: Record<EnvSettingsType | any, string | undefined> = {
    REACT_APP_PB_AUTH_URL: 'https://idp.microsuave.com',
    REACT_APP_PB_AUTH_SCOPE:
      'openid profile placebet-pos placebet-administration',
    REACT_APP_PB_AUTH_CLIENT_ID: 'placebet-app',
    REACT_APP_PB_API_TIMEOUT: '30000',
    REACT_APP_PB_CDN_BASE_URL:
      'https://teamsimage.azureedge.net/leagueteamsimagebyname',
    REACT_APP_PB_FINGERPRINT_PRO_KEY: 'UQreLvVEdQFIupF6P2FP',
    REACT_APP_PB_GRAPH_BASE_URL: 'https://router.microsuave.com',
    REACT_APP_PB_HORSE_RACES_BOARD_URL:
      'https://horseraces-board.microsuave.com',
    REACT_APP_PB_COCKFIGHTS_BOARD_URL:
      'https://cockfights-board.microsuave.com',
    REACT_APP_PB_DEV_FINGERPRINT: 'dOpwsfqSoo97iOvCHPXS'
  }

  var buildSettings = (window as any)['_env_'] as any

  Object.keys(envSettings).forEach((key) => {
    const value = buildSettings[key] || process.env[key]
    if (value) {
      envSettings[key] = value
    }
  })

  return envSettings
}

export const getProductBoardUrl = (product: ProductEnum) => {
  const settings = getEnvSettings()
  return settings[`REACT_APP_PB_${product.toLocaleUpperCase()}_BOARD_URL`] as
    | string
    | undefined
}
