import { useCallback, useContext, useEffect, useState } from 'react'
import * as Signalr from '@microsoft/signalr'

import { BroadcastContent, SyncEvent } from '../models/core'
import { getAccessToken } from 'pb-shared'
import { getProductBoardUrl, useAppSelector } from '..'

const reconnectPolicy: Signalr.IRetryPolicy = {
  nextRetryDelayInMilliseconds: () => 5000
}

export type ServerEventChannels = 'ServerDateTime' | 'SyncContent'

export type HubResponseType =
  | Date
  | BroadcastContent<any>
  | SyncEvent
  | string
  | undefined

export type ConnectionStatus = 'Disconnected' | 'Connecting' | 'Connected'

export interface SignalrChannelConnection<T> {
  status: ConnectionStatus
  broadcastResults?: T
  error?: Error
  disconnect?: () => void
}

export function useSignalR<T>(channel: string): SignalrChannelConnection<T> {
  const { product } = useAppSelector((state) => state.appContext)
  const [hubConnection, setHubConnection] = useState<Signalr.HubConnection>()
  const [broadcastResults, setBroadcastResults] = useState<T>()
  const [error, setError] = useState<Error | undefined>()
  const [status, setStatus] = useState<ConnectionStatus>('Disconnected')

  const boardUrl = getProductBoardUrl(product)
  useEffect(() => {
    if (!channel) return

    // Set the initial SignalR Hub Connection.
    const createHubConnection = async () => {
      const hubConnect = new Signalr.HubConnectionBuilder()
        .withUrl(`${boardUrl}/placebetHub`, {
          skipNegotiation: true,
          transport: Signalr.HttpTransportType.WebSockets,
          accessTokenFactory: () => getAccessToken() || ''
        })
        .withAutomaticReconnect(reconnectPolicy)
        .configureLogging(Signalr.LogLevel.Trace)
        .build()
      try {
        await hubConnect.start()

        setStatus('Connected')

        hubConnect.invoke('subscribe', channel)

        hubConnect.on('update', (data: T) => {
          console.log(`Updating channel: ${channel}`)
          // console.log(data)
          setBroadcastResults(data)
        })

        hubConnect.onreconnecting(() => {
          setStatus('Connecting')
          console.log(`Reconecting to channel ${channel}`)
        })

        hubConnect.onreconnected(() => {
          setStatus('Connected')
          console.log(`Reconected to channel ${channel}`)
        })
      } catch (err: unknown) {
        setError(new Error(`Error connecting to hub: ${err}`))
        setStatus('Disconnected')
        console.log(`Disconnected from channel ${channel}`)
        console.log(err)
      }

      setHubConnection(hubConnect)
    }

    createHubConnection()
  }, [boardUrl, channel])

  const disconnect = useCallback(() => {
    hubConnection?.invoke('disconnect', channel)
    hubConnection?.stop()
  }, [channel, hubConnection])

  return {
    status,
    broadcastResults,
    error,
    disconnect
  }
}
