import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import localization from './localization'

// the translations
// (tip move them in a JSON file and import them)
// const resources = {
//   en: {
//     translation: {
//       key_1: 'Placebet'
//     }
//   }
// }

const xhrBackendOptions = {
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  loadPath: () => {
    return `http://localhost:62424/locales/{{lng}}`
  },
  // path to post missing resources
  addPath: 'locales/add/{{lng}}',

  // your backend server supports multiloading
  // /locales/resources.json?lng=de+en&ns=ns1+ns2
  // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
  // Returned JSON structure in this case is
  // {
  //  lang : {
  //   namespaceA: {},
  //   namespaceB: {},
  //   ...etc
  //  }
  // }
  allowMultiLoading: true, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

  // allow cross domain requests
  crossDomain: true,

  // allow credentials on cross domain requests
  withCredentials: true,

  // overrideMimeType sets request.overrideMimeType("application/json")
  overrideMimeType: true

  // custom request headers sets request.setRequestHeader(key, value)
  // customHeaders: {
  //   authorization: 'foo'
  //   // ...
  // }
}

i18n
  //.use(XHR)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: 'v3',
    lng: 'es-US',
    fallbackLng: 'es-US',
    debug: process.env.NODE_ENV !== 'production',
    preload: ['es-US'],
    resources: localization,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    // backend: xhrBackendOptions,
    react: {
      useSuspense: false
    }
  })

export default i18n
