import FinalIcon from '@mui/icons-material/SportsScoreOutlined'
import OpenIcon from '@mui/icons-material/AlarmOutlined'
import ClosedIcon from '@mui/icons-material/AlarmOffOutlined'
import PostIcon from '@mui/icons-material/AlarmOnOutlined'
import CancelIcon from '@mui/icons-material/CancelOutlined'
import LockedIcon from '@mui/icons-material/LockOutlined'
import UnCancelIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import UnOfficialIcon from '@mui/icons-material/AdjustOutlined'
import BeginIcon from '@mui/icons-material/FlagOutlined'
import { EventStatus } from 'src/placebet/core'

export const getStatusIcon = (status: EventStatus) => {
  switch (status) {
    case 'Begin':
      return BeginIcon
    case 'Cancelled':
      return CancelIcon
    case 'Closed':
      return ClosedIcon
    case 'Final':
      return FinalIcon
    case 'Locked':
      return LockedIcon
    case 'Open':
      return OpenIcon
    case 'Post':
      return PostIcon
    case 'UnCancel':
      return UnCancelIcon
    case 'UnOfficial':
      return UnOfficialIcon
    default:
      return null
  }
}
