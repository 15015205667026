import {
  Avatar,
  Box,
  Paper,
  PaperProps,
  styled,
  Table,
  TableHead
} from '@mui/material'
import { red } from '@mui/material/colors'

export const DotLegend = styled('span')(
  ({ theme }) => `
      border-radius: 22px;
      width: ${theme.spacing(0.75)};
      height: ${theme.spacing(0.75)};
      display: inline-block;
      margin-right: ${theme.spacing(1)};
      margin-top: -${theme.spacing(0.1)};
  `
)

export const CardActions = styled(Box)(
  ({ theme }) => `
      position: absolute;
      right: ${theme.spacing(2)};
      top: ${theme.spacing(2)};
      z-index: 7;
    `
)

export const LabelInfo = styled(Box)(
  ({ theme }) => `
      display: inline-block;
      background: ${theme.palette.info.main};
      color: ${theme.palette.info.contrastText};
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(10)};
      font-weight: bold;
      line-height: 23px;
      height: 22px;
      padding: ${theme.spacing(0, 2)};
      border-radius: ${theme.general.borderRadiusSm};
    `
)

export const TableHeadWrapper = styled(TableHead)(
  ({ theme }) => `
          .MuiTableCell-root {
              text-transform: none;
              font-size: ${theme.typography.pxToRem(15)};
              padding-top: 0;
              padding-bottom: ${theme.spacing(1)};
              color: ${theme.colors.alpha.black[100]};
          }
    
          .MuiTableRow-root {
              background: transparent;
          }
      `
)

export const TableWrapper = styled(Table)(
  () => `
        .MuiTableCell-root {
            border-bottom: 0;
        }
      `
)

export const BoxItemWrapper = styled(Box)(
  ({ theme }) => `
        border-radius: ${theme.general.borderRadius};
        background: ${theme.colors.alpha.black[5]};
        position: relative;
        padding: ${theme.spacing(2)};
        width: 100%;
        color: ${theme.palette.primary.dark};
        
        &::before {
          content: '.';
          background: ${theme.colors.error.main};
          color: ${theme.colors.error.main};
          border-radius: ${theme.general.borderRadius};
          position: absolute;
          text-align: center;
          width: 6px;
          left: 0;
          height: 100%;
          top: 0;
        }
        
        &.wrapper-info {
          &:before {
            background: ${theme.colors.info.dark};
            color: ${theme.colors.info.dark};
          }
        }
            
        &.wrapper-warning {
          &:before {
            background: ${theme.colors.warning.main};
            color: ${theme.colors.warning.main};
          }
        }
    `
)

export const Bullet = styled((props: PaperProps) => <Paper {...props} />)(
  ({ theme }) => ({
    height: '10px',
    width: '10px',
    borderRadius: '10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.dark,
    display: 'inline-block',
    margin: '0 8px'
  })
)
