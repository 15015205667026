import React from 'react'

const SplitDisplayValues: React.FC<{ pick: string }> = ({ pick }) => {
  const splitSpaces = pick.split('  ')

  if (splitSpaces.length > 1) {
    const typeBetName = splitSpaces[0]
    const picks = splitSpaces[1].split('/')
    picks.unshift(typeBetName)
    const pickNodes = picks.map((p, i) => <span key={i}>{p}</span>)
    return <div style={{ display: 'grid' }}>{pickNodes}</div>
  }

  return <>{pick}</>
}

export default SplitDisplayValues
