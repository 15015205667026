import React from 'react'
import { useAppSelector } from 'src/placebet/core'
import TicketSidebarCore from 'src/placebet/products/components/TicketsSidebarCore'
import {
  useRemoveHorseRacesTicketMutation,
  useSubmitHorseRacesTicketMutation
} from '../../../redux/horseRacesProductApi'
import TicketWagerCollection from './TicketWagerCollection/Index'
import WagerPreview from './WagerPreview'

const TicketSidebar = () => {
  const { event, ticket, busy } = useAppSelector((state) => state.horseRaces)
  const [removeTicket] = useRemoveHorseRacesTicketMutation()
  const [submitTicket] = useSubmitHorseRacesTicketMutation()

  return (
    <TicketSidebarCore
      event={event}
      ticket={ticket}
      removeTicket={removeTicket}
      submitTicket={submitTicket}
      wagerInputComponent={WagerPreview}
      wagerComponent={TicketWagerCollection}
      canSubmit={!busy && ticket && ticket.wagerCollections?.length > 0}
    />
  )
}

export default React.memo(TicketSidebar)
