import React, { useMemo } from 'react'
import { useAppSelector } from 'src/placebet/core'
import { HorseRacesSpecialKeyboard } from './definitions/HorseRacesSpecialKeyboard'
import { CockFightsSpecialKeyboard } from './definitions/CockFightsSpecialKeyboard'
import { KeyboardMapping } from './models'
import CustomKeyboard from './CustomKeyboard'

function CustomKeyboardProvider({ children }: { children: React.ReactNode }) {
  const { product } = useAppSelector((state) => state.appContext)

  const customKeyBoard = useMemo((): KeyboardMapping => {
    switch (product) {
      case 'HORSE_RACES':
        return HorseRacesSpecialKeyboard

      case 'COCKFIGHTS':
        return CockFightsSpecialKeyboard

      default:
        return { keys: {} }
    }
  }, [product])

  return customKeyBoard ? (
    <CustomKeyboard customKeyboard={customKeyBoard}>{children}</CustomKeyboard>
  ) : (
    <>{children}</>
  )
}

export default CustomKeyboardProvider
