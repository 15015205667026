import { Box, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import TooltipWrapper from 'src/placebet/components/TooltipWrapper'

const LogoWrapper = styled('div')(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 53px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
)

const Brand = styled('img')(({ theme }) => ({
  display: 'block',
  width: 'auto',
  height: 48,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  marginLeft: 'auto',
  marginRight: 'auto'
}))

function Logo() {
  const { t }: { t: any } = useTranslation()

  return (
    <TooltipWrapper title={t('PLACEBANK')} arrow>
      <LogoWrapper>
        <Brand
          alt="logo"
          src={`https://pbankpublicimages.blob.core.windows.net/logos/placebankwallet.svg`}
        />
        {/* <Badge
          sx={{
            '.MuiBadge-badge': {
              fontSize: theme.typography.pxToRem(11),
              right: -2,
              top: 8
            }
          }}
          overlap="circular"
          color="success"
          badgeContent="3.0"
        >
          <LogoSignWrapper>
            <LogoSign>
              <LogoSignInner />
            </LogoSign>
          </LogoSignWrapper>
        </Badge> */}
      </LogoWrapper>
    </TooltipWrapper>
  )
}

export default Logo
