import { Grid } from '@mui/material'
import { LeagueEvent } from 'src/placebet/core'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import React from 'react'
import { EventDetails, EventResults, Total } from '../../cockfights/models'
import { ReceiptTypography } from '../../components/recepits/components/styled'
import { DashedDivider } from 'src/placebet/products/components/recepits/TicketReceipt'
import { HeaderReceipt } from './components/HeaderReceipt'
import InfoHeader from './components/InfoHeader'
import { TruncateText } from 'src/placebet/components'

const OpenReceipt = ({
  events
}: {
  events: LeagueEvent<EventDetails, EventResults>[]
}) => {
  const { t }: { t: any } = useTranslation()
  const openEvents = events.filter((event) => event.eventStatus === 'Open')
  return (
    <>
      <InfoHeader />

      <Grid container spacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
        <HeaderReceipt />
        <DashedDivider />

        {openEvents.map((item, key) => {
          const { details, eventName } = item
          const {
            details: {
              columns: { moneyLine, total }
            }
          } = item
          const blue = moneyLine.blue
          const white = moneyLine.white

          return (
            <React.Fragment key={key}>
              <Grid
                container
                spacing={1}
                sx={{ paddingLeft: 1, paddingRight: 1 }}
              >
                <Grid item xs={1}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {details.blockNumber}
                  </ReceiptTypography>
                </Grid>

                <Grid item xs={1}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {eventName}
                  </ReceiptTypography>
                </Grid>

                <Grid item xs={6}>
                  <TruncateText style={{ width: '120px' }}>
                    <ReceiptTypography
                      variant="caption"
                      noWrap
                      sx={{ textTransform: 'none' }}
                    >
                      {details.teams.blue.name}
                    </ReceiptTypography>
                  </TruncateText>
                </Grid>

                <Grid item xs={2}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {blue.displayValue}
                  </ReceiptTypography>
                </Grid>

                <Grid item xs={2}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {total.spread > 0 ? total.spread : ''}
                  </ReceiptTypography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                sx={{ paddingLeft: 1, paddingRight: 1 }}
              >
                <Grid item xs={1} />
                <Grid item xs={1} />
                <Grid item xs={6}>
                  <TruncateText style={{ width: '120px' }}>
                    <ReceiptTypography
                      variant="caption"
                      noWrap
                      sx={{ textTransform: 'none' }}
                    >
                      {details.teams.white.name}
                    </ReceiptTypography>
                  </TruncateText>
                </Grid>

                <Grid item xs={2}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {white.displayValue}
                  </ReceiptTypography>
                </Grid>

                <Grid item xs={2}>
                  <ReceiptTypography
                    variant="caption"
                    noWrap
                    sx={{ textTransform: 'none' }}
                  >
                    {getTotalSymbol(total)}
                  </ReceiptTypography>
                </Grid>
              </Grid>
              <DashedDivider />
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
}

const getTotalSymbol = (total: Total): string => {
  const overValue = Number(total.over.displayValue)
  const underValue = Number(total.under.displayValue)

  if (overValue === underValue) {
    return total.over.displayValue
  }
  if (overValue < 0 && underValue < 0) {
    return overValue < underValue
      ? total.over.displayValue.replace('-', 'o')
      : total.under.displayValue.replace('-', 'u')
  }
  if (overValue < 0 && underValue > 0) {
    return total.over.displayValue.replace('-', 'o')
  }
  if (overValue > 0 && underValue < 0) {
    return total.under.displayValue.replace('-', 'u')
  }
  return total.over.displayValue.replace('-', 'u')
}

export default OpenReceipt
