import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { formatAsCurrency, formatTime } from 'src/placebet/core'
import { ActivityHistoryDetailContext } from '../state'

function ActivitiesDataGrid() {
  const { t }: { t: any } = useTranslation()

  const {
    state: { activities }
  } = useContext(ActivityHistoryDetailContext)

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center" size="small">
              {t('HORA')}
            </TableCell>
            <TableCell align="center" size="small">
              {t('MONTO')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((activity, index) => (
            <TableRow key={index}>
              <TableCell align="right" size="small">
                {formatTime(activity.time)}
              </TableCell>
              <TableCell align="right" size="small">
                {formatAsCurrency(activity.amount)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ActivitiesDataGrid
