import { t } from 'i18next'
import {
  useLazyDrawQuery,
  useLazyPayDrawQuery
} from 'src/placebet/core/api/productApi/productApi'
import { useWalletCompleteWithdrawalMutation } from 'src/placebet/core/api/walletApi/walletApi'
import KeyboardModalShortcutsCore from 'src/placebet/products/components/KeyboardModalShortcutsCore'
import {
  useCancelHorseRacesTicketMutation,
  usePayHorseRacesTicketMutation
} from '../../redux/horseRacesProductApi'
import { isPayTicketFormatValid, useAppDispatch } from 'src/placebet/core'
import { handleErrorMessage } from 'src/placebet/core/api/productApi/productSlice'

const KeyboardModalShortcuts = () => {
  const [payTicket] = usePayHorseRacesTicketMutation()
  const [cancelTicket] = useCancelHorseRacesTicketMutation()
  const [draw] = useLazyDrawQuery()
  const [payDraw] = useLazyPayDrawQuery()
  const [walletWithdraw] = useWalletCompleteWithdrawalMutation()
  const dispatch = useAppDispatch()

  const handlePayTicket = (ticketNumber: string) => {
    if (!isPayTicketFormatValid(ticketNumber)) {
      dispatch(handleErrorMessage(t('FORMATO_TICKET_INVALIDO')))
    } else {
      payTicket({ ticketNumber, allowProviderTicket: true })
    }
  }

  const handleCancelTicket = (ticketNumber: string) => {
    cancelTicket({ ticketNumber, allowProviderTicket: true })
  }

  const handleDraw = (amount: number) => {
    draw({ amount, product: 'HORSE_RACES', currency: 'DOP' })
  }

  const handlePayDraw = (amount: number) => {
    payDraw({ amount, product: 'HORSE_RACES', currency: 'DOP' })
  }

  return (
    <KeyboardModalShortcutsCore
      payTicket={handlePayTicket}
      cancelTicket={handleCancelTicket}
      drawCash={handleDraw}
      paybackCash={handlePayDraw}
    />
  )
}

export default KeyboardModalShortcuts
