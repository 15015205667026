import React from 'react'
import { useLottie } from 'lottie-react'
import animation from './empty-results.json'
import { Box } from '@mui/material'

const EmptyAnimation = () => {
  const { View } = useLottie({
    animationData: animation,
    height: '200px',
    style: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center'
    },
    loop: true,
    autoplay: true
  })

  return <Box sx={{ m: 'auto', width: '320px' }}>{View}</Box>
}

export default EmptyAnimation
