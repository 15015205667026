import { ReactElement, ReactNode, useEffect, useMemo, useReducer } from 'react'
import { ActivityHistoryDetailContext, initialState, reducer } from '.'
import { ActivityHistoryDetail, Filters } from '../models'

export const ActivityHistoryDetailProvider = ({
  children
}: {
  filters?: Filters
  activities?: ActivityHistoryDetail[]
  children: ReactElement | ReactNode
}) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState })

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return (
    <ActivityHistoryDetailContext.Provider value={contextValue}>
      {children}
    </ActivityHistoryDetailContext.Provider>
  )
}
