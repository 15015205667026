import { AuthContext } from "pb-shared"
import { useContext, useEffect, useState } from "react"
import { isUserCustomer, parseJwt } from "../../services"

export const useUserClaims = () => {
    const userContext = useContext(AuthContext)
    const [isCustomer, setIsCustomer] = useState(false)

    useEffect(() => {
        userContext
        .getUserManager()
        .getUser()
        .then((user) => {
            if (user) {
                const claims = parseJwt(user?.access_token)
                if (claims) {
                    setIsCustomer(isUserCustomer(claims))
                }
            }
        })
        .catch((e) => console.error(e))
    }, [AuthContext])
  
    return { isCustomer }
  }