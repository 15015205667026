import React, { useMemo } from 'react'
import { List, styled, Grid, Box, Divider, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { CockfightsWagerCollection } from '../../../models'
import { formatAsCurrency, customFormatDate, decode } from 'src/placebet/core'
import TicketListItem from '../../../../components/TicketsSidebarCore/TicketListItem'
import SplitDisplayValues from 'src/placebet/products/components/recepits/TicketReceipt/SplitDisplayValues'
import { DashedDivider } from 'src/placebet/products/components/recepits/TicketReceipt'
import { ReceiptTypography } from 'src/placebet/products/components/recepits/components/styled'

const MainWrapper = styled(Box)(
  ({ theme }) => `
          margin-top: ${theme.spacing(0)} 0;
          border-radius: {theme.general.borderRadius};
          padding: ${theme.spacing(0.25)};
    `
)

type ContentProps = {
  wagerCollection: CockfightsWagerCollection
  preview?: boolean
}

function WagerCollection({ wagerCollection, preview }: ContentProps) {
  const { t } = useTranslation()
  const {
    providerTicketId,
    securityCode,
    providerError,
    wagers,
    wagerPayoff,
    total,
    surcharge,
    event
  } = wagerCollection

  const {
    eventId,
    eventName,
    league: { name: leagueName }
  } = event

  const translateAll = (value: string) => {
    return value
      .split(' ')
      .map((token) => t(token))
      .join(' ')
  }

  return (
    <MainWrapper flexDirection={'column'}>
      <Box
        sx={{ py: 0.5 }}
        display="flex"
        alignItems="flex-start"
        flexDirection={'column'}
      >
        <ReceiptTypography variant="body2">{`${leagueName}`}</ReceiptTypography>
        {providerTicketId && (
          <ReceiptTypography
            variant="subtitle1"
            display="inline"
            align="right"
            textTransform={'uppercase'}
          >
            {!!preview
              ? `${providerTicketId}-*****`
              : `${providerTicketId}-${decode(securityCode || '')}`}
          </ReceiptTypography>
        )}
        <Divider />
      </Box>

      <List sx={{ width: '100%' }} disablePadding>
        {wagers &&
          wagers.map((wager, i) => {
            const { priceDisplayValue } = wager

            let displayValue: string | React.ReactElement = translateAll(
              wager.displayValue
            )
            if (wager.displayValue.includes('/')) {
              displayValue = <SplitDisplayValues pick={wager.displayValue} />
            }

            const spread = wager.spread
              ? `${wager.spread} ${t('MIN_ABREVIATION')}`
              : undefined

            return (
              <Box key={`wager-${wager.id}`} display={'flex'} sx={{ p: 0 }}>
                <TicketListItem
                  fontSize={13}
                  typographyColor="black"
                  variant="subtitle2"
                  primary={displayValue}
                  legend={spread}
                  secondary={priceDisplayValue}
                />
              </Box>
            )
          })}
      </List>

      {!!wagerPayoff && wagerPayoff?.payoff && (
        <>
          <DashedDivider />
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <ReceiptTypography
                variant="subtitle1"
                color="primary"
                align="left"
              >
                {wagerPayoff?.payoff?.sourceId}{' '}
                {customFormatDate(wagerPayoff?.payoff?.time)}
              </ReceiptTypography>
            </Grid>
            <Grid item xs={3}>
              <ReceiptTypography variant="subtitle1" color="primary" ml={-2}>
                {`${t('PAYOFF')}: ${formatAsCurrency(
                  wagerPayoff?.payoff?.net || 0,
                  2
                )}`}
              </ReceiptTypography>
            </Grid>
          </Grid>
        </>
      )}

      {!!providerError && !!providerError.message && (
        <div>
          <DashedDivider style={{ marginTop: 0, marginBottom: 0 }} />
          <ReceiptTypography variant="body1" color="error">
            {`${t('ERROR')}: ${t(providerError.message)}`}
          </ReceiptTypography>
        </div>
      )}

      <>
        <DashedDivider style={{ marginTop: 0, marginBottom: 0 }} />
        {surcharge > 0 && (
          <Box display={'flex'} sx={{ p: 0 }}>
            <TicketListItem
              fontSize={13}
              typographyColor="black"
              variant="subtitle2"
              primary={t('SURCHARGE') as string}
              secondary={formatAsCurrency(surcharge, 2)}
            />
          </Box>
        )}
        <Box display={'flex'} sx={{ py: 0.5 }}>
          <TicketListItem
            fontSize={13}
            typographyColor="black"
            variant="subtitle2"
            primary={t('TOTAL') as string}
            secondary={formatAsCurrency(total, 2)}
          />
        </Box>
      </>
    </MainWrapper>
  )
}

export default WagerCollection
