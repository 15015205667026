import { gql, useMutation } from '@apollo/client'

const REGISTER_WORKSTATION = gql`
  mutation RegisterWorkstation($registrationCode: String!) {
    registerWorkstation(registrationCode: $registrationCode) {
      registrationCode
    }
  }
`
interface RegistrationState {
  registrationCode?: string
}

export const useWorkstationRegistration = () => {
  const mutation = useMutation<RegistrationState>(REGISTER_WORKSTATION)
  const [registerWorkstation, { loading, error }] = mutation

  return { registerWorkstation, loading, error }
}
