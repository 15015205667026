import { useState } from 'react'

export function useSessionStorage<T>(key: string, initialValue?: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key)
      if (item) {
        return JSON.parse(item)
      }

      if (initialValue) {
        // if the key does not exist set to default
        window.sessionStorage.setItem(key, JSON.stringify(initialValue))
      }

      // Parse stored json or if none return initialValue
      return initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }

  const clear = () => {
    window.sessionStorage.removeItem(key)
  }

  return { storedValue, setValue, clear } as const
}
