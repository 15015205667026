import { useRoutes } from 'react-router-dom'
import router from 'src/router'

import { SnackbarProvider } from 'notistack'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { CssBaseline } from '@mui/material'
import ThemeProvider from '../theme/ThemeProvider'

function App() {
  const content = useRoutes(router)

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          preventDuplicate
          maxSnack={2}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <CssBaseline />
          {content}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
export default App
