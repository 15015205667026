import { gql } from '@apollo/client'
import {
  CORE_TICKET_FIELDS,
  WAGER_PAYOFF_FRAGMENT
} from 'src/placebet/core/graph/fragments'

export const LEAGUE_EVENT = gql`
  fragment HorseRacesEvent on HorseRacesEvent {
    id
    eventId
    eventName
    eventDate
    eventTime
    eventStatus
    leagueId
    league {
      id
      name
      key
    }
  }
`
export const EVENT_RESULTS = gql`
  fragment EventResults on HorseRacesEventResults {
    eventId
    eventName
    raceId
    runId
    groupId
    currencyId
    raceResults {
      winners
      scratches
      positions {
        finishers {
          runnerId
          entryId
          name
          jockey
        }
        positionId
      }
    }
    poolPrices {
      poolId
      poolName
      refund
      carryOver
      prices {
        finish
        exchange
        hold
        winnings
        priceAmount
        wager
        required
        payoff
      }
    }
  }
`
export const EVENT_DETAILS = gql`
  fragment EventDetails on HorseRacesEventDetails {
    poolList
    raceId
    pools {
      box
      exchange
      keyWheel
      leading
      legs
      maximum
      singleBetMinimum
      multipleBetMinimum
      wagerMinimum
      poolId
      poolName
      poolNumber
      poolRaces
      quick
      raceList
      combine
    }
    runners {
      runnerId
      scratch
      odds
      entries {
        id
        name
        entryId
        jockey
        medication
        owner
        position
        scratch
        trainer
        weight
      }
    }
    live
    odds
    postTime
    numberOfRunners
    raceStatus
    purse
    claim
    age
  }
`

// Ticket

export const WAGER_FRAGMENT = gql`
  fragment HorseRacesWagerFragment on HorseRacesWager {
    id
    date
    poolId
    combine
    race
    pick
    legs {
      raceId
      runners {
        runnerId
        entries {
          id
          trainer
          jockey
          name
          position
          __typename
        }
        odds
        scratch
        __typename
      }
      __typename
    }
    bet
    modifier
    quick
    takeDown
    displayValue
    wagerCombinations
    total
    commission
    __typename
  }
`
export const EVENT_FRAGMENT = gql`
  fragment HorseRacesEventFragment on HorseRacesEvent {
    eventId
    eventName
    league {
      name
      __typename
    }
    __typename
  }
`
export const WAGER_COLLECTION_FRAGMENT = gql`
  fragment HorseRacesWagerCollectionFragment on HorseRacesWagerCollection {
    id
    race
    postTime
    providerTicketId
    securityCode
    total
    surcharge
    providerError {
      number
      message
      __typename
    }
    event {
      ...HorseRacesEventFragment
    }
    wagers {
      ...HorseRacesWagerFragment
    }
  }
`
export const TICKET_FIELDS = gql`
  ${CORE_TICKET_FIELDS}
  ${WAGER_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${WAGER_COLLECTION_FRAGMENT}
  fragment TicketFields on Ticket {
    ...CoreTicketFields
    horseRacesWagerCollections {
      ...HorseRacesWagerCollectionFragment
    }
    __typename
  }
`
export const TICKET_FIELDS_WITH_PAYOFF = gql`
  ${CORE_TICKET_FIELDS}
  ${WAGER_PAYOFF_FRAGMENT}
  ${WAGER_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${WAGER_COLLECTION_FRAGMENT}
  fragment TicketFieldsWithPayoff on Ticket {
    ...CoreTicketFields
    horseRacesWagerCollections {
      ...HorseRacesWagerCollectionFragment
      wagerPayoff {
        ...WagerPayoffFragment
      }
    }
    __typename
  }
`
//

export const LEAGUE_EVENTS_QUERY = gql`
  ${LEAGUE_EVENT}
  ${EVENT_DETAILS}
  ${EVENT_RESULTS}
  ${TICKET_FIELDS}
  query HorseRacesLeagueEvents(
    $name: String!
    $whereTicket: TicketFilterInput
  ) {
    horseRacesLeagueEvents(name: $name) {
      ...HorseRacesEvent
      details {
        ...EventDetails
      }
      results {
        ...EventResults
      }
    }
    tickets(where: $whereTicket) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        ...TicketFields
      }
    }
  }
`
export const LEAGUE_POOL_EVENTS_QUERY = gql`
  ${LEAGUE_EVENT}
  ${EVENT_DETAILS}
  ${EVENT_RESULTS}
  ${TICKET_FIELDS}
  query GetPoolRaces(
    $name: String!
    $race: Long!
    $pool: String!
    $whereTicket: TicketFilterInput
  ) {
    horseRacesPoolEvents(name: $name, race: $race, pool: $pool) {
      ...HorseRacesEvent
      details {
        ...EventDetails
      }
      results {
        ...EventResults
      }
    }
    tickets(where: $whereTicket) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        ...TicketFields
      }
    }
  }
`
export const TICKETS_QUERY = gql`
  ${TICKET_FIELDS}
  query Tickets($whereTicket: TicketFilterInput) {
    tickets(where: $whereTicket) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      items {
        ...TicketFields
      }
    }
  }
`

export const TICKETS_TYPE_BET_SALES_QUERY = gql`
  ${WAGER_FRAGMENT}
  ${EVENT_FRAGMENT}
  query GetWorkstationTicketTransactionTypebetSales(
    $product: ProductEnum!
    $from: DateTime!
    $to: DateTime!
  ) {
    workstationTicketTransactionsTypebetSales(
      product: $product
      from: $from
      to: $to
    ) {
      horseRacesWagerCollections {
        surcharge
        event {
          ...HorseRacesEventFragment
        }
        wagers {
          ...HorseRacesWagerFragment
        }
      }
    }
  }
`

export const TICKET_QUERY = gql`
  ${TICKET_FIELDS_WITH_PAYOFF}
  query GetTicket($number: String!) {
    ticket(number: $number) {
      ...TicketFieldsWithPayoff
      __typename
    }
  }
`
