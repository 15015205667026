import { gql } from '@apollo/client'
import {
  CORE_TICKET_FIELDS,
  WAGER_PAYOFF_FRAGMENT
} from 'src/placebet/core/graph/fragments'

export const LEAGUE_EVENT = gql`
  fragment CockfightsEvent on CockfightsEvent {
    id
    eventId
    eventName
    eventDate
    eventTime
    eventStatus
    leagueId
    league {
      id
      name
      key
    }
    __typename
  }
`
export const EVENT_DETAILS = gql`
  fragment EventDetails on CockfightsEventDetails {
    matchId
    blockNumber
    eventTime
    eventOrder
    periodId
    typeEventMatchupId
    typeScoreId
    coliseum
    teams {
      blue {
        id
        name
        __typename
      }
      white {
        id
        name
        __typename
      }
      __typename
    }
    columns {
      moneyLine {
        updateTime
        takeDown
        blue {
          identifier
          displayValue
          price
          __typename
        }
        white {
          identifier
          displayValue
          price
          __typename
        }
        draw {
          identifier
          displayValue
          price
          __typename
        }
        __typename
      }
      total {
        identifier
        updateTime
        takeDown
        spread
        over {
          identifier
          displayValue
          price
          __typename
        }
        under {
          identifier
          displayValue
          price
          __typename
        }
        __typename
      }
      fastest {
        block {
          identifier
          name
          price
          takeDown
          displayValue
          updateTime
          metadata {
            blockNumber
            __typename
          }
          __typename
        }
        __typename
      }
      finishedAt {
        quadrant {
          identifier
          name
          price
          displayValue
          takeDown
          __typename
        }
        territory {
          identifier
          name
          price
          displayValue
          takeDown
          __typename
        }
        __typename
      }
      __typename
    }
    __typename
  }
`
export const EVENT_RESULTS = gql`
  fragment EventResults on CockfightsEventResults {
    duration
    teams {
      blue {
        id
        name
        winner
        __typename
      }
      white {
        id
        name
        winner
        __typename
      }
      __typename
    }
    __typename
  }
`

// Tickets

export const WAGER_FRAGMENT = gql`
  fragment CockfightsWagerFragment on CockfightsWager {
    id
    displaySegments
    exclusions
    grouping
    identifier
    originalPrice
    price
    priceDisplayValue
    spread
    status
    takeDown
    teamColor
    teamId
    teamName
    wagerAmount
    typeBet
    wagerDetail
    # date
    # bet
    quick
    takeDown
    displayValue
    __typename
  }
`
export const EVENT_FRAGMENT = gql`
  fragment CockfightsEventFragment on CockfightsEvent {
    eventId
    eventName
    league {
      name
      __typename
    }
    __typename
  }
`
export const WAGER_COLLECTION_FRAGMENT = gql`
  fragment CockFightsWagerCollectionFragment on CockfightsWagerCollection {
    id
    eventId
    displayValue
    eventName
    providerTicketId
    securityCode
    total
    surcharge
    providerError {
      number
      message
      __typename
    }
    event {
      ...CockfightsEventFragment
    }
    wagers {
      ...CockfightsWagerFragment
      __typename
    }
    __typename
  }
`
export const TICKET_FIELDS = gql`
  ${CORE_TICKET_FIELDS}
  ${WAGER_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${WAGER_COLLECTION_FRAGMENT}
  fragment TicketFields on Ticket {
    ...CoreTicketFields
    cockfightsWagerCollections {
      ...CockFightsWagerCollectionFragment
    }
    __typename
  }
`
export const TICKET_FIELDS_WITH_PAYOFF = gql`
  ${CORE_TICKET_FIELDS}
  ${WAGER_PAYOFF_FRAGMENT}
  ${WAGER_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${WAGER_COLLECTION_FRAGMENT}
  fragment TicketFieldsWithPayoff on Ticket {
    ...CoreTicketFields
    cockfightsWagerCollections {
      ...CockFightsWagerCollectionFragment
      wagerPayoff {
        ...WagerPayoffFragment
      }
    }
    __typename
  }
`
export const TRANSACTION_WITH_PAYOFF = gql`
  ${CORE_TICKET_FIELDS}
  ${WAGER_PAYOFF_FRAGMENT}
  ${WAGER_FRAGMENT}
  ${EVENT_FRAGMENT}
  ${WAGER_COLLECTION_FRAGMENT}
  fragment TicketFieldsWithPayoff on Ticket {
    ...CoreTicketFields
    cockfightsWagerCollections {
      ...CockFightsWagerCollectionFragment
      wagerPayoff {
        ...WagerPayoffFragment
      }
    }
    __typename
  }
`
