import { ApolloProvider } from '@apollo/client'
import ExtendedSidebarLayout from 'src/placebet/layouts/ExtendedSidebarLayout'
import {
  getApolloClient,
  ProductEnum,
  store,
  useBrowserFingerprint
} from 'src/placebet/core'
import Protected from 'src/placebet/core/auth/Protected'
import { Loader } from '../Loader'
import CustomKeyboardProvider from 'src/placebet/products/providers/CustomKeyboardProvider'
import AppContextProvider from 'src/placebet/core/appContext/AppContextProvider'
import { Provider } from 'react-redux'
import Printer from '../Printer'
import ErrorHandler from '../ErrorHandlerr'

const AppLayout = ({ product }: { product: ProductEnum }) => {
  const { ready, error } = useBrowserFingerprint()

  return (
    <Loader loading={!ready}>
      <Protected>
        <Provider store={store}>
          <ApolloProvider client={getApolloClient()}>
            <AppContextProvider product={product}>
              <CustomKeyboardProvider>
                <ExtendedSidebarLayout />
              </CustomKeyboardProvider>
              <Printer />
              <ErrorHandler />
            </AppContextProvider>
          </ApolloProvider>
        </Provider>
      </Protected>
    </Loader>
  )
}

export default AppLayout
