import { styled, Box, Tooltip } from '@mui/material'
import PaymentIcon from '@mui/icons-material/Payment'
import Fab from '@mui/material/Fab'
import { useTranslation } from 'react-i18next'

const FloatingButton = styled(Box)(
  ({ theme }) => `
          position: fixed;
          z-index: 9999;
          right: ${theme.spacing(4)};
          bottom: ${theme.spacing(4)};
          
          &::before {
              width: 30px;
              height: 30px;
              content: ' ';
              position: absolute;
              border-radius: 100px;
              left: 13px;
              top: 13px;
              background: ${theme.colors.primary.main};
              animation: ripple 1s infinite;
              transition: ${theme.transitions.create(['all'])};
          }

          .MuiSvgIcon-root {
            animation: pulse 1s infinite;
            transition: ${theme.transitions.create(['all'])};
          }
  `
)

const SubmitButton = ({
  disabled,
  handleSubmit
}: {
  disabled: boolean
  handleSubmit: () => void
}) => {
  const { t } = useTranslation()

  return (
    <FloatingButton>
      <Tooltip arrow title={t('CREAR_TICKET')}>
        <Fab
          disabled={disabled}
          onClick={handleSubmit}
          color="primary"
          aria-label="add"
        >
          <PaymentIcon />
        </Fab>
      </Tooltip>
    </FloatingButton>
  )
}

export default SubmitButton
